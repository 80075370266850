import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";

export interface SingleRowBasicProps {
	dueDate: string;
	description: string;
	state?: JSX.Element;
	dateOfPayment?: string;
	insuranceToPay?: string;
	period?: JSX.Element;
	content?: JSX.Element;
}

export interface RowBasicProps {
	row: SingleRowBasicProps;
	titles: Array<string>;
}

function RowBasic({ row, titles }: RowBasicProps): JSX.Element {
	return (
		<>
			<TableRow>
				{titles.map((_, i) => (
					<TableCell key={i}>{Object.values(row)[i]}</TableCell>
				))}
			</TableRow>
		</>
	);
}

export default RowBasic;
