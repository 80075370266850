import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { Stack } from "@mui/material";
import useIsDesktop from "desktop/useIsDesktop";
import { graphql, useStaticQuery } from "gatsby";
import { useState } from "react";

import { useContractId } from "../../context/ContractIDContext";
import {
	NumberExtraDigits,
	NumberToCZK,
	NumberToCZKExtraDigits,
} from "../../functions";
import { useParams } from "../../hooks/useParams";
import { useDpsAllowedChanges, usePensionDetail } from "../../queryHooks";
import translation from "../../translations/cs.json";
import DPSChartCard from "../Charts/ChartCard/DPSChartCard";
import { NotInMVPButton } from "../Drawer/NotInMVP";
import ArmchairIcon from "../Icons/Armchair";
import GraphIcon from "../Icons/Graph";
import PigIcon from "../Icons/Pig";
import ScalesIcon from "../Icons/Scales";
import HelpBox from "../InfoBox/HelpBox";
import SwipeableInformationList from "../InformationList/Swipeable";
import ProductOverview from "../Overview/ProductOverview";
import { ScrollSnapper } from "../ScrollSnapper";
import BasicTable from "../Table/Basic";
import { HeadingL } from "../Typography";
import ChangeSavingsStrategy from "./ChangeSavingsStrategy";

interface imageProps {
	intro_image: {
		publicURL: string;
	};
}

const introImage = (): imageProps => {
	return useStaticQuery<Queries.DPSExportPigImageQuery>(
		graphql`
			query DPSExportPigImage {
				intro_image: file(relativePath: { eq: "pig.png" }) {
					publicURL
				}
			}
		`
	);
};

const fundsLabels = translation.product.fundsLabels;

/*previously was always assigned DPS title by apollo even in PP detail */
const title = "Doplňkové penzijní spoření";

function Export(): JSX.Element {
	const id = useContractId();
	const image = introImage();
	const isDesktop = useIsDesktop();
	const [fondIndex, setFondIndex] = useState(0);

	const [investmentStrategyOverride] = useParams({
		name: "investmentStrategyOverride",
		required: false,
	});

	const ai = useAppInsightsContext();

	const handleClick = (changeText: string) => {
		ai.trackEvent({
			name: `[Change] ${changeText}`,
			properties: {
				type: "not in MVP",
			},
		});
	};

	const { data: allowedData, isSuccess: allowedIsSuccess } =
		useDpsAllowedChanges({
			contractId: id,
		});

	const { data, isSuccess, isLoading, refetch } = usePensionDetail({ id });

	const isFundsChangeInProgress = allowedIsSuccess
		? !allowedData.canChangeInvestmentStrategy
		: true;

	const feature_isInvestmentStrategyEnabled =
		investmentStrategyOverride === "true" ||
		process.env.GATSBY_FEATURE_IS_SAVINGS_STRATEGY_ENABLED === "true";

	let tableData = [
		{
			title: undefined,
			balanceRatio: undefined,
			depositRatio: undefined,
			puAmount: undefined,
			puValue: undefined,
			totalAmount: undefined,
			code: undefined,
		},
	];

	let tableRows = null;

	if (isSuccess && data.funds) {
		tableData = [...data.funds]
			.sort((x, y) => x.totalAmount - y.totalAmount)
			.map((item) => {
				return {
					...item,
					totalAmount: item.totalAmount ? NumberToCZK(item.totalAmount) : "-",
					depositRatio: item.depositRatio ? `${item.depositRatio} %` : "-",
					balanceRatio: item.balanceRatio ? `${item.balanceRatio} %` : "-",
					puValue: item.puValue ? NumberToCZKExtraDigits(item.puValue) : "-",
					puAmount: item.puAmount ? NumberExtraDigits(item.puAmount) : "-",
					code: item.code ? item.code : "-",
				};
			});

		tableRows = Object.keys(fundsLabels).map((key) => [
			fundsLabels[key],
			...tableData.map((item) => item[key]),
		]);
	}

	const helperIconObject = {
		GROW: (
			<ScalesIcon
				sx={{ width: "6.4rem", height: "5rem", pb: 4 }}
				color="primary"
			/>
		),
		CON: (
			<ArmchairIcon
				sx={{ width: "6.4rem", height: "5rem", pb: 4 }}
				color="primary"
			/>
		),
		BAL: (
			<GraphIcon
				sx={{ width: "6.4rem", height: "5rem", pb: 4 }}
				color="primary"
			/>
		),
	};

	return (
		<>
			<ProductOverview
				title={title}
				image={!isDesktop && image.intro_image.publicURL}
				icon={<PigIcon sx={{ width: "3.2rem", height: "3.2rem", mr: 4 }} />}
				totalSavings={data?.savedAmount}
				sx={{
					display: { md: "none" },
					mb: -4,
					mx: -4,
				}}
				isFundsChangeInProgress={isFundsChangeInProgress}
			/>
			<Stack>
				{!isFundsChangeInProgress && (
					<>
						{isDesktop ? (
							<>
								<HeadingL withoutScale>
									Aktuální stav spoření k předešlému pracovnímu dni
								</HeadingL>
								<BasicTable
									titles={
										tableData && ["", ...tableData.map((item) => item.title)]
									}
									rows={tableRows}
								/>
							</>
						) : (
							<>
								<HeadingL withoutScale>Aktuální hodnoty spoření </HeadingL>
								<ScrollSnapper
									sx={{ zIndex: 2, gap: 4, pb: 4 }}
									index={fondIndex}
									onIndexChange={setFondIndex}
									childContainerProps={{ sx: { p: 2, width: "80%" } }}
								>
									{tableData.map((fond) => {
										return (
											<SwipeableInformationList
												isLoading={isLoading}
												key={fond.title}
												title={fond.title}
												code={fond.code}
												icon={helperIconObject[fond.code]}
												information={[
													{
														title: fundsLabels["totalAmount"],
														description: fond["totalAmount"],
													},
													{
														title: fundsLabels["depositRatio"],
														description: fond["depositRatio"],
													},
													{
														title: fundsLabels["balanceRatio"],
														description: fond["balanceRatio"],
													},
													{
														title: fundsLabels["puValue"],
														description: fond["puValue"],
													},
													{
														title: fundsLabels["puAmount"],
														description: fond["puAmount"],
													},
												]}
											/>
										);
									})}
								</ScrollSnapper>
							</>
						)}
					</>
				)}

				{feature_isInvestmentStrategyEnabled ? (
					<ChangeSavingsStrategy
						title="Změnit strategii spoření"
						refetch={refetch}
						isFundsChangeInProgress={isFundsChangeInProgress}
						isLoading={isLoading}
						funds={data?.funds}
					/>
				) : (
					<NotInMVPButton
						text="Změnit strategii spoření"
						onClick={() => {
							handleClick("Změnit strategii spoření");
						}}
						sx={{ mt: 4 }}
					/>
				)}
			</Stack>
			<DPSChartCard />
			<HelpBox />
		</>
	);
}

export default Export;
