/* tslint:disable */
/* eslint-disable */
/**
 * NN.CustomerPortal.ApiApp.General
 * GeneralAPIs
 *
 * The version of the OpenAPI document: 1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { PensionTransactionEnum } from './PensionTransactionEnum';
import {
    PensionTransactionEnumFromJSON,
    PensionTransactionEnumFromJSONTyped,
    PensionTransactionEnumToJSON,
} from './PensionTransactionEnum';

/**
 * 
 * @export
 * @interface GetPensionTransactionsResponse
 */
export interface GetPensionTransactionsResponse {
    /**
     * 
     * @type {number}
     * @memberof GetPensionTransactionsResponse
     */
    amount: number;
    /**
     * Date of creation
     * @type {Date}
     * @memberof GetPensionTransactionsResponse
     */
    date: Date;
    /**
     * 
     * @type {string}
     * @memberof GetPensionTransactionsResponse
     */
    typeFull: string;
    /**
     * 
     * @type {PensionTransactionEnum}
     * @memberof GetPensionTransactionsResponse
     */
    type: PensionTransactionEnum;
    /**
     * 
     * @type {string}
     * @memberof GetPensionTransactionsResponse
     */
    title: string;
}

/**
 * Check if a given object implements the GetPensionTransactionsResponse interface.
 */
export function instanceOfGetPensionTransactionsResponse(value: object): value is GetPensionTransactionsResponse {
    if (!('amount' in value) || value['amount'] === undefined) return false;
    if (!('date' in value) || value['date'] === undefined) return false;
    if (!('typeFull' in value) || value['typeFull'] === undefined) return false;
    if (!('type' in value) || value['type'] === undefined) return false;
    if (!('title' in value) || value['title'] === undefined) return false;
    return true;
}

export function GetPensionTransactionsResponseFromJSON(json: any): GetPensionTransactionsResponse {
    return GetPensionTransactionsResponseFromJSONTyped(json, false);
}

export function GetPensionTransactionsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetPensionTransactionsResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'amount': json['amount'],
        'date': (new Date(json['date'])),
        'typeFull': json['typeFull'],
        'type': PensionTransactionEnumFromJSON(json['type']),
        'title': json['title'],
    };
}

export function GetPensionTransactionsResponseToJSON(value?: GetPensionTransactionsResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'amount': value['amount'],
        'date': ((value['date']).toISOString()),
        'typeFull': value['typeFull'],
        'type': PensionTransactionEnumToJSON(value['type']),
        'title': value['title'],
    };
}

