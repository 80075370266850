/* tslint:disable */
/* eslint-disable */
/**
 * NN.CustomerPortal.ApiApp.General
 * GeneralAPIs
 *
 * The version of the OpenAPI document: 1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Address } from './Address';
import {
    AddressFromJSON,
    AddressFromJSONTyped,
    AddressToJSON,
} from './Address';
import type { ProductType } from './ProductType';
import {
    ProductTypeFromJSON,
    ProductTypeFromJSONTyped,
    ProductTypeToJSON,
} from './ProductType';
import type { InsuredPersonInfo } from './InsuredPersonInfo';
import {
    InsuredPersonInfoFromJSON,
    InsuredPersonInfoFromJSONTyped,
    InsuredPersonInfoToJSON,
} from './InsuredPersonInfo';

/**
 * 
 * @export
 * @interface ContactAddress
 */
export interface ContactAddress {
    /**
     * 
     * @type {string}
     * @memberof ContactAddress
     */
    contractNumber: string;
    /**
     * 
     * @type {string}
     * @memberof ContactAddress
     */
    title: string;
    /**
     * 
     * @type {ProductType}
     * @memberof ContactAddress
     */
    type: ProductType;
    /**
     * 
     * @type {InsuredPersonInfo}
     * @memberof ContactAddress
     */
    firstInsuredPerson?: InsuredPersonInfo;
    /**
     * 
     * @type {Address}
     * @memberof ContactAddress
     */
    address: Address;
    /**
     * 
     * @type {boolean}
     * @memberof ContactAddress
     */
    isContactAddressChangeInProgress: boolean;
}

/**
 * Check if a given object implements the ContactAddress interface.
 */
export function instanceOfContactAddress(value: object): value is ContactAddress {
    if (!('contractNumber' in value) || value['contractNumber'] === undefined) return false;
    if (!('title' in value) || value['title'] === undefined) return false;
    if (!('type' in value) || value['type'] === undefined) return false;
    if (!('address' in value) || value['address'] === undefined) return false;
    if (!('isContactAddressChangeInProgress' in value) || value['isContactAddressChangeInProgress'] === undefined) return false;
    return true;
}

export function ContactAddressFromJSON(json: any): ContactAddress {
    return ContactAddressFromJSONTyped(json, false);
}

export function ContactAddressFromJSONTyped(json: any, ignoreDiscriminator: boolean): ContactAddress {
    if (json == null) {
        return json;
    }
    return {
        
        'contractNumber': json['contractNumber'],
        'title': json['title'],
        'type': ProductTypeFromJSON(json['type']),
        'firstInsuredPerson': json['firstInsuredPerson'] == null ? undefined : InsuredPersonInfoFromJSON(json['firstInsuredPerson']),
        'address': AddressFromJSON(json['address']),
        'isContactAddressChangeInProgress': json['isContactAddressChangeInProgress'],
    };
}

export function ContactAddressToJSON(value?: ContactAddress | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'contractNumber': value['contractNumber'],
        'title': value['title'],
        'type': ProductTypeToJSON(value['type']),
        'firstInsuredPerson': InsuredPersonInfoToJSON(value['firstInsuredPerson']),
        'address': AddressToJSON(value['address']),
        'isContactAddressChangeInProgress': value['isContactAddressChangeInProgress'],
    };
}

