/* tslint:disable */
/* eslint-disable */
/**
 * NN.CustomerPortal.ApiApp.General
 * GeneralAPIs
 *
 * The version of the OpenAPI document: 1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const ChangesDisabledReason = {
    None: 'None',
    BeneficiaryChangeInProgress: 'BeneficiaryChangeInProgress',
    BeneficiaryIsABank: 'BeneficiaryIsABank',
    HasNoBeneficiaries: 'HasNoBeneficiaries',
    FutureEmployerOrClientContributionChange: 'FutureEmployerOrClientContributionChange'
} as const;
export type ChangesDisabledReason = typeof ChangesDisabledReason[keyof typeof ChangesDisabledReason];


export function instanceOfChangesDisabledReason(value: any): boolean {
    for (const key in ChangesDisabledReason) {
        if (Object.prototype.hasOwnProperty.call(ChangesDisabledReason, key)) {
            if ((ChangesDisabledReason as Record<string, ChangesDisabledReason>)[key] === value) {
                return true;
            }
        }
    }
    return false;
}

export function ChangesDisabledReasonFromJSON(json: any): ChangesDisabledReason {
    return ChangesDisabledReasonFromJSONTyped(json, false);
}

export function ChangesDisabledReasonFromJSONTyped(json: any, ignoreDiscriminator: boolean): ChangesDisabledReason {
    return json as ChangesDisabledReason;
}

export function ChangesDisabledReasonToJSON(value?: ChangesDisabledReason | null): any {
    return value as any;
}

