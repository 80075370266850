/* tslint:disable */
/* eslint-disable */
/**
 * NN.CustomerPortal.ApiApp.General
 * GeneralAPIs
 *
 * The version of the OpenAPI document: 1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const LifeTransactionEnum = {
    Other: 'Other',
    LifeInsurer: 'LifeInsurer',
    LifeEmployer: 'LifeEmployer',
    LifePayIn: 'LifePayIn',
    LifePayOut: 'LifePayOut'
} as const;
export type LifeTransactionEnum = typeof LifeTransactionEnum[keyof typeof LifeTransactionEnum];


export function instanceOfLifeTransactionEnum(value: any): boolean {
    for (const key in LifeTransactionEnum) {
        if (Object.prototype.hasOwnProperty.call(LifeTransactionEnum, key)) {
            if ((LifeTransactionEnum as Record<string, LifeTransactionEnum>)[key] === value) {
                return true;
            }
        }
    }
    return false;
}

export function LifeTransactionEnumFromJSON(json: any): LifeTransactionEnum {
    return LifeTransactionEnumFromJSONTyped(json, false);
}

export function LifeTransactionEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): LifeTransactionEnum {
    return json as LifeTransactionEnum;
}

export function LifeTransactionEnumToJSON(value?: LifeTransactionEnum | null): any {
    return value as any;
}

