/* tslint:disable */
/* eslint-disable */
/**
 * NN.CustomerPortal.ApiApp.General
 * GeneralAPIs
 *
 * The version of the OpenAPI document: 1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const PaymentInfoType = {
    Regular: 'Regular',
    Extraordinary: 'Extraordinary',
    ExtraordinaryFunds: 'ExtraordinaryFunds',
    ExtraordinaryFundsWithGuaranteedPayoff: 'ExtraordinaryFundsWithGuaranteedPayoff',
    ExtraordinaryGuarenteedFunds: 'ExtraordinaryGuarenteedFunds'
} as const;
export type PaymentInfoType = typeof PaymentInfoType[keyof typeof PaymentInfoType];


export function instanceOfPaymentInfoType(value: any): boolean {
    for (const key in PaymentInfoType) {
        if (Object.prototype.hasOwnProperty.call(PaymentInfoType, key)) {
            if ((PaymentInfoType as Record<string, PaymentInfoType>)[key] === value) {
                return true;
            }
        }
    }
    return false;
}

export function PaymentInfoTypeFromJSON(json: any): PaymentInfoType {
    return PaymentInfoTypeFromJSONTyped(json, false);
}

export function PaymentInfoTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaymentInfoType {
    return json as PaymentInfoType;
}

export function PaymentInfoTypeToJSON(value?: PaymentInfoType | null): any {
    return value as any;
}

