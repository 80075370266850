/* tslint:disable */
/* eslint-disable */
/**
 * NN.CustomerPortal.ApiApp.General
 * GeneralAPIs
 *
 * The version of the OpenAPI document: 1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { PaymentFrequency } from './PaymentFrequency';
import {
    PaymentFrequencyFromJSON,
    PaymentFrequencyFromJSONTyped,
    PaymentFrequencyToJSON,
} from './PaymentFrequency';

/**
 * 
 * @export
 * @interface PensionPaymentInfo
 */
export interface PensionPaymentInfo {
    /**
     * 
     * @type {string}
     * @memberof PensionPaymentInfo
     */
    accountNumber: string;
    /**
     * Null for employer contribution
     * @type {number}
     * @memberof PensionPaymentInfo
     */
    monthlyContribution?: number | null;
    /**
     * 
     * @type {string}
     * @memberof PensionPaymentInfo
     */
    constantSymbol: string;
    /**
     * 
     * @type {string}
     * @memberof PensionPaymentInfo
     */
    variableSymbol: string;
    /**
     * 
     * @type {PaymentFrequency}
     * @memberof PensionPaymentInfo
     */
    frequency: PaymentFrequency;
    /**
     * 
     * @type {string}
     * @memberof PensionPaymentInfo
     */
    iban: string;
}

/**
 * Check if a given object implements the PensionPaymentInfo interface.
 */
export function instanceOfPensionPaymentInfo(value: object): value is PensionPaymentInfo {
    if (!('accountNumber' in value) || value['accountNumber'] === undefined) return false;
    if (!('constantSymbol' in value) || value['constantSymbol'] === undefined) return false;
    if (!('variableSymbol' in value) || value['variableSymbol'] === undefined) return false;
    if (!('frequency' in value) || value['frequency'] === undefined) return false;
    if (!('iban' in value) || value['iban'] === undefined) return false;
    return true;
}

export function PensionPaymentInfoFromJSON(json: any): PensionPaymentInfo {
    return PensionPaymentInfoFromJSONTyped(json, false);
}

export function PensionPaymentInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PensionPaymentInfo {
    if (json == null) {
        return json;
    }
    return {
        
        'accountNumber': json['accountNumber'],
        'monthlyContribution': json['monthlyContribution'] == null ? undefined : json['monthlyContribution'],
        'constantSymbol': json['constantSymbol'],
        'variableSymbol': json['variableSymbol'],
        'frequency': PaymentFrequencyFromJSON(json['frequency']),
        'iban': json['iban'],
    };
}

export function PensionPaymentInfoToJSON(value?: PensionPaymentInfo | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'accountNumber': value['accountNumber'],
        'monthlyContribution': value['monthlyContribution'],
        'constantSymbol': value['constantSymbol'],
        'variableSymbol': value['variableSymbol'],
        'frequency': PaymentFrequencyToJSON(value['frequency']),
        'iban': value['iban'],
    };
}

