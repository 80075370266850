import { Stack, StackProps } from "@mui/material";

import { ErrorBoundaryWithAsyncContext } from "../ErrorBoundary";

export const Tab = ({ sx, children, ...rest }: StackProps): JSX.Element => {
	return (
		<ErrorBoundaryWithAsyncContext>
			<Stack
				gap={{ xxs: 7, md: 8 }}
				role="tabpanel"
				sx={{
					// If internal component is too wide, horizontal scrollbar prevents swipe event. Swipe event must be available always.
					overflowX: "hidden",
					// For box shadows
					p: 1,
					pt: { md: 7, xxs: 1 },
					...sx,
				}}
				{...rest}
			>
				{children}
			</Stack>
		</ErrorBoundaryWithAsyncContext>
	);
};

export default Tab;
