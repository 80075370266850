/* tslint:disable */
/* eslint-disable */
/**
 * NN.CustomerPortal.ApiApp.General
 * GeneralAPIs
 *
 * The version of the OpenAPI document: 1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SetCookieConsentRequest
 */
export interface SetCookieConsentRequest {
    /**
     * 
     * @type {string}
     * @memberof SetCookieConsentRequest
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof SetCookieConsentRequest
     */
    expirationDate: string;
    /**
     * 
     * @type {string}
     * @memberof SetCookieConsentRequest
     */
    cookieConsent: string;
}

/**
 * Check if a given object implements the SetCookieConsentRequest interface.
 */
export function instanceOfSetCookieConsentRequest(value: object): value is SetCookieConsentRequest {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('expirationDate' in value) || value['expirationDate'] === undefined) return false;
    if (!('cookieConsent' in value) || value['cookieConsent'] === undefined) return false;
    return true;
}

export function SetCookieConsentRequestFromJSON(json: any): SetCookieConsentRequest {
    return SetCookieConsentRequestFromJSONTyped(json, false);
}

export function SetCookieConsentRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): SetCookieConsentRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'expirationDate': json['expirationDate'],
        'cookieConsent': json['cookieConsent'],
    };
}

export function SetCookieConsentRequestToJSON(value?: SetCookieConsentRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'expirationDate': value['expirationDate'],
        'cookieConsent': value['cookieConsent'],
    };
}

