import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { Alert, Box } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import useIsDesktop from "desktop/useIsDesktop";
import { isEmpty } from "lodash";
import { PropsWithChildren } from "react";

import { usePrimaryAPIClient } from "../../context/PrimaryAPIClient";
import { useHasSPError } from "../../context/SPErrorContext";
import cs from "../../translations/cs.json";
import {
	BannerByPageWrapperDesktop,
	BannerByPageWrapperMobile,
} from "../Layout";
import PensionNovelBannerWrapper from "../PensionNovelBanner/Wrapper";
import { BodyTextM } from "../Typography";

const ErrorWrapper = ({ children }: PropsWithChildren) => (
	<Box sx={{ mb: 4 }}>{children}</Box>
);

const BannerComponent = ({
	severity,
	value,
}: {
	severity: string;
	value: string;
}) => (
	<ErrorWrapper>
		<Alert
			severity={severity as "error" | "warning" | "info" | "success"}
			sx={{
				zIndex: 999,
				pr: 4,
				pl: 4,
			}}
		>
			<BodyTextM dangerouslySetInnerHTML={{ __html: value }}></BodyTextM>
		</Alert>
	</ErrorWrapper>
);

export const InfoBanners = ({
	pageName,
	showCoreSystemDownWarning,
}: {
	pageName: string;
	showCoreSystemDownWarning: boolean;
}) => {
	const { hasError: isSpError } = useHasSPError();
	const isDesktop = useIsDesktop();
	const { instance } = useMsal();
	const { bannersApi } = usePrimaryAPIClient();
	const isAuth = useIsAuthenticated();
	const isLoggedUser = !!instance.getActiveAccount() && isAuth;

	const { data } = useQuery(
		["bannersApi.getCmsWarningsGet"],
		() => bannersApi.getCmsWarningsGet(),
		{ enabled: isLoggedUser }
	);

	const WrapperComponent = isDesktop
		? BannerByPageWrapperDesktop
		: BannerByPageWrapperMobile;
	const WrapperComponentComponent = WrapperComponent[pageName];

	if (!isLoggedUser) return null;

	return (
		<>
			{data &&
				Object.entries(data).map(([key, value]) => {
					if (!value || value === "") return null;

					if (data?.error && !isEmpty(data?.error) && key !== "error")
						return null;

					return <BannerComponent key={key} severity={key} value={value} />;
				})}

			{isSpError && showCoreSystemDownWarning && !data?.error && (
				<BannerComponent
					severity={"error"}
					value={cs.global.starPensionUnavailableWarning}
				/>
			)}

			{WrapperComponentComponent && (
				<WrapperComponentComponent>
					<PensionNovelBannerWrapper />
				</WrapperComponentComponent>
			)}
		</>
	);
};
