/* tslint:disable */
/* eslint-disable */
/**
 * NN.CustomerPortal.ApiApp.General
 * GeneralAPIs
 *
 * The version of the OpenAPI document: 1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { CustomAllocation } from './CustomAllocation';
import {
    CustomAllocationFromJSON,
    CustomAllocationFromJSONTyped,
    CustomAllocationToJSON,
} from './CustomAllocation';
import type { ZZJInformationRequest } from './ZZJInformationRequest';
import {
    ZZJInformationRequestFromJSON,
    ZZJInformationRequestFromJSONTyped,
    ZZJInformationRequestToJSON,
} from './ZZJInformationRequest';

/**
 * 
 * @export
 * @interface ChangeFundsRequest
 */
export interface ChangeFundsRequest {
    /**
     * 
     * @type {string}
     * @memberof ChangeFundsRequest
     */
    transactionId: string;
    /**
     * 
     * @type {string}
     * @memberof ChangeFundsRequest
     */
    authenticationPhone: string;
    /**
     * 
     * @type {string}
     * @memberof ChangeFundsRequest
     */
    smsCode: string;
    /**
     * 
     * @type {string}
     * @memberof ChangeFundsRequest
     */
    dpsPrecontractualInfoName: string;
    /**
     * 
     * @type {ZZJInformationRequest}
     * @memberof ChangeFundsRequest
     */
    zzjInformation: ZZJInformationRequest;
    /**
     * 
     * @type {Array<CustomAllocation>}
     * @memberof ChangeFundsRequest
     */
    recommendedStrategy: Array<CustomAllocation>;
}

/**
 * Check if a given object implements the ChangeFundsRequest interface.
 */
export function instanceOfChangeFundsRequest(value: object): value is ChangeFundsRequest {
    if (!('transactionId' in value) || value['transactionId'] === undefined) return false;
    if (!('authenticationPhone' in value) || value['authenticationPhone'] === undefined) return false;
    if (!('smsCode' in value) || value['smsCode'] === undefined) return false;
    if (!('dpsPrecontractualInfoName' in value) || value['dpsPrecontractualInfoName'] === undefined) return false;
    if (!('zzjInformation' in value) || value['zzjInformation'] === undefined) return false;
    if (!('recommendedStrategy' in value) || value['recommendedStrategy'] === undefined) return false;
    return true;
}

export function ChangeFundsRequestFromJSON(json: any): ChangeFundsRequest {
    return ChangeFundsRequestFromJSONTyped(json, false);
}

export function ChangeFundsRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ChangeFundsRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'transactionId': json['transactionId'],
        'authenticationPhone': json['authenticationPhone'],
        'smsCode': json['smsCode'],
        'dpsPrecontractualInfoName': json['dpsPrecontractualInfoName'],
        'zzjInformation': ZZJInformationRequestFromJSON(json['zzjInformation']),
        'recommendedStrategy': ((json['recommendedStrategy'] as Array<any>).map(CustomAllocationFromJSON)),
    };
}

export function ChangeFundsRequestToJSON(value?: ChangeFundsRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'transactionId': value['transactionId'],
        'authenticationPhone': value['authenticationPhone'],
        'smsCode': value['smsCode'],
        'dpsPrecontractualInfoName': value['dpsPrecontractualInfoName'],
        'zzjInformation': ZZJInformationRequestToJSON(value['zzjInformation']),
        'recommendedStrategy': ((value['recommendedStrategy'] as Array<any>).map(CustomAllocationToJSON)),
    };
}

