import { ProductType } from "../../models";

// Narrower types from product type

export type LifeContractType = typeof ProductType.Cl | typeof ProductType.Clf;

export type PensionContractType = typeof ProductType.Uf | typeof ProductType.Pf;

export type SupportedContractType = LifeContractType | PensionContractType;

// Type guards. Inside a typeguard the type of variable is narrowed as per the typeguard definition.

export const getIsSupportedContractType = (
	contractType: unknown
): contractType is SupportedContractType => {
	return (
		contractType === ProductType.Cl ||
		contractType === ProductType.Clf ||
		contractType === ProductType.Uf ||
		contractType === ProductType.Pf
	);
};

export const getIsPensionContractType = (
	contractType: unknown
): contractType is PensionContractType => {
	return contractType === ProductType.Uf || contractType === ProductType.Pf;
};

export const getIsLifeContractType = (
	contractType: unknown
): contractType is LifeContractType => {
	return contractType === ProductType.Cl || contractType === ProductType.Clf;
};

// Asserts. Variable type is changed to asserted type, must be a normal, not an arrow function.
// Throws error if type is not correct

export function assertIsSupportedContractType(
	contractType: unknown,
	errorMessage?: string
): asserts contractType is SupportedContractType {
	if (!getIsSupportedContractType(contractType)) {
		throw new Error(
			errorMessage ??
				`${contractType} does not match any supported contract type`
		);
	}
}

export function assertIsLifeContractType(
	contractType: unknown,
	errorMessage?: string
): asserts contractType is LifeContractType {
	if (!getIsLifeContractType(contractType)) {
		throw new Error(
			errorMessage ??
				`${contractType} does not match any supported life contract type`
		);
	}
}

export function assertIsPensionContractType(
	contractType: unknown,
	errorMessage?: string
): asserts contractType is PensionContractType {
	if (!getIsPensionContractType(contractType)) {
		throw new Error(
			errorMessage ??
				`${contractType} does not match any supported pension contract type`
		);
	}
}
