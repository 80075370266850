/* tslint:disable */
/* eslint-disable */
/**
 * NN.CustomerPortal.ApiApp.General
 * GeneralAPIs
 *
 * The version of the OpenAPI document: 1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ContactAddress } from './ContactAddress';
import {
    ContactAddressFromJSON,
    ContactAddressFromJSONTyped,
    ContactAddressToJSON,
} from './ContactAddress';

/**
 * 
 * @export
 * @interface GetContactAddressesResponse
 */
export interface GetContactAddressesResponse {
    /**
     * 
     * @type {Array<ContactAddress>}
     * @memberof GetContactAddressesResponse
     */
    contactAddresses: Array<ContactAddress>;
    /**
     * 
     * @type {boolean}
     * @memberof GetContactAddressesResponse
     */
    isGlobalContactAddressChangeEnabled: boolean;
}

/**
 * Check if a given object implements the GetContactAddressesResponse interface.
 */
export function instanceOfGetContactAddressesResponse(value: object): value is GetContactAddressesResponse {
    if (!('contactAddresses' in value) || value['contactAddresses'] === undefined) return false;
    if (!('isGlobalContactAddressChangeEnabled' in value) || value['isGlobalContactAddressChangeEnabled'] === undefined) return false;
    return true;
}

export function GetContactAddressesResponseFromJSON(json: any): GetContactAddressesResponse {
    return GetContactAddressesResponseFromJSONTyped(json, false);
}

export function GetContactAddressesResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetContactAddressesResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'contactAddresses': ((json['contactAddresses'] as Array<any>).map(ContactAddressFromJSON)),
        'isGlobalContactAddressChangeEnabled': json['isGlobalContactAddressChangeEnabled'],
    };
}

export function GetContactAddressesResponseToJSON(value?: GetContactAddressesResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'contactAddresses': ((value['contactAddresses'] as Array<any>).map(ContactAddressToJSON)),
        'isGlobalContactAddressChangeEnabled': value['isGlobalContactAddressChangeEnabled'],
    };
}

