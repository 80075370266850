import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import useIsDesktop from "desktop/useIsDesktop";

import {
	GetLifeTransactionsResponse,
	LifeTransactionEnum,
	LifeTransactionStatus,
} from "../../../../../../models";
import { NumberToCZK } from "../../../../../functions";
import { dateTimeFormat } from "../../../../../functions/dates";
import cs from "../../../../../translations/cs.json";
import BasicTable from "../../../../Table/Basic";
import CollapsibleTable from "../../../../Table/Collapsible";
import { SingleRowBasicProps } from "../../../../Table/RowBasic";
import { SingleRowProps } from "../../../../Table/RowCollapsible";
import { TransactionSkeleton } from "../../../../Transactions/SingleTransactionRow";
import { BodyTextM, HeadingS } from "../../../../Typography";
import LoadMoreButton from "./LoadMoreButton";

const titles = [
	cs.life.transactions.table.titles.maturityDate,
	cs.life.transactions.table.titles.description,
];

const desktopTitles = [
	cs.life.transactions.table.titles.maturityDate,
	cs.life.transactions.table.titles.description,
	cs.life.transactions.table.titles.state,
	cs.life.transactions.table.titles.paymentDate,
	cs.life.transactions.table.titles.insuracePaymentSum,
	cs.life.transactions.table.titles.period,
];

export enum LoadingStatus {
	Initial,
	Additional,
	Ready,
}

export const mapLifeTransactionStatusToString = (
	status: LifeTransactionStatus
) => {
	return (
		cs.life.transactions.table.status[status] ??
		cs.life.transactions.table.status.Unknown
	);
};

const mapTranscationsToDesktopRows = (
	data: GetLifeTransactionsResponse[]
): SingleRowBasicProps[] => {
	return data.map((transaction) => {
		const isPayInOrOut =
			transaction.type === LifeTransactionEnum.LifePayOut ||
			transaction.type === LifeTransactionEnum.LifePayIn;

		return {
			dueDate: dateTimeFormat.format(new Date(transaction.maturityDate)),
			description: transaction.title,
			state: (
				<>
					{!isPayInOrOut
						? mapLifeTransactionStatusToString(transaction.status)
						: "-"}
				</>
			),
			dateOfPayment:
				new Date(transaction.date).getFullYear() === 1
					? "-"
					: dateTimeFormat.format(new Date(transaction.date)),
			insuranceToPay: NumberToCZK(transaction.amount),
			period: (
				<>
					{!isPayInOrOut && transaction.periodFrom && transaction.periodTo ? (
						<>
							{dateTimeFormat.format(new Date(transaction.periodFrom))} -{" "}
							{dateTimeFormat.format(new Date(transaction.periodTo))}
						</>
					) : (
						"-"
					)}
				</>
			),
		};
	});
};

const mapTranscationsToMobileRows = (
	data: GetLifeTransactionsResponse[]
): SingleRowProps[] => {
	return data.map((transaction) => {
		const isPayInOrOut =
			transaction.type === LifeTransactionEnum.LifePayOut ||
			transaction.type === LifeTransactionEnum.LifePayIn;
		return {
			dueDate: dateTimeFormat.format(new Date(transaction.maturityDate)),
			description: transaction.title,
			content: (
				<>
					{!isPayInOrOut && (
						<Stack>
							<HeadingS mb={0}>{cs.life.transactions.state}</HeadingS>
							<BodyTextM>
								{!isPayInOrOut
									? mapLifeTransactionStatusToString(transaction.status)
									: "-"}
							</BodyTextM>
						</Stack>
					)}
					<Stack>
						<HeadingS mb={0}>{cs.life.transactions.dateOfPayment}</HeadingS>
						<BodyTextM>
							{transaction.isPaid
								? dateTimeFormat.format(new Date(transaction.date))
								: "-"}
						</BodyTextM>
					</Stack>
					<Stack>
						<HeadingS mb={0}>
							{isPayInOrOut
								? cs.global.amount
								: cs.life.transactions.insuranceToPay}
						</HeadingS>
						<BodyTextM>{NumberToCZK(transaction.amount)}</BodyTextM>
					</Stack>
					{!!transaction.periodFrom && !!transaction.periodTo && (
						<Stack>
							<HeadingS mb={0}>{cs.life.transactions.period}</HeadingS>
							<BodyTextM>
								{dateTimeFormat.format(new Date(transaction.periodFrom))} -{" "}
								{dateTimeFormat.format(new Date(transaction.periodTo))}
							</BodyTextM>
						</Stack>
					)}
				</>
			),
		};
	});
};

interface TableProps {
	data: GetLifeTransactionsResponse[];
	loadingStatus: LoadingStatus;
	fetchMore: () => void;
	displayLoadMoreButton: boolean;
}

export const Table = ({
	data,
	loadingStatus,
	fetchMore,
	displayLoadMoreButton,
}: TableProps) => {
	const isDesktop = useIsDesktop();

	if (loadingStatus === LoadingStatus.Initial) {
		return <TransactionsListSkeleton skeletonsCount={6} />;
	}

	const TableContent = isDesktop ? (
		<BasicTable
			titles={desktopTitles}
			rows={mapTranscationsToDesktopRows(data)}
		/>
	) : (
		<CollapsibleTable
			titles={titles}
			rows={mapTranscationsToMobileRows(data)}
			isLoading={false}
		/>
	);

	return (
		<>
			{data.length > 0 ? (
				<>
					{TableContent}
					{loadingStatus === LoadingStatus.Additional && (
						<TransactionsListSkeleton skeletonsCount={6} />
					)}
					{displayLoadMoreButton && (
						<LoadMoreButton
							loading={loadingStatus === LoadingStatus.Additional}
							fetchMore={fetchMore}
						/>
					)}
				</>
			) : (
				<Box>{cs.global.noTransactionsFound}</Box>
			)}
		</>
	);
};

interface TransactionsListSkeletonProps {
	skeletonsCount: number;
}
export function TransactionsListSkeleton({
	skeletonsCount,
}: TransactionsListSkeletonProps): JSX.Element {
	return (
		<Stack spacing={4} py={3}>
			{[...Array(skeletonsCount).keys()].map((_, index) => (
				<TransactionSkeleton key={index} />
			))}
		</Stack>
	);
}
