/* tslint:disable */
/* eslint-disable */
/**
 * NN.CustomerPortal.ApiApp.General
 * GeneralAPIs
 *
 * The version of the OpenAPI document: 1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const ChangeRequestType = {
    All: 'All',
    Contract: 'Contract',
    Requirements: 'Requirements',
    Funds: 'Funds'
} as const;
export type ChangeRequestType = typeof ChangeRequestType[keyof typeof ChangeRequestType];


export function instanceOfChangeRequestType(value: any): boolean {
    for (const key in ChangeRequestType) {
        if (Object.prototype.hasOwnProperty.call(ChangeRequestType, key)) {
            if ((ChangeRequestType as Record<string, ChangeRequestType>)[key] === value) {
                return true;
            }
        }
    }
    return false;
}

export function ChangeRequestTypeFromJSON(json: any): ChangeRequestType {
    return ChangeRequestTypeFromJSONTyped(json, false);
}

export function ChangeRequestTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ChangeRequestType {
    return json as ChangeRequestType;
}

export function ChangeRequestTypeToJSON(value?: ChangeRequestType | null): any {
    return value as any;
}

