/* tslint:disable */
/* eslint-disable */
/**
 * NN.CustomerPortal.ApiApp.General
 * GeneralAPIs
 *
 * The version of the OpenAPI document: 1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Allocation } from './Allocation';
import {
    AllocationFromJSON,
    AllocationFromJSONTyped,
    AllocationToJSON,
} from './Allocation';
import type { StrategyCode } from './StrategyCode';
import {
    StrategyCodeFromJSON,
    StrategyCodeFromJSONTyped,
    StrategyCodeToJSON,
} from './StrategyCode';

/**
 * 
 * @export
 * @interface Strategy
 */
export interface Strategy {
    /**
     * 
     * @type {StrategyCode}
     * @memberof Strategy
     */
    code: StrategyCode;
    /**
     * 
     * @type {string}
     * @memberof Strategy
     */
    name: string;
    /**
     * 
     * @type {Array<Allocation>}
     * @memberof Strategy
     */
    allocation: Array<Allocation>;
}

/**
 * Check if a given object implements the Strategy interface.
 */
export function instanceOfStrategy(value: object): value is Strategy {
    if (!('code' in value) || value['code'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('allocation' in value) || value['allocation'] === undefined) return false;
    return true;
}

export function StrategyFromJSON(json: any): Strategy {
    return StrategyFromJSONTyped(json, false);
}

export function StrategyFromJSONTyped(json: any, ignoreDiscriminator: boolean): Strategy {
    if (json == null) {
        return json;
    }
    return {
        
        'code': StrategyCodeFromJSON(json['code']),
        'name': json['name'],
        'allocation': ((json['allocation'] as Array<any>).map(AllocationFromJSON)),
    };
}

export function StrategyToJSON(value?: Strategy | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'code': StrategyCodeToJSON(value['code']),
        'name': value['name'],
        'allocation': ((value['allocation'] as Array<any>).map(AllocationToJSON)),
    };
}

