/* tslint:disable */
/* eslint-disable */
/**
 * NN.CustomerPortal.ApiApp.General
 * GeneralAPIs
 *
 * The version of the OpenAPI document: 1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AreConsentsNeededResponse,
  ErrorResponse,
  GetConsentResponse,
  SetConsentRequest,
  SetCookieConsentRequest,
} from '../models/index';
import {
    AreConsentsNeededResponseFromJSON,
    AreConsentsNeededResponseToJSON,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    GetConsentResponseFromJSON,
    GetConsentResponseToJSON,
    SetConsentRequestFromJSON,
    SetConsentRequestToJSON,
    SetCookieConsentRequestFromJSON,
    SetCookieConsentRequestToJSON,
} from '../models/index';

export interface SetConsentsPutRequest {
    setConsentRequest: SetConsentRequest;
}

export interface SetCookiesConsentsPutRequest {
    setCookieConsentRequest: SetCookieConsentRequest;
}

/**
 * 
 */
export class ConsentApi extends runtime.BaseAPI {

    /**
     * Checks whether consents confirmation should be shown or consents are proberly set for user
     */
    async areConsentsNeededGetRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AreConsentsNeededResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("ADB2CAuthFlow", ["https://nnczcustomerportaldev.onmicrosoft.com/5d23dca9-d19b-4c16-b403-d27b86222083/CustPortalApi.Default"]);
        }

        const response = await this.request({
            path: `/consents/required`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AreConsentsNeededResponseFromJSON(jsonValue));
    }

    /**
     * Checks whether consents confirmation should be shown or consents are proberly set for user
     */
    async areConsentsNeededGet(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AreConsentsNeededResponse> {
        const response = await this.areConsentsNeededGetRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get marketing consent setting for user
     */
    async getConsentsGetRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GetConsentResponse>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("ADB2CAuthFlow", ["https://nnczcustomerportaldev.onmicrosoft.com/5d23dca9-d19b-4c16-b403-d27b86222083/CustPortalApi.Default"]);
        }

        const response = await this.request({
            path: `/consents`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetConsentResponseFromJSON));
    }

    /**
     * Get marketing consent setting for user
     */
    async getConsentsGet(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GetConsentResponse>> {
        const response = await this.getConsentsGetRaw(initOverrides);
        return await response.value();
    }

    /**
     * Set marketing consent setting for user
     */
    async setConsentsPutRaw(requestParameters: SetConsentsPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['setConsentRequest'] == null) {
            throw new runtime.RequiredError(
                'setConsentRequest',
                'Required parameter "setConsentRequest" was null or undefined when calling setConsentsPut().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("ADB2CAuthFlow", ["https://nnczcustomerportaldev.onmicrosoft.com/5d23dca9-d19b-4c16-b403-d27b86222083/CustPortalApi.Default"]);
        }

        const response = await this.request({
            path: `/consents`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: SetConsentRequestToJSON(requestParameters['setConsentRequest']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Set marketing consent setting for user
     */
    async setConsentsPut(requestParameters: SetConsentsPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.setConsentsPutRaw(requestParameters, initOverrides);
    }

    /**
     * Set marketing cookie consent setting for user
     */
    async setCookiesConsentsPutRaw(requestParameters: SetCookiesConsentsPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['setCookieConsentRequest'] == null) {
            throw new runtime.RequiredError(
                'setCookieConsentRequest',
                'Required parameter "setCookieConsentRequest" was null or undefined when calling setCookiesConsentsPut().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/consents/cookies`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: SetCookieConsentRequestToJSON(requestParameters['setCookieConsentRequest']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Set marketing cookie consent setting for user
     */
    async setCookiesConsentsPut(requestParameters: SetCookiesConsentsPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.setCookiesConsentsPutRaw(requestParameters, initOverrides);
    }

}
