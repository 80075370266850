/* tslint:disable */
/* eslint-disable */
/**
 * NN.CustomerPortal.ApiApp.General
 * GeneralAPIs
 *
 * The version of the OpenAPI document: 1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PensionBeneficiaryAddress
 */
export interface PensionBeneficiaryAddress {
    /**
     * 
     * @type {string}
     * @memberof PensionBeneficiaryAddress
     */
    street?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PensionBeneficiaryAddress
     */
    houseNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PensionBeneficiaryAddress
     */
    town?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PensionBeneficiaryAddress
     */
    zip?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PensionBeneficiaryAddress
     */
    country?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PensionBeneficiaryAddress
     */
    countryName?: string | null;
}

/**
 * Check if a given object implements the PensionBeneficiaryAddress interface.
 */
export function instanceOfPensionBeneficiaryAddress(value: object): value is PensionBeneficiaryAddress {
    return true;
}

export function PensionBeneficiaryAddressFromJSON(json: any): PensionBeneficiaryAddress {
    return PensionBeneficiaryAddressFromJSONTyped(json, false);
}

export function PensionBeneficiaryAddressFromJSONTyped(json: any, ignoreDiscriminator: boolean): PensionBeneficiaryAddress {
    if (json == null) {
        return json;
    }
    return {
        
        'street': json['street'] == null ? undefined : json['street'],
        'houseNumber': json['houseNumber'] == null ? undefined : json['houseNumber'],
        'town': json['town'] == null ? undefined : json['town'],
        'zip': json['zip'] == null ? undefined : json['zip'],
        'country': json['country'] == null ? undefined : json['country'],
        'countryName': json['countryName'] == null ? undefined : json['countryName'],
    };
}

export function PensionBeneficiaryAddressToJSON(value?: PensionBeneficiaryAddress | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'street': value['street'],
        'houseNumber': value['houseNumber'],
        'town': value['town'],
        'zip': value['zip'],
        'country': value['country'],
        'countryName': value['countryName'],
    };
}

