import { PageProps } from "gatsby";
import { createContext, ReactNode, useContext } from "react";

import { EnvironmentHelper } from "../functions/env";

export type ContractIDType = string;

export const ContractIDContext = createContext<ContractIDType>("");

export function useContractId(): ContractIDType {
	return useContext(ContractIDContext);
}

interface Props {
	children: ReactNode;
	contractId: string;
}

export function extractContractIDFromPageProps(pageProps: PageProps): string {
	if (EnvironmentHelper.isBuild()) return "";
	const url = new URL(pageProps.location.href);
	const id = url.searchParams.get("cislo");
	if (!id) throw new Error("Missing contract number");
	return id;
}

export function ContractIDProvider({
	children,
	contractId,
}: Props): JSX.Element {
	return (
		<ContractIDContext.Provider value={contractId}>
			{children}
		</ContractIDContext.Provider>
	);
}
