/* tslint:disable */
/* eslint-disable */
/**
 * NN.CustomerPortal.ApiApp.General
 * GeneralAPIs
 *
 * The version of the OpenAPI document: 1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SetContributionRequest
 */
export interface SetContributionRequest {
    /**
     * 
     * @type {number}
     * @memberof SetContributionRequest
     */
    contribution?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof SetContributionRequest
     */
    doesEmployerContribute?: boolean | null;
}

/**
 * Check if a given object implements the SetContributionRequest interface.
 */
export function instanceOfSetContributionRequest(value: object): value is SetContributionRequest {
    return true;
}

export function SetContributionRequestFromJSON(json: any): SetContributionRequest {
    return SetContributionRequestFromJSONTyped(json, false);
}

export function SetContributionRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): SetContributionRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'contribution': json['contribution'] == null ? undefined : json['contribution'],
        'doesEmployerContribute': json['doesEmployerContribute'] == null ? undefined : json['doesEmployerContribute'],
    };
}

export function SetContributionRequestToJSON(value?: SetContributionRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'contribution': value['contribution'],
        'doesEmployerContribute': value['doesEmployerContribute'],
    };
}

