/* tslint:disable */
/* eslint-disable */
/**
 * NN.CustomerPortal.ApiApp.General
 * GeneralAPIs
 *
 * The version of the OpenAPI document: 1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { AMLVerification } from './AMLVerification';
import {
    AMLVerificationFromJSON,
    AMLVerificationFromJSONTyped,
    AMLVerificationToJSON,
} from './AMLVerification';

/**
 * 
 * @export
 * @interface SharedClient
 */
export interface SharedClient {
    /**
     * 
     * @type {string}
     * @memberof SharedClient
     */
    degreeBefore?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SharedClient
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof SharedClient
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof SharedClient
     */
    citizenship?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SharedClient
     */
    degreeAfter?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SharedClient
     */
    birthNumber?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof SharedClient
     */
    dateOfBirth?: Date | null;
    /**
     * 
     * @type {AMLVerification}
     * @memberof SharedClient
     */
    identificationStatus: AMLVerification;
}

/**
 * Check if a given object implements the SharedClient interface.
 */
export function instanceOfSharedClient(value: object): value is SharedClient {
    if (!('firstName' in value) || value['firstName'] === undefined) return false;
    if (!('lastName' in value) || value['lastName'] === undefined) return false;
    if (!('identificationStatus' in value) || value['identificationStatus'] === undefined) return false;
    return true;
}

export function SharedClientFromJSON(json: any): SharedClient {
    return SharedClientFromJSONTyped(json, false);
}

export function SharedClientFromJSONTyped(json: any, ignoreDiscriminator: boolean): SharedClient {
    if (json == null) {
        return json;
    }
    return {
        
        'degreeBefore': json['degreeBefore'] == null ? undefined : json['degreeBefore'],
        'firstName': json['firstName'],
        'lastName': json['lastName'],
        'citizenship': json['citizenship'] == null ? undefined : json['citizenship'],
        'degreeAfter': json['degreeAfter'] == null ? undefined : json['degreeAfter'],
        'birthNumber': json['birthNumber'] == null ? undefined : json['birthNumber'],
        'dateOfBirth': json['dateOfBirth'] == null ? undefined : (new Date(json['dateOfBirth'])),
        'identificationStatus': AMLVerificationFromJSON(json['identificationStatus']),
    };
}

export function SharedClientToJSON(value?: SharedClient | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'degreeBefore': value['degreeBefore'],
        'firstName': value['firstName'],
        'lastName': value['lastName'],
        'citizenship': value['citizenship'],
        'degreeAfter': value['degreeAfter'],
        'birthNumber': value['birthNumber'],
        'dateOfBirth': value['dateOfBirth'] == null ? undefined : ((value['dateOfBirth'] as any).toISOString()),
        'identificationStatus': AMLVerificationToJSON(value['identificationStatus']),
    };
}

