/* tslint:disable */
/* eslint-disable */
/**
 * NN.CustomerPortal.ApiApp.General
 * GeneralAPIs
 *
 * The version of the OpenAPI document: 1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface IdentityDocument
 */
export interface IdentityDocument {
    /**
     * 
     * @type {number}
     * @memberof IdentityDocument
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof IdentityDocument
     */
    identityNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IdentityDocument
     */
    identityType?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof IdentityDocument
     */
    identityExpiryDate?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof IdentityDocument
     */
    identityAuthority?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IdentityDocument
     */
    sourceSystem?: string | null;
}

/**
 * Check if a given object implements the IdentityDocument interface.
 */
export function instanceOfIdentityDocument(value: object): value is IdentityDocument {
    if (!('id' in value) || value['id'] === undefined) return false;
    return true;
}

export function IdentityDocumentFromJSON(json: any): IdentityDocument {
    return IdentityDocumentFromJSONTyped(json, false);
}

export function IdentityDocumentFromJSONTyped(json: any, ignoreDiscriminator: boolean): IdentityDocument {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'identityNumber': json['identityNumber'] == null ? undefined : json['identityNumber'],
        'identityType': json['identityType'] == null ? undefined : json['identityType'],
        'identityExpiryDate': json['identityExpiryDate'] == null ? undefined : (new Date(json['identityExpiryDate'])),
        'identityAuthority': json['identityAuthority'] == null ? undefined : json['identityAuthority'],
        'sourceSystem': json['sourceSystem'] == null ? undefined : json['sourceSystem'],
    };
}

export function IdentityDocumentToJSON(value?: IdentityDocument | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'identityNumber': value['identityNumber'],
        'identityType': value['identityType'],
        'identityExpiryDate': value['identityExpiryDate'] == null ? undefined : ((value['identityExpiryDate'] as any).toISOString().substring(0,10)),
        'identityAuthority': value['identityAuthority'],
        'sourceSystem': value['sourceSystem'],
    };
}

