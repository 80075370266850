import { QueryKey, useQuery, UseQueryOptions } from "@tanstack/react-query";

import { GetLifeTransactionsGetRequest } from "../../apis";
import {
	DocumentDownloadResponse,
	GetFundsPerformanceResponse,
	GetLifeDetailResponse,
	GetLifeDetailV2Response,
	GetLifeTransactionsResponse,
	ProductType,
} from "../../models";
import { useContractTypeContext } from "../context/ContractTypeContext";
import {
	FetchApiError,
	usePrimaryAPIClient,
} from "../context/PrimaryAPIClient";
import { useQueryKeyFactory } from "../context/QueryKeyProvider";
import { LifeContractType } from "../types/contracts";
import { CustomApiError } from "../types/errors";

export type AcceptedLifeTypes = "CL" | "CLF";
export type LifeContractResponses =
	| GetLifeDetailResponse
	| GetLifeDetailV2Response;
const AcceptedLifeTypes: AcceptedLifeTypes[] = [
	ProductType.Cl,
	ProductType.Clf,
];
interface LifeDetailProps<
	TResult = GetLifeDetailResponse | GetLifeDetailV2Response
> {
	id: string;
	contractType?: AcceptedLifeTypes;
	options?: UseQueryOptions<LifeContractResponses, unknown, TResult, QueryKey>;
}

export const useLifeDetail = <
	TResult = GetLifeDetailResponse | GetLifeDetailV2Response,
>({
	id,
	contractType,
	options = {},
}: LifeDetailProps<TResult>) => {
	const { contractsLifeApi } = usePrimaryAPIClient();
	const { createQueryKey } = useQueryKeyFactory();
	const { contractType: contextContractType } = useContractTypeContext();
	const selectedContractType = (contractType ??
		contextContractType) as AcceptedLifeTypes;

	if (!AcceptedLifeTypes.includes(selectedContractType)) {
		throw new Error(
			`Wrong type passed to useLifeDetail. Type: ${contractType}`
		);
	}

	const fetchMethod = {
		[ProductType.Cl]: () =>
			contractsLifeApi.getLifeDetailGet({ contractId: id }),
		[ProductType.Clf]: () =>
			contractsLifeApi.getLifeDetailV2NdpGet({ contractId: id }),
	}[selectedContractType];

	return useQuery<LifeContractResponses, unknown, TResult, QueryKey>(
		createQueryKey({
			key: ["getLifeDetail", id],
			includeUserIdentifier: false,
		}),
		() => {
			return fetchMethod();
		},
		options
	);
};

interface ILifeFundsProps {
	id: string;
	options?: UseQueryOptions<GetFundsPerformanceResponse[]>;
}

export const useLifeFunds = ({ id, options = {} }: ILifeFundsProps) => {
	const { contractsLifeApi } = usePrimaryAPIClient();
	const { createQueryKey } = useQueryKeyFactory();

	return useQuery<GetFundsPerformanceResponse[]>(
		createQueryKey({ key: ["getLifeFunds", id], includeUserIdentifier: false }),
		() => {
			return contractsLifeApi.getLifeFundsGet({ contractId: id });
		},
		options
	);
};

export type LifeTransactionsQueryParams = Pick<
	GetLifeTransactionsGetRequest,
	"count" | "to" | "from" | "typeFilter"
>;

interface ILifeTransactionsProps {
	contractId: string;
	contractType: LifeContractType;
	params: LifeTransactionsQueryParams;
	options?: UseQueryOptions<GetLifeTransactionsResponse[]>;
}

export const transactionsPerRequest = 60;

export const isUnsupportedFufiContractTypeError = (
	error: unknown,
	contractType: unknown = ProductType.Clf
) => {
	if (contractType !== ProductType.Clf) return false;
	return (
		error instanceof FetchApiError &&
		error.errorResponse.code === CustomApiError.MethodNotAllowed
	);
};

export const useLifeTransactions = ({
	contractId,
	contractType,
	params,
	options = {},
}: ILifeTransactionsProps) => {
	const { contractsLifeApi } = usePrimaryAPIClient();
	const { createQueryKey } = useQueryKeyFactory();

	return useQuery(
		createQueryKey({
			key: ["getLifeTransactions", contractId, contractType, params],
			includeUserIdentifier: false,
		}),
		async () => {
			return await contractsLifeApi.getLifeTransactionsGet({
				contractId,
				count: params.count,
				from: params.from,
				to: params.to,
				productType: contractType,
				typeFilter: params.typeFilter,
			});
		},
		options
	);
};

interface ILifeDocumentsProps {
	id: string;
	options?: UseQueryOptions<DocumentDownloadResponse[]>;
}

export const useLifeDocuments = ({ id, options }: ILifeDocumentsProps) => {
	const { documentsApi } = usePrimaryAPIClient();
	const { createQueryKey } = useQueryKeyFactory();

	return useQuery<DocumentDownloadResponse[]>(
		createQueryKey({
			key: ["getLifeDocuments", id],
			includeUserIdentifier: false,
		}),
		() => {
			return documentsApi.getLifeDocumentsGet({ contractId: id });
		},
		options
	);
};
