import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import RowBasic, { SingleRowBasicProps } from "./RowBasic";
import TableWithSkeleton from "./TableWithSkeleton";

interface RowsProps {
	rows?: Array<SingleRowBasicProps>;
	titles?: Array<string>;
}

function BasicTable({ titles, rows }: RowsProps): JSX.Element {
	if (!titles || !rows) {
		return <TableWithSkeleton />;
	}
	return (
		<TableContainer component={Box}>
			<Table aria-label="table">
				<TableHead>
					<TableRow>
						{titles.map((title) => (
							<TableCell key={title}>{title}</TableCell>
						))}
					</TableRow>
				</TableHead>
				<TableBody>
					{rows.map((row, index) => (
						<RowBasic key={index} row={row} titles={titles} />
					))}
				</TableBody>
			</Table>
		</TableContainer>
	);
}

export default BasicTable;
