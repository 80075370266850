import { useContractId } from "../../context/ContractIDContext";
import { usePensionDetail } from "../../queryHooks";
import ClientShared from "./ClientShared";

function Client(): JSX.Element {
	const id = useContractId();

	const result = usePensionDetail({ id });

	return (
		<ClientShared
			isSuccess={result.isSuccess}
			isLoading={result.isLoading}
			person={result.data}
		/>
	);
}
export default Client;
