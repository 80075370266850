/* tslint:disable */
/* eslint-disable */
/**
 * NN.CustomerPortal.ApiApp.General
 * GeneralAPIs
 *
 * The version of the OpenAPI document: 1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Address } from './Address';
import {
    AddressFromJSON,
    AddressFromJSONTyped,
    AddressToJSON,
} from './Address';
import type { SharedClient } from './SharedClient';
import {
    SharedClientFromJSON,
    SharedClientFromJSONTyped,
    SharedClientToJSON,
} from './SharedClient';

/**
 * 
 * @export
 * @interface GetProfileResponse
 */
export interface GetProfileResponse {
    /**
     * 
     * @type {SharedClient}
     * @memberof GetProfileResponse
     */
    client: SharedClient;
    /**
     * 
     * @type {Array<Address>}
     * @memberof GetProfileResponse
     */
    permanentAddresses: Array<Address>;
    /**
     * 
     * @type {string}
     * @memberof GetProfileResponse
     */
    email?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProfileResponse
     */
    mobile?: string | null;
}

/**
 * Check if a given object implements the GetProfileResponse interface.
 */
export function instanceOfGetProfileResponse(value: object): value is GetProfileResponse {
    if (!('client' in value) || value['client'] === undefined) return false;
    if (!('permanentAddresses' in value) || value['permanentAddresses'] === undefined) return false;
    return true;
}

export function GetProfileResponseFromJSON(json: any): GetProfileResponse {
    return GetProfileResponseFromJSONTyped(json, false);
}

export function GetProfileResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetProfileResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'client': SharedClientFromJSON(json['client']),
        'permanentAddresses': ((json['permanentAddresses'] as Array<any>).map(AddressFromJSON)),
        'email': json['email'] == null ? undefined : json['email'],
        'mobile': json['mobile'] == null ? undefined : json['mobile'],
    };
}

export function GetProfileResponseToJSON(value?: GetProfileResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'client': SharedClientToJSON(value['client']),
        'permanentAddresses': ((value['permanentAddresses'] as Array<any>).map(AddressToJSON)),
        'email': value['email'],
        'mobile': value['mobile'],
    };
}

