/* tslint:disable */
/* eslint-disable */
/**
 * NN.CustomerPortal.ApiApp.General
 * GeneralAPIs
 *
 * The version of the OpenAPI document: 1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AssignMasterKeyRequest
 */
export interface AssignMasterKeyRequest {
    /**
     * 
     * @type {string}
     * @memberof AssignMasterKeyRequest
     */
    userId: string;
    /**
     * 
     * @type {string}
     * @memberof AssignMasterKeyRequest
     */
    masterKey: string;
    /**
     * 
     * @type {string}
     * @memberof AssignMasterKeyRequest
     */
    employeeId: string;
}

/**
 * Check if a given object implements the AssignMasterKeyRequest interface.
 */
export function instanceOfAssignMasterKeyRequest(value: object): value is AssignMasterKeyRequest {
    if (!('userId' in value) || value['userId'] === undefined) return false;
    if (!('masterKey' in value) || value['masterKey'] === undefined) return false;
    if (!('employeeId' in value) || value['employeeId'] === undefined) return false;
    return true;
}

export function AssignMasterKeyRequestFromJSON(json: any): AssignMasterKeyRequest {
    return AssignMasterKeyRequestFromJSONTyped(json, false);
}

export function AssignMasterKeyRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): AssignMasterKeyRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'userId': json['userId'],
        'masterKey': json['master-key'],
        'employeeId': json['employee-id'],
    };
}

export function AssignMasterKeyRequestToJSON(value?: AssignMasterKeyRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'userId': value['userId'],
        'master-key': value['masterKey'],
        'employee-id': value['employeeId'],
    };
}

