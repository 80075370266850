import { Button, Skeleton, Stack } from "@mui/material";
import useIsDesktop from "desktop/useIsDesktop";
import { AnimatePresence, motion } from "framer-motion";
import { ReactNode } from "react";

import {
	GetContractsResponse,
	GetDPSDetailResponse,
	GetLifeDetailV2Response,
	PensionPhaseStatus,
	ProductType,
} from "../../../models";
import InsuranceIcon from "../../components/Icons/Insurance";
import PigIcon from "../../components/Icons/Pig";
import { contractLink, NumberToCZK } from "../../functions";
import {
	useContractById,
	useLifeDetail,
	usePensionDetail,
} from "../../queryHooks";
import cs from "../../translations/cs.json";
import { formatAmountWithCurrencyCode } from "../../utils";
import CaretRight from "../Icons/CaretRight";
import InternalButtonLink from "../Link/InternalButtonLink";
import { BodyTextS, HeadingS } from "../Typography";
import { contractTypeToDescription, contractTypeToTitle } from "./ContractCard";
import LifeCardDescription from "./ContractCard/LifeCardDescription";

export const DetailLink = ({
	contract,
	link,
}: {
	contract?: GetContractsResponse;
	link?: string;
}) => {
	const isDesktop = useIsDesktop();

	if (!contract && !link) throw new Error("Contract or link must be provided");

	return (
		<InternalButtonLink
			to={link && !contract ? link : contractLink(contract.id, contract.type)}
			size="large"
			variant="contained"
			sx={{
				alignSelf: "center",
				width: isDesktop ? undefined : "100%",
				mt: isDesktop ? 0 : 5,
				p: isDesktop ? 3 : 0,
			}}
		>
			Detail
		</InternalButtonLink>
	);
};

export const AnimationDetailLinkWrapper = ({
	children,
}: {
	children?: ReactNode;
}) => {
	return (
		<motion.div
			initial={{ zoom: 0 }}
			animate={{ zoom: 1 }}
			exit={{ zoom: 0 }}
			transition={{
				duration: 0.2,
			}}
		>
			{children}
		</motion.div>
	);
};

interface IContractCardDesktopMenuView {
	title?: string;
	description?: string;
	contractNumber?: string;
	iconElement: JSX.Element;
	setContractIndex: () => void;
	isActive: boolean;
	ammountDisplay?: string | JSX.Element;
	data: GetContractsResponse;
}

const ContractCardDesktopMenuView = ({
	title,
	contractNumber,
	iconElement,
	description,
	ammountDisplay,
	isActive,
	setContractIndex,
	data,
}: IContractCardDesktopMenuView) => {
	return (
		<Button
			variant="clear"
			data-test="contract"
			sx={{
				display: "block",
				pb: 4,
				borderBottomWidth: "2px",
				borderBottomStyle: "solid",
				borderBottomColor: isActive ? "primary.main" : "white.main",
				borderRadius: 0,
				color: isActive ? "primary.main" : "grey.dark",
				textAlign: "unset",
				fontWeight: "unset",
				fontFamily: `"NNNittiGroteskTextRegular", sans-serif`,
				"&:focus": {
					outlineStyle: "none",
					borderBottomColor: "primary.main",
					color: "primary.main",
				},
				"&:hover": { backgroundColor: "unset" },
			}}
			onClick={() => setContractIndex()}
		>
			<Stack direction="row" justifyContent="space-between" alignItems="center">
				<Stack direction="row" alignItems="top">
					{iconElement}
					<Stack>
						<HeadingS sx={{ display: "flex", mb: 2 }} withoutScale>
							{title ?? <Skeleton />}
						</HeadingS>
						<BodyTextS mb={0}>
							{description || contractNumber ? (
								[description, contractNumber && `(${contractNumber})`]
									.filter((string) => !!string)
									.join(" ")
							) : (
								<Skeleton />
							)}
						</BodyTextS>
						<BodyTextS strong mb={0}>
							{ammountDisplay ?? <Skeleton />}
						</BodyTextS>
					</Stack>
				</Stack>
				<AnimatePresence>
					{isActive ? (
						<AnimationDetailLinkWrapper>
							<DetailLink contract={data} />
						</AnimationDetailLinkWrapper>
					) : (
						<CaretRight
							sx={{
								width: "0.8rem",
								height: "1.3rem",
								ml: 4,
							}}
						/>
					)}
				</AnimatePresence>
			</Stack>
		</Button>
	);
};

interface IContractCardDesktopMenu {
	data: GetContractsResponse;
	setContractIndex: () => void;
	contractIndex: number;
	selectedProduct: number;
}

export const ContractCardDesktopMenu = ({
	data,
	setContractIndex,
	selectedProduct,
	contractIndex,
}: IContractCardDesktopMenu): JSX.Element => {
	const contractDetail = usePensionDetail<GetDPSDetailResponse>({
		id: data.id,
		type: "UF",
		options: {
			enabled: data.type === ProductType.Uf,
		},
	});
	const { data: contract } = useContractById({
		id: data.id,
	});

	const getTotalSavings = () => {
		if (data.type === ProductType.Uf) {
			if (contractDetail.isLoading)
				return <Skeleton sx={{ display: "inline-block", width: 100, ml: 2 }} />;
			if (contractDetail.isError) return <></>;
			return contractDetail.data.isFundsChangeInProgress ? (
				`: ${cs.global.changeInProgress}`
			) : (
				<>&nbsp;{formatAmountWithCurrencyCode(data.amount)}</>
			);
		}
		return <>&nbsp;{formatAmountWithCurrencyCode(data.amount)}</>;
	};

	const isActive = contractIndex === selectedProduct;

	return (
		<Button
			variant="clear"
			data-test="contract"
			sx={{
				display: "block",
				pb: 4,
				borderBottomWidth: "2px",
				borderBottomStyle: "solid",
				borderBottomColor: isActive ? "primary.main" : "white.main",
				borderRadius: 0,
				color: isActive ? "primary.main" : "grey.dark",
				textAlign: "unset",
				fontWeight: "unset",
				fontFamily: `"NNNittiGroteskTextRegular", sans-serif`,
				"&:focus": {
					outlineStyle: "none",
					borderBottomColor: "primary.main",
					color: "primary.main",
				},
				"&:hover": { backgroundColor: "unset" },
			}}
			onClick={() => setContractIndex()}
		>
			<Stack direction="row" justifyContent="space-between" alignItems="center">
				<Stack direction="row" alignItems="top">
					{[ProductType.Uf, ProductType.Pf].includes(data.type) && (
						<PigIcon
							sx={{
								width: "2.4rem",
								height: "2.4rem",
								mr: 4,
							}}
						/>
					)}
					{[ProductType.Cl, ProductType.Clf].includes(data.type) && (
						<InsuranceIcon
							sx={{
								width: "2.4rem",
								height: "2.4rem",
								mr: 4,
							}}
						/>
					)}
					<Stack>
						<HeadingS sx={{ display: "flex", mb: 2 }} withoutScale>
							{data.isChildrenContract
								? `Dětské ${(
										contractTypeToTitle[data.type] as string
								  ).toLowerCase()}`
								: contractTypeToTitle[data.type]}
							{data.isChildrenContract && ` - ${data.childName}`}
						</HeadingS>
						<BodyTextS mb={0}>{`${data.title} (${data.id})`}</BodyTextS>
						<BodyTextS strong mb={0}>
							{[ProductType.Cl].includes(data.type) ? (
								<>
									Výše pravidelných plateb{" "}
									{formatAmountWithCurrencyCode(data.amount)}{" "}
									<LifeCardDescription contractId={data.id} frequency />
								</>
							) : (
								<>
									{contract?.pensionPhaseStatus ===
									PensionPhaseStatus.Payout ? (
										<>{cs.global.terminatedContract}</>
									) : (
										<>
											{!contractDetail.isError &&
												contractTypeToDescription[
													data.type as keyof typeof contractTypeToDescription
												]}
											{getTotalSavings()}
										</>
									)}
								</>
							)}
						</BodyTextS>
					</Stack>
				</Stack>
				<AnimatePresence>
					{isActive ? (
						<AnimationDetailLinkWrapper>
							<DetailLink contract={data} />
						</AnimationDetailLinkWrapper>
					) : (
						<CaretRight
							sx={{
								width: "0.8rem",
								height: "1.3rem",
								ml: 4,
							}}
						/>
					)}
				</AnimatePresence>
			</Stack>
		</Button>
	);
};

interface IContractCardDesktopMenuWithFufiData {
	data: GetContractsResponse;
	contractId: string;
	isActive: boolean;
	setContractIndex: () => void;
}

export const ContractCardDesktopMenuWithFufiData = ({
	contractId,
	isActive,
	setContractIndex,
	data: contract,
}: IContractCardDesktopMenuWithFufiData) => {
	const { data, isSuccess } = useLifeDetail<GetLifeDetailV2Response>({
		id: contractId,
		contractType: ProductType.Clf,
	});

	const ammmountDisplay = (
		<>
			{`${cs.life.global.monthlyPaymentAmmount}`}{" "}
			{isSuccess ? NumberToCZK(data.premiumClient) : <Skeleton width={30} />}
		</>
	);

	return (
		<ContractCardDesktopMenuView
			data={contract}
			title={contractTypeToTitle[ProductType.Clf]}
			description={
				isSuccess ? (data.productDescription as string | undefined) : undefined
			}
			ammountDisplay={ammmountDisplay}
			contractNumber={contractId}
			isActive={isActive}
			setContractIndex={setContractIndex}
			iconElement={
				<InsuranceIcon
					sx={{
						width: "2.4rem",
						height: "2.4rem",
						mr: 4,
					}}
				/>
			}
		/>
	);
};

export default ContractCardDesktopMenu;
