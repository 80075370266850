/* tslint:disable */
/* eslint-disable */
/**
 * NN.CustomerPortal.ApiApp.General
 * GeneralAPIs
 *
 * The version of the OpenAPI document: 1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const PensionTransactionEnum = {
    Other: 'Other',
    ContribEmployer: 'ContribEmployer',
    ContribClient: 'ContribClient',
    ContribState: 'ContribState'
} as const;
export type PensionTransactionEnum = typeof PensionTransactionEnum[keyof typeof PensionTransactionEnum];


export function instanceOfPensionTransactionEnum(value: any): boolean {
    for (const key in PensionTransactionEnum) {
        if (Object.prototype.hasOwnProperty.call(PensionTransactionEnum, key)) {
            if ((PensionTransactionEnum as Record<string, PensionTransactionEnum>)[key] === value) {
                return true;
            }
        }
    }
    return false;
}

export function PensionTransactionEnumFromJSON(json: any): PensionTransactionEnum {
    return PensionTransactionEnumFromJSONTyped(json, false);
}

export function PensionTransactionEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): PensionTransactionEnum {
    return json as PensionTransactionEnum;
}

export function PensionTransactionEnumToJSON(value?: PensionTransactionEnum | null): any {
    return value as any;
}

