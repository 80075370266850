/* tslint:disable */
/* eslint-disable */
/**
 * NN.CustomerPortal.ApiApp.General
 * GeneralAPIs
 *
 * The version of the OpenAPI document: 1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ChangeResources } from './ChangeResources';
import {
    ChangeResourcesFromJSON,
    ChangeResourcesFromJSONTyped,
    ChangeResourcesToJSON,
} from './ChangeResources';
import type { SfdrPreference } from './SfdrPreference';
import {
    SfdrPreferenceFromJSON,
    SfdrPreferenceFromJSONTyped,
    SfdrPreferenceToJSON,
} from './SfdrPreference';
import type { UserAnswersRequest } from './UserAnswersRequest';
import {
    UserAnswersRequestFromJSON,
    UserAnswersRequestFromJSONTyped,
    UserAnswersRequestToJSON,
} from './UserAnswersRequest';

/**
 * 
 * @export
 * @interface ZZJInformationRequest
 */
export interface ZZJInformationRequest {
    /**
     * 
     * @type {UserAnswersRequest}
     * @memberof ZZJInformationRequest
     */
    investmentQuestionnaire: UserAnswersRequest;
    /**
     * 
     * @type {ChangeResources}
     * @memberof ZZJInformationRequest
     */
    existingResources?: ChangeResources;
    /**
     * 
     * @type {ChangeResources}
     * @memberof ZZJInformationRequest
     */
    newResources?: ChangeResources;
    /**
     * 
     * @type {SfdrPreference}
     * @memberof ZZJInformationRequest
     */
    sfdrPreference: SfdrPreference;
}

/**
 * Check if a given object implements the ZZJInformationRequest interface.
 */
export function instanceOfZZJInformationRequest(value: object): value is ZZJInformationRequest {
    if (!('investmentQuestionnaire' in value) || value['investmentQuestionnaire'] === undefined) return false;
    if (!('sfdrPreference' in value) || value['sfdrPreference'] === undefined) return false;
    return true;
}

export function ZZJInformationRequestFromJSON(json: any): ZZJInformationRequest {
    return ZZJInformationRequestFromJSONTyped(json, false);
}

export function ZZJInformationRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ZZJInformationRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'investmentQuestionnaire': UserAnswersRequestFromJSON(json['investmentQuestionnaire']),
        'existingResources': json['existingResources'] == null ? undefined : ChangeResourcesFromJSON(json['existingResources']),
        'newResources': json['newResources'] == null ? undefined : ChangeResourcesFromJSON(json['newResources']),
        'sfdrPreference': SfdrPreferenceFromJSON(json['sfdrPreference']),
    };
}

export function ZZJInformationRequestToJSON(value?: ZZJInformationRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'investmentQuestionnaire': UserAnswersRequestToJSON(value['investmentQuestionnaire']),
        'existingResources': ChangeResourcesToJSON(value['existingResources']),
        'newResources': ChangeResourcesToJSON(value['newResources']),
        'sfdrPreference': SfdrPreferenceToJSON(value['sfdrPreference']),
    };
}

