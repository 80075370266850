import { Stack } from "@mui/material";
import { useFormikContext } from "formik";

import cs from "../../../../../translations/cs.json";
import SingleStepDrawer from "../../../../Drawer/SingleStepDrawer";
import Form from "../../../../Form/Form";
import FormErrorMessage from "../../../../Form/FormErrorMessage";
import DatePicker from "../../../../Form/Input/DatePicker";
import Switch from "../../../../Form/Input/Switch";
import TriggerButton from "../../../../Link/TriggerButton";

interface IResetFilterProps {
	handleHideDrawer: () => void;
}

function ResetFilter({ handleHideDrawer }: IResetFilterProps) {
	const { isSubmitting } = useFormikContext();

	return (
		<TriggerButton
			variant="text"
			type="reset"
			disabled={isSubmitting}
			onClick={handleHideDrawer}
		>
			Zrušit&nbsp;filtr
		</TriggerButton>
	);
}

interface IFilterTransactionsProps {
	handleHideDrawer: () => void;
}

const FilterTransactionsDrawer = ({
	handleHideDrawer,
}: IFilterTransactionsProps): JSX.Element => {
	const { errors } = useFormikContext();
	return (
		<SingleStepDrawer>
			<Form
				spacing={6}
				actions={<ResetFilter handleHideDrawer={handleHideDrawer} />}
			>
				<Stack spacing={4}>
					<Switch name="LIFE_INSURER" label="Moje platba" />
					<Switch name="LIFE_EMPLOYER" label="Platba zaměstnavatele" />
					<Switch name="LIFE_PAY_IN" label="Mimořádný vklad" />
					<Switch name="LIFE_PAY_OUT" label="Mimořádný výběr" />
					{errors.isAnySelected && (
						<FormErrorMessage>{cs.formErrors.isAnySelected}</FormErrorMessage>
					)}
				</Stack>
				<DatePicker
					name="dateFrom"
					placeholder="Datum od"
					options={{
						minDate: new Date("2010-01-01T00:00:00.000"),
						maxDate: new Date(),
					}}
				/>
				<DatePicker
					name="dateTo"
					placeholder="Datum do"
					options={{
						minDate: new Date("2010-01-01T00:00:00.000"),
						maxDate: new Date(),
					}}
				/>
			</Form>
		</SingleStepDrawer>
	);
};

export default FilterTransactionsDrawer;
