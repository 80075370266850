/* tslint:disable */
/* eslint-disable */
/**
 * NN.CustomerPortal.ApiApp.General
 * GeneralAPIs
 *
 * The version of the OpenAPI document: 1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Strategy } from './Strategy';
import {
    StrategyFromJSON,
    StrategyFromJSONTyped,
    StrategyToJSON,
} from './Strategy';
import type { Profile } from './Profile';
import {
    ProfileFromJSON,
    ProfileFromJSONTyped,
    ProfileToJSON,
} from './Profile';

/**
 * 
 * @export
 * @interface InvestorProfileResult
 */
export interface InvestorProfileResult {
    /**
     * 
     * @type {Profile}
     * @memberof InvestorProfileResult
     */
    investmentProfile: Profile;
    /**
     * 
     * @type {Strategy}
     * @memberof InvestorProfileResult
     */
    investmentStrategy: Strategy;
}

/**
 * Check if a given object implements the InvestorProfileResult interface.
 */
export function instanceOfInvestorProfileResult(value: object): value is InvestorProfileResult {
    if (!('investmentProfile' in value) || value['investmentProfile'] === undefined) return false;
    if (!('investmentStrategy' in value) || value['investmentStrategy'] === undefined) return false;
    return true;
}

export function InvestorProfileResultFromJSON(json: any): InvestorProfileResult {
    return InvestorProfileResultFromJSONTyped(json, false);
}

export function InvestorProfileResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): InvestorProfileResult {
    if (json == null) {
        return json;
    }
    return {
        
        'investmentProfile': ProfileFromJSON(json['investmentProfile']),
        'investmentStrategy': StrategyFromJSON(json['investmentStrategy']),
    };
}

export function InvestorProfileResultToJSON(value?: InvestorProfileResult | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'investmentProfile': ProfileToJSON(value['investmentProfile']),
        'investmentStrategy': StrategyToJSON(value['investmentStrategy']),
    };
}

