/* tslint:disable */
/* eslint-disable */
/**
 * NN.CustomerPortal.ApiApp.General
 * GeneralAPIs
 *
 * The version of the OpenAPI document: 1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const RequirementStatus = {
    New: 'New',
    Waiting: 'Waiting',
    Processed: 'Processed',
    Failed: 'Failed',
    Rejected: 'Rejected',
    TemporarilyStopped: 'TemporarilyStopped'
} as const;
export type RequirementStatus = typeof RequirementStatus[keyof typeof RequirementStatus];


export function instanceOfRequirementStatus(value: any): boolean {
    for (const key in RequirementStatus) {
        if (Object.prototype.hasOwnProperty.call(RequirementStatus, key)) {
            if ((RequirementStatus as Record<string, RequirementStatus>)[key] === value) {
                return true;
            }
        }
    }
    return false;
}

export function RequirementStatusFromJSON(json: any): RequirementStatus {
    return RequirementStatusFromJSONTyped(json, false);
}

export function RequirementStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): RequirementStatus {
    return json as RequirementStatus;
}

export function RequirementStatusToJSON(value?: RequirementStatus | null): any {
    return value as any;
}

