/* tslint:disable */
/* eslint-disable */
/**
 * NN.CustomerPortal.ApiApp.General
 * GeneralAPIs
 *
 * The version of the OpenAPI document: 1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const StrategyCode = {
    Rejected: 'REJECTED',
    Prepension: 'PREPENSION',
    Con: 'CON',
    Bal: 'BAL',
    Dyn: 'DYN'
} as const;
export type StrategyCode = typeof StrategyCode[keyof typeof StrategyCode];


export function instanceOfStrategyCode(value: any): boolean {
    for (const key in StrategyCode) {
        if (Object.prototype.hasOwnProperty.call(StrategyCode, key)) {
            if ((StrategyCode as Record<string, StrategyCode>)[key] === value) {
                return true;
            }
        }
    }
    return false;
}

export function StrategyCodeFromJSON(json: any): StrategyCode {
    return StrategyCodeFromJSONTyped(json, false);
}

export function StrategyCodeFromJSONTyped(json: any, ignoreDiscriminator: boolean): StrategyCode {
    return json as StrategyCode;
}

export function StrategyCodeToJSON(value?: StrategyCode | null): any {
    return value as any;
}

