/* tslint:disable */
/* eslint-disable */
/**
 * NN.CustomerPortal.ApiApp.General
 * GeneralAPIs
 *
 * The version of the OpenAPI document: 1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const BeneficiaryRelationship = {
    Other: 'Other',
    HusbandOrWife: 'HusbandOrWife',
    Fiance: 'Fiance',
    RegisteredPartner: 'RegisteredPartner',
    Child: 'Child',
    BrotherOrSister: 'BrotherOrSister',
    Parent: 'Parent',
    Bank: 'Bank'
} as const;
export type BeneficiaryRelationship = typeof BeneficiaryRelationship[keyof typeof BeneficiaryRelationship];


export function instanceOfBeneficiaryRelationship(value: any): boolean {
    for (const key in BeneficiaryRelationship) {
        if (Object.prototype.hasOwnProperty.call(BeneficiaryRelationship, key)) {
            if ((BeneficiaryRelationship as Record<string, BeneficiaryRelationship>)[key] === value) {
                return true;
            }
        }
    }
    return false;
}

export function BeneficiaryRelationshipFromJSON(json: any): BeneficiaryRelationship {
    return BeneficiaryRelationshipFromJSONTyped(json, false);
}

export function BeneficiaryRelationshipFromJSONTyped(json: any, ignoreDiscriminator: boolean): BeneficiaryRelationship {
    return json as BeneficiaryRelationship;
}

export function BeneficiaryRelationshipToJSON(value?: BeneficiaryRelationship | null): any {
    return value as any;
}

