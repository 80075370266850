/* tslint:disable */
/* eslint-disable */
/**
 * NN.CustomerPortal.ApiApp.General
 * GeneralAPIs
 *
 * The version of the OpenAPI document: 1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Address } from './Address';
import {
    AddressFromJSON,
    AddressFromJSONTyped,
    AddressToJSON,
} from './Address';
import type { Fund } from './Fund';
import {
    FundFromJSON,
    FundFromJSONTyped,
    FundToJSON,
} from './Fund';
import type { Employer } from './Employer';
import {
    EmployerFromJSON,
    EmployerFromJSONTyped,
    EmployerToJSON,
} from './Employer';
import type { PensionBeneficiary } from './PensionBeneficiary';
import {
    PensionBeneficiaryFromJSON,
    PensionBeneficiaryFromJSONTyped,
    PensionBeneficiaryToJSON,
} from './PensionBeneficiary';
import type { PensionContractStatus } from './PensionContractStatus';
import {
    PensionContractStatusFromJSON,
    PensionContractStatusFromJSONTyped,
    PensionContractStatusToJSON,
} from './PensionContractStatus';
import type { ContactInfo } from './ContactInfo';
import {
    ContactInfoFromJSON,
    ContactInfoFromJSONTyped,
    ContactInfoToJSON,
} from './ContactInfo';
import type { ContractParametersDPS } from './ContractParametersDPS';
import {
    ContractParametersDPSFromJSON,
    ContractParametersDPSFromJSONTyped,
    ContractParametersDPSToJSON,
} from './ContractParametersDPS';
import type { PensionPaymentInfo } from './PensionPaymentInfo';
import {
    PensionPaymentInfoFromJSON,
    PensionPaymentInfoFromJSONTyped,
    PensionPaymentInfoToJSON,
} from './PensionPaymentInfo';
import type { SharedClient } from './SharedClient';
import {
    SharedClientFromJSON,
    SharedClientFromJSONTyped,
    SharedClientToJSON,
} from './SharedClient';
import type { DPSUIStatus } from './DPSUIStatus';
import {
    DPSUIStatusFromJSON,
    DPSUIStatusFromJSONTyped,
    DPSUIStatusToJSON,
} from './DPSUIStatus';
import type { StarPensionClientTypeEnum } from './StarPensionClientTypeEnum';
import {
    StarPensionClientTypeEnumFromJSON,
    StarPensionClientTypeEnumFromJSONTyped,
    StarPensionClientTypeEnumToJSON,
} from './StarPensionClientTypeEnum';

/**
 * 
 * @export
 * @interface GetDPSDetailResponse
 */
export interface GetDPSDetailResponse {
    /**
     * 
     * @type {number}
     * @memberof GetDPSDetailResponse
     */
    savedAmount: number;
    /**
     * 
     * @type {Array<Fund>}
     * @memberof GetDPSDetailResponse
     */
    funds: Array<Fund>;
    /**
     * 
     * @type {boolean}
     * @memberof GetDPSDetailResponse
     */
    isFundsChangeInProgress: boolean;
    /**
     * 
     * @type {PensionPaymentInfo}
     * @memberof GetDPSDetailResponse
     */
    clientPaymentInfo: PensionPaymentInfo;
    /**
     * 
     * @type {PensionPaymentInfo}
     * @memberof GetDPSDetailResponse
     */
    employerPaymentInfo: PensionPaymentInfo;
    /**
     * 
     * @type {SharedClient}
     * @memberof GetDPSDetailResponse
     */
    clientInfo: SharedClient;
    /**
     * 
     * @type {ContactInfo}
     * @memberof GetDPSDetailResponse
     */
    contact: ContactInfo;
    /**
     * 
     * @type {Address}
     * @memberof GetDPSDetailResponse
     */
    permanentAddress: Address;
    /**
     * 
     * @type {Address}
     * @memberof GetDPSDetailResponse
     */
    contactAddress: Address;
    /**
     * 
     * @type {boolean}
     * @memberof GetDPSDetailResponse
     */
    isContactAddressChangeInProgress: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetDPSDetailResponse
     */
    isGlobalContactAddressChangeEnabled: boolean;
    /**
     * 
     * @type {ContractParametersDPS}
     * @memberof GetDPSDetailResponse
     */
    contract: ContractParametersDPS;
    /**
     * 
     * @type {Array<PensionBeneficiary>}
     * @memberof GetDPSDetailResponse
     */
    beneficiaries: Array<PensionBeneficiary>;
    /**
     * 
     * @type {PensionContractStatus}
     * @memberof GetDPSDetailResponse
     */
    status: PensionContractStatus;
    /**
     * 
     * @type {Employer}
     * @memberof GetDPSDetailResponse
     */
    employer?: Employer;
    /**
     * 
     * @type {DPSUIStatus}
     * @memberof GetDPSDetailResponse
     */
    uiStatus: DPSUIStatus;
    /**
     * 
     * @type {boolean}
     * @memberof GetDPSDetailResponse
     */
    isChildrenContract: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetDPSDetailResponse
     */
    isClientIdentified: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetDPSDetailResponse
     */
    isAmlFulfilled: boolean;
    /**
     * 
     * @type {StarPensionClientTypeEnum}
     * @memberof GetDPSDetailResponse
     */
    clientType: StarPensionClientTypeEnum;
}

/**
 * Check if a given object implements the GetDPSDetailResponse interface.
 */
export function instanceOfGetDPSDetailResponse(value: object): value is GetDPSDetailResponse {
    if (!('savedAmount' in value) || value['savedAmount'] === undefined) return false;
    if (!('funds' in value) || value['funds'] === undefined) return false;
    if (!('isFundsChangeInProgress' in value) || value['isFundsChangeInProgress'] === undefined) return false;
    if (!('clientPaymentInfo' in value) || value['clientPaymentInfo'] === undefined) return false;
    if (!('employerPaymentInfo' in value) || value['employerPaymentInfo'] === undefined) return false;
    if (!('clientInfo' in value) || value['clientInfo'] === undefined) return false;
    if (!('contact' in value) || value['contact'] === undefined) return false;
    if (!('permanentAddress' in value) || value['permanentAddress'] === undefined) return false;
    if (!('contactAddress' in value) || value['contactAddress'] === undefined) return false;
    if (!('isContactAddressChangeInProgress' in value) || value['isContactAddressChangeInProgress'] === undefined) return false;
    if (!('isGlobalContactAddressChangeEnabled' in value) || value['isGlobalContactAddressChangeEnabled'] === undefined) return false;
    if (!('contract' in value) || value['contract'] === undefined) return false;
    if (!('beneficiaries' in value) || value['beneficiaries'] === undefined) return false;
    if (!('status' in value) || value['status'] === undefined) return false;
    if (!('uiStatus' in value) || value['uiStatus'] === undefined) return false;
    if (!('isChildrenContract' in value) || value['isChildrenContract'] === undefined) return false;
    if (!('isClientIdentified' in value) || value['isClientIdentified'] === undefined) return false;
    if (!('isAmlFulfilled' in value) || value['isAmlFulfilled'] === undefined) return false;
    if (!('clientType' in value) || value['clientType'] === undefined) return false;
    return true;
}

export function GetDPSDetailResponseFromJSON(json: any): GetDPSDetailResponse {
    return GetDPSDetailResponseFromJSONTyped(json, false);
}

export function GetDPSDetailResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetDPSDetailResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'savedAmount': json['savedAmount'],
        'funds': ((json['funds'] as Array<any>).map(FundFromJSON)),
        'isFundsChangeInProgress': json['isFundsChangeInProgress'],
        'clientPaymentInfo': PensionPaymentInfoFromJSON(json['clientPaymentInfo']),
        'employerPaymentInfo': PensionPaymentInfoFromJSON(json['employerPaymentInfo']),
        'clientInfo': SharedClientFromJSON(json['clientInfo']),
        'contact': ContactInfoFromJSON(json['contact']),
        'permanentAddress': AddressFromJSON(json['permanentAddress']),
        'contactAddress': AddressFromJSON(json['contactAddress']),
        'isContactAddressChangeInProgress': json['isContactAddressChangeInProgress'],
        'isGlobalContactAddressChangeEnabled': json['isGlobalContactAddressChangeEnabled'],
        'contract': ContractParametersDPSFromJSON(json['contract']),
        'beneficiaries': ((json['beneficiaries'] as Array<any>).map(PensionBeneficiaryFromJSON)),
        'status': PensionContractStatusFromJSON(json['status']),
        'employer': json['employer'] == null ? undefined : EmployerFromJSON(json['employer']),
        'uiStatus': DPSUIStatusFromJSON(json['uiStatus']),
        'isChildrenContract': json['isChildrenContract'],
        'isClientIdentified': json['isClientIdentified'],
        'isAmlFulfilled': json['isAmlFulfilled'],
        'clientType': StarPensionClientTypeEnumFromJSON(json['clientType']),
    };
}

export function GetDPSDetailResponseToJSON(value?: GetDPSDetailResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'savedAmount': value['savedAmount'],
        'funds': ((value['funds'] as Array<any>).map(FundToJSON)),
        'isFundsChangeInProgress': value['isFundsChangeInProgress'],
        'clientPaymentInfo': PensionPaymentInfoToJSON(value['clientPaymentInfo']),
        'employerPaymentInfo': PensionPaymentInfoToJSON(value['employerPaymentInfo']),
        'clientInfo': SharedClientToJSON(value['clientInfo']),
        'contact': ContactInfoToJSON(value['contact']),
        'permanentAddress': AddressToJSON(value['permanentAddress']),
        'contactAddress': AddressToJSON(value['contactAddress']),
        'isContactAddressChangeInProgress': value['isContactAddressChangeInProgress'],
        'isGlobalContactAddressChangeEnabled': value['isGlobalContactAddressChangeEnabled'],
        'contract': ContractParametersDPSToJSON(value['contract']),
        'beneficiaries': ((value['beneficiaries'] as Array<any>).map(PensionBeneficiaryToJSON)),
        'status': PensionContractStatusToJSON(value['status']),
        'employer': EmployerToJSON(value['employer']),
        'uiStatus': DPSUIStatusToJSON(value['uiStatus']),
        'isChildrenContract': value['isChildrenContract'],
        'isClientIdentified': value['isClientIdentified'],
        'isAmlFulfilled': value['isAmlFulfilled'],
        'clientType': StarPensionClientTypeEnumToJSON(value['clientType']),
    };
}

