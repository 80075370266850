/* tslint:disable */
/* eslint-disable */
/**
 * NN.CustomerPortal.ApiApp.General
 * GeneralAPIs
 *
 * The version of the OpenAPI document: 1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { InsuredPersonRole } from './InsuredPersonRole';
import {
    InsuredPersonRoleFromJSON,
    InsuredPersonRoleFromJSONTyped,
    InsuredPersonRoleToJSON,
} from './InsuredPersonRole';
import type { Address } from './Address';
import {
    AddressFromJSON,
    AddressFromJSONTyped,
    AddressToJSON,
} from './Address';
import type { ContactInfo } from './ContactInfo';
import {
    ContactInfoFromJSON,
    ContactInfoFromJSONTyped,
    ContactInfoToJSON,
} from './ContactInfo';
import type { Rider } from './Rider';
import {
    RiderFromJSON,
    RiderFromJSONTyped,
    RiderToJSON,
} from './Rider';
import type { Beneficiary } from './Beneficiary';
import {
    BeneficiaryFromJSON,
    BeneficiaryFromJSONTyped,
    BeneficiaryToJSON,
} from './Beneficiary';

/**
 * 
 * @export
 * @interface InsuredPerson
 */
export interface InsuredPerson {
    /**
     * 
     * @type {string}
     * @memberof InsuredPerson
     */
    degreeBefore?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InsuredPerson
     */
    firstName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InsuredPerson
     */
    lastName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InsuredPerson
     */
    degreeAfter?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InsuredPerson
     */
    birthNumber?: string | null;
    /**
     * Null for Children
     * @type {Date}
     * @memberof InsuredPerson
     */
    dateOfBirth?: Date | null;
    /**
     * Null for Children
     * @type {string}
     * @memberof InsuredPerson
     */
    citizenship?: string | null;
    /**
     * 
     * @type {Address}
     * @memberof InsuredPerson
     */
    permanentAddress?: Address;
    /**
     * 
     * @type {Address}
     * @memberof InsuredPerson
     */
    contactAddress?: Address;
    /**
     * Valid only for PolicyHolder
     * @type {boolean}
     * @memberof InsuredPerson
     */
    isContactAddressChangeInProgress: boolean;
    /**
     * Valid only for PolicyHolder
     * @type {boolean}
     * @memberof InsuredPerson
     */
    isGlobalContactAddressChangeEnabled: boolean;
    /**
     * 
     * @type {ContactInfo}
     * @memberof InsuredPerson
     */
    contact?: ContactInfo;
    /**
     * 
     * @type {Array<Rider>}
     * @memberof InsuredPerson
     */
    riders: Array<Rider>;
    /**
     * Empty for Children
     * @type {Array<Beneficiary>}
     * @memberof InsuredPerson
     */
    beneficiaries: Array<Beneficiary>;
    /**
     * 
     * @type {InsuredPersonRole}
     * @memberof InsuredPerson
     */
    role: InsuredPersonRole;
}

/**
 * Check if a given object implements the InsuredPerson interface.
 */
export function instanceOfInsuredPerson(value: object): value is InsuredPerson {
    if (!('isContactAddressChangeInProgress' in value) || value['isContactAddressChangeInProgress'] === undefined) return false;
    if (!('isGlobalContactAddressChangeEnabled' in value) || value['isGlobalContactAddressChangeEnabled'] === undefined) return false;
    if (!('riders' in value) || value['riders'] === undefined) return false;
    if (!('beneficiaries' in value) || value['beneficiaries'] === undefined) return false;
    if (!('role' in value) || value['role'] === undefined) return false;
    return true;
}

export function InsuredPersonFromJSON(json: any): InsuredPerson {
    return InsuredPersonFromJSONTyped(json, false);
}

export function InsuredPersonFromJSONTyped(json: any, ignoreDiscriminator: boolean): InsuredPerson {
    if (json == null) {
        return json;
    }
    return {
        
        'degreeBefore': json['degreeBefore'] == null ? undefined : json['degreeBefore'],
        'firstName': json['firstName'] == null ? undefined : json['firstName'],
        'lastName': json['lastName'] == null ? undefined : json['lastName'],
        'degreeAfter': json['degreeAfter'] == null ? undefined : json['degreeAfter'],
        'birthNumber': json['birthNumber'] == null ? undefined : json['birthNumber'],
        'dateOfBirth': json['dateOfBirth'] == null ? undefined : (new Date(json['dateOfBirth'])),
        'citizenship': json['citizenship'] == null ? undefined : json['citizenship'],
        'permanentAddress': json['permanentAddress'] == null ? undefined : AddressFromJSON(json['permanentAddress']),
        'contactAddress': json['contactAddress'] == null ? undefined : AddressFromJSON(json['contactAddress']),
        'isContactAddressChangeInProgress': json['isContactAddressChangeInProgress'],
        'isGlobalContactAddressChangeEnabled': json['isGlobalContactAddressChangeEnabled'],
        'contact': json['contact'] == null ? undefined : ContactInfoFromJSON(json['contact']),
        'riders': ((json['riders'] as Array<any>).map(RiderFromJSON)),
        'beneficiaries': ((json['beneficiaries'] as Array<any>).map(BeneficiaryFromJSON)),
        'role': InsuredPersonRoleFromJSON(json['role']),
    };
}

export function InsuredPersonToJSON(value?: InsuredPerson | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'degreeBefore': value['degreeBefore'],
        'firstName': value['firstName'],
        'lastName': value['lastName'],
        'degreeAfter': value['degreeAfter'],
        'birthNumber': value['birthNumber'],
        'dateOfBirth': value['dateOfBirth'] == null ? undefined : ((value['dateOfBirth'] as any).toISOString()),
        'citizenship': value['citizenship'],
        'permanentAddress': AddressToJSON(value['permanentAddress']),
        'contactAddress': AddressToJSON(value['contactAddress']),
        'isContactAddressChangeInProgress': value['isContactAddressChangeInProgress'],
        'isGlobalContactAddressChangeEnabled': value['isGlobalContactAddressChangeEnabled'],
        'contact': ContactInfoToJSON(value['contact']),
        'riders': ((value['riders'] as Array<any>).map(RiderToJSON)),
        'beneficiaries': ((value['beneficiaries'] as Array<any>).map(BeneficiaryToJSON)),
        'role': InsuredPersonRoleToJSON(value['role']),
    };
}

