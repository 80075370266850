import { Box, BoxProps, Button, Skeleton, Stack } from "@mui/material";
import useIsDesktop from "desktop/useIsDesktop";

import {
	GetContractsResponse,
	GetDPSDetailResponse,
	GetLifeDetailResponse,
	PensionPhaseStatus,
	ProductType,
} from "../../../../models";
import { NumberToCZK } from "../../../functions";
import {
	useContractById,
	useDpsAllowedChanges,
	useLifeDetail,
	usePensionDetail,
} from "../../../queryHooks";
import cs from "../../../translations/cs.json";
import { formatAmount } from "../../../utils";
import Attention from "../../AttentionBlock";
import { BodyTextL, BodyTextS, HeadingM } from "../../Typography";
import { BodyTextM } from "../../Typography";
import { DetailLink } from "../ContractCardDesktopMenu";
import LifeCardDescription from "./LifeCardDescription";

export const contractTypeToTitle = {
	UF: "Penzijko",
	CL: "Životko",
	PF: "Penzijko",
	CLF: "Životko (Fufi)",
};

export const contractTypeToProductName = {
	UF: "Doplňkové penzijní spoření",
	PF: "Penzijní připojištění",
};

export const contractTypeToDescription = {
	UF: "Mám naspořeno",
	PF: "Mám naspořeno",
	CL: "Pravidelně platím",
	CLF: "Pravidelně platím",
};

interface ILifeProductName {
	contractId: string;
}

const LifeProductName = ({ contractId }: ILifeProductName) => {
	const { data, isSuccess } = useLifeDetail<GetLifeDetailResponse>({
		id: contractId,
	});
	if (isSuccess) {
		return <>{data.productName}</>;
	}
	return <Skeleton width={250} />;
};

export const CarretWrapper = ({ children, ...rest }: BoxProps): JSX.Element => {
	return (
		<Box
			sx={{
				py: 4,
				px: 3,
				cursor: "pointer",
			}}
			{...rest}
		>
			<Button sx={{ p: 0 }}>{children}</Button>
		</Box>
	);
};

interface ContractCardProps {
	contractId: string;
	position?: string;
	type: ProductType;
}

function ContractCard({
	contractId,
	position,
	type,
}: ContractCardProps): JSX.Element {
	const isDesktop = useIsDesktop();
	const { data: contract } = useContractById({
		id: contractId,
		options: {
			refetchOnMount: false,
		},
	});

	const { data, isInitialLoading } = useDpsAllowedChanges({
		contractId,
		options: {
			enabled: type === ProductType.Uf && !!contractId,
		},
	});

	const contractDetail = usePensionDetail<GetDPSDetailResponse>({
		id: contractId,
		type: "UF",
		options: { enabled: type === ProductType.Uf },
	});
	const isFufi = contract?.type === ProductType.Clf;
	const getContractName = (contract: GetContractsResponse) => {
		if (contract.type === ProductType.Clf) return contract.title;
		if (isDesktop) {
			return contract.type === ProductType.Cl ? (
				<LifeProductName contractId={contractId} />
			) : (
				contractTypeToProductName[contract.type as "UF" | "PF"]
			);
		}

		return contract.isChildrenContract
			? `${cs.global.child} ${contractTypeToTitle[
					contract.type as "UF" | "PF"
			  ].toLowerCase()}`
			: contractTypeToTitle[contract.type as "UF" | "PF"];
	};

	const getTotalSavings = () => {
		if (!contract) return <Skeleton width={150} />;
		if (type === ProductType.Uf) {
			if (contractDetail.isError) return <></>;
			if (!contractDetail.isSuccess) return <Skeleton width={150} />;
			return !isInitialLoading &&
				data !== undefined &&
				!data.canChangeInvestmentStrategy ? (
				<Attention severity="info" sx={{ mb: 4 }}>
					{cs.investmentStrategy.alerts.fundChangeInProgress.description}
				</Attention>
			) : (
				<>{NumberToCZK(contractDetail.data.savedAmount)}</>
			);
		}
		return <>{formatAmount(contract.amount)} Kč</>;
	};

	return (
		<Stack
			data-test="contract"
			sx={{
				display: !isDesktop && position === "preview" ? "none" : "flex",
				flexGrow: 1,
				height: "100%",
				p: position === "preview" ? 0 : 4,
				boxShadow: position === "preview" ? "none" : "close",
				borderRadius: 1,
			}}
		>
			<Stack direction="row" justifyContent="space-between" alignItems="center">
				<HeadingM sx={{ flexGrow: 1, mb: 0, pr: 4 }}>
					{contract ? getContractName(contract) : <Skeleton sx={{ mr: 1 }} />}
				</HeadingM>
			</Stack>
			<Stack>
				<BodyTextS light sx={{ mb: 4 }}>
					{contract ? (
						isDesktop || isFufi ? (
							`Smlouva číslo ${contractId}`
						) : (
							<>
								{[ProductType.Cl].includes(contract.type) ? (
									<LifeProductName contractId={contractId} />
								) : (
									contractTypeToProductName[contract.type]
								)}{" "}
								{contractId}
							</>
						)
					) : (
						<Skeleton sx={{ mr: 1 }} />
					)}
				</BodyTextS>
			</Stack>
			{contract && [ProductType.Cl].includes(contract.type) ? (
				<>
					<LifeCardDescription contractId={contractId} />
					<Stack
						direction={isDesktop ? "column" : "row"}
						justifyContent="space-between"
						alignItems={isDesktop ? "flex-start" : "center"}
					>
						<BodyTextS mb={0} light>
							{contractTypeToDescription[contract.type]}
						</BodyTextS>
						<BodyTextL>
							{formatAmount(contract.amount)} Kč{" "}
							<LifeCardDescription contractId={contractId} frequency />
						</BodyTextL>
					</Stack>
				</>
			) : (
				<>
					{contract?.pensionPhaseStatus === PensionPhaseStatus.Payout ? (
						<BodyTextM light>{cs.global.terminatedContract}</BodyTextM>
					) : (
						<>
							{!contractDetail.isError && (
								<BodyTextS mb={0} light>
									{contract ? (
										contractTypeToDescription[
											contract.type as keyof typeof contractTypeToDescription
										]
									) : (
										<Skeleton />
									)}
								</BodyTextS>
							)}
							{getTotalSavings()}
						</>
					)}
				</>
			)}
			{!isDesktop && <DetailLink contract={contract} />}
		</Stack>
	);
}

export default ContractCard;

export function ContractCardSkeleton({
	width,
}: {
	width: number;
}): JSX.Element {
	return (
		<Skeleton
			sx={{
				width: (theme) => `calc(${width * 100}% - ${theme.spacing(6)}/2)`,
				mb: 6,
			}}
			height={115}
			variant="rectangular"
		/>
	);
}
export function ContractCardSkeletonHeader(): JSX.Element {
	return (
		<Stack>
			<Skeleton sx={{ width: "70%", height: "40px" }} />
			<Stack direction="row" spacing={2}>
				<BodyTextS>{cs.global.contractNumber}</BodyTextS>
				<Skeleton sx={{ width: "20%", height: "20px" }} />
			</Stack>

			<Box>
				<BodyTextS>{cs.global.savings}</BodyTextS>
				<Skeleton sx={{ width: "30%" }} />
			</Box>
		</Stack>
	);
}
