/* tslint:disable */
/* eslint-disable */
/**
 * NN.CustomerPortal.ApiApp.General
 * GeneralAPIs
 *
 * The version of the OpenAPI document: 1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const AddressType = {
    Delivery: 'Delivery',
    Permanent: 'Permanent',
    Financial: 'Financial',
    Unknown: 'Unknown'
} as const;
export type AddressType = typeof AddressType[keyof typeof AddressType];


export function instanceOfAddressType(value: any): boolean {
    for (const key in AddressType) {
        if (Object.prototype.hasOwnProperty.call(AddressType, key)) {
            if ((AddressType as Record<string, AddressType>)[key] === value) {
                return true;
            }
        }
    }
    return false;
}

export function AddressTypeFromJSON(json: any): AddressType {
    return AddressTypeFromJSONTyped(json, false);
}

export function AddressTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): AddressType {
    return json as AddressType;
}

export function AddressTypeToJSON(value?: AddressType | null): any {
    return value as any;
}

