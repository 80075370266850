import { useMsal } from "@azure/msal-react";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { Formik, FormikProps } from "formik";
import { useEffect, useRef } from "react";

import { ContractsDpsApi, ContractsPpApi } from "../../../../apis";
import { ProductType } from "../../../../models";
import cs from "../../../translations/cs.json";
import { PensionContractType } from "../../../types/contracts";
import { AMLRedirect } from "../../AML";
import { GetAMLQuestionsDrawerStep } from "../../AML/Form/questions";
import {
	GetBankIdIndetificationDrawerErrorStep,
	GetBankIdIndetificationDrawerStep,
} from "../../AML/identification";
import { useSmartAction } from "../../Contract/SmartAction/context";
import { useDrawer } from "../../Drawer/context";
import FormMultiStepDrawer from "../../Drawer/FormMultiStepDrawer";
import {
	mapFetchApiErrorToSubmitStatus,
	SubmitStatus,
	SubmitStatusStep,
} from "../../Drawer/GlobalSteps/SubmitStatusStep";
import { MultiStepChildren } from "../../Drawer/MultiStepDrawer";
import { getMonthlyContributionValidation } from "../../Form/rules";
import ChangesOverview from "../Drawers/ClientContributionChange/ChangesOverview";
import SetNewAmount, {
	SetNewAmmountHandle,
} from "../Drawers/ClientContributionChange/SetNewAmount";
import { mapContributionRequest } from "./helpers";

export enum ContributionAction {
	Monthly,
	Employer,
}

export type DialogValues = {
	monthlyContribution: number;
	doesEmployerContribute: boolean;
};

interface IContributionDialog {
	presetValues: DialogValues;
	currentValues: DialogValues;
	contractType: PensionContractType;
	contractsPpApi: ContractsPpApi;
	contractsDpsApi: ContractsDpsApi;
	refetchOnSuccess: () => Promise<any>;
	refetchOnError: () => Promise<any>;
	id: string;
	includeAML: boolean;
	includeID: boolean;
	contributionAction?: ContributionAction;
}

function ContributionDialog({
	presetValues,
	currentValues,
	contractType,
	contractsPpApi,
	contractsDpsApi,
	refetchOnSuccess,
	refetchOnError,
	id,
	includeAML,
	includeID,
	contributionAction,
}: IContributionDialog) {
	const ai = useAppInsightsContext();
	const { nextStep } = useDrawer();
	const { action } = useSmartAction();
	const { instance } = useMsal();
	const setNewAmmountHandle = useRef<SetNewAmmountHandle>(null);

	useEffect(() => {
		if (contributionAction === ContributionAction.Employer) {
			setNewAmmountHandle.current?.scrollToEmployerContribution();
		}
	}, [contributionAction]);

	const trackContributionIncrease = (newAmount: number) => {
		const delta = newAmount - currentValues.monthlyContribution;

		const properties = {
			delta: delta,
			previous: currentValues.monthlyContribution,
			new: newAmount,
			contractId: id,
			attribution:
				instance.getActiveAccount()?.idTokenClaims?.partner_code || "NN",
		};

		ai.trackEvent({
			name: "increasedContribution",
			properties,
		});

		if (action?.type === "Fond") {
			ai.trackEvent({
				name: "increasedContribution [smartAction]",
				properties,
			});
		}
	};

	const getDrawerSteps = (
		props: Pick<
			FormikProps<{
				monthlyContribution: number | null | undefined;
			}>,
			"setSubmitting" | "submitForm" | "status"
		>
	): MultiStepChildren[] => {
		const displayID = includeID && action?.type !== "FondBankId";
		const displayIDErrorStep =
			action?.type === "FondBankId" &&
			action.context &&
			"success" in action.context &&
			action.context.success === false;
		const idErrorMessage =
			action?.context && "errorMessage" in action.context
				? action.context.errorMessage
				: undefined;
		const steps = [
			{
				component: (
					<SetNewAmount
						key="set-new-amount"
						currentAmount={presetValues.monthlyContribution}
						id={id}
						contractType={contractType}
						ref={setNewAmmountHandle}
					/>
				),
				stepName: "SetNewAmount",
			},
			{
				component: <ChangesOverview key="changes-overview" />,
				stepConfig: {
					label: "Potvrdit změnu",
					customFunction: () => {
						void props.submitForm();
						nextStep();
					},
				},
				stepName: "ChangesOverview",
			},
			{
				component: (
					<SubmitStatusStep
						status={props.status}
						successProps={{ attention: cs.contributionAttention }}
					/>
				),
				stepName: "SubmitStatusStep",
			},
		] as MultiStepChildren[];

		if (includeAML) {
			steps.unshift(
				GetAMLQuestionsDrawerStep({
					contractId: id,
					contractType,
					helpText: cs.AML.amlReasoning.monthlyContribution,
					saveText: cs.AML.amlSave.monthlyContribution,
					onSubmitCallback: (error) => {
						if (!error) {
							nextStep();
						} else {
							throw error;
						}
					},
				})
			);
		}

		if (displayID) {
			steps.unshift(
				GetBankIdIndetificationDrawerStep({
					actionInfo: {
						redirect: AMLRedirect.Contribution,
						contractId: id,
						contractType: contractType,
					},
					scenario: "contribution",
					onSkip: () => nextStep(),
				})
			);
		}

		if (displayIDErrorStep) {
			steps.unshift(
				GetBankIdIndetificationDrawerErrorStep({
					errorMessage: idErrorMessage as string | undefined,
					onSkip: () => {
						nextStep();
					},
				})
			);
		}

		return steps;
	};

	return (
		<Formik
			initialValues={presetValues}
			validationSchema={getMonthlyContributionValidation(currentValues)}
			initialTouched={{ valuesChanged: true }}
			onSubmit={async (
				{ monthlyContribution, doesEmployerContribute },
				{ setStatus }
			) => {
				setStatus(SubmitStatus.Submitting);
				try {
					if (contractType === ProductType.Pf) {
						await contractsPpApi.setPPContributionPut({
							contractId: id,
							setContributionRequest: mapContributionRequest(
								{
									contribution: monthlyContribution,
									doesEmployerContribute,
								},
								currentValues
							),
						});
					}
					if (contractType === ProductType.Uf) {
						await contractsDpsApi.setDPSContributionPut({
							contractId: id,
							setContributionRequest: mapContributionRequest(
								{
									contribution: monthlyContribution,
									doesEmployerContribute,
								},
								currentValues
							),
						});
					}
					await refetchOnSuccess();
					setStatus(SubmitStatus.Success);
					trackContributionIncrease(monthlyContribution);
				} catch (error) {
					await refetchOnError();
					setStatus(mapFetchApiErrorToSubmitStatus(error));
				}
			}}
		>
			{({ submitForm, status, setSubmitting }) => {
				return (
					<FormMultiStepDrawer title={cs.employerContributionDialog.title}>
						{getDrawerSteps({
							submitForm,
							status,
							setSubmitting,
						})}
					</FormMultiStepDrawer>
				);
			}}
		</Formik>
	);
}

export default ContributionDialog;
