/* tslint:disable */
/* eslint-disable */
/**
 * NN.CustomerPortal.ApiApp.General
 * GeneralAPIs
 *
 * The version of the OpenAPI document: 1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AreConsentsNeededResponse
 */
export interface AreConsentsNeededResponse {
    /**
     * 
     * @type {boolean}
     * @memberof AreConsentsNeededResponse
     */
    isNeeded: boolean;
}

/**
 * Check if a given object implements the AreConsentsNeededResponse interface.
 */
export function instanceOfAreConsentsNeededResponse(value: object): value is AreConsentsNeededResponse {
    if (!('isNeeded' in value) || value['isNeeded'] === undefined) return false;
    return true;
}

export function AreConsentsNeededResponseFromJSON(json: any): AreConsentsNeededResponse {
    return AreConsentsNeededResponseFromJSONTyped(json, false);
}

export function AreConsentsNeededResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): AreConsentsNeededResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'isNeeded': json['isNeeded'],
    };
}

export function AreConsentsNeededResponseToJSON(value?: AreConsentsNeededResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'isNeeded': value['isNeeded'],
    };
}

