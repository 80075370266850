import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { ButtonProps, Stack } from "@mui/material";
import { isNil } from "lodash";
import { ElementType, ReactNode } from "react";

import { ProductType } from "../../../../models";
import { contractLink } from "../../../functions";
import {
	useAllowedChangesForContract,
	useContractById,
	usePensionDetail,
	usePhoneNumbers,
} from "../../../queryHooks";
import { SupportedContractType } from "../../../types/contracts";
import Button from "../../Link/InternalButtonLink";
import { HeadingXS } from "../../Typography";
import { ActionType, useSmartAction } from "./context";
import { SmartActionWarnings } from "./SmartAction.helpers";
import { SmartActionComponents as SAC } from "./SmartActionComponents";

export interface IAttention {
	text: string;
}

export interface SmartAction {
	type: SupportedContractType;
}

export interface SmartActionsByIdProps {
	id: string;
	type: SupportedContractType;
}
export interface SmartActionsByTypeProps {
	type: SupportedContractType;
	id: string;
}

// TODO fix typing, split into files,
// split into different components by contract type, fix broken loading state display
function SmartActionsByType({
	type,
	id,
	...rest
}: SmartActionsByTypeProps): JSX.Element {
	const { data, isSuccess: contractByIdSuccess } = useContractById({
		id: id,
		options: { refetchOnMount: false, enabled: !!id },
	});

	const { isSuccess: isDetailSuccess, isError: isDetailError } =
		usePensionDetail({
			id,
			type,
			options: {
				enabled: type === ProductType.Uf || type === ProductType.Pf,
			},
		});

	const {
		data: allowedChanges,
		isSuccess: allowedChangesSuccess,
		isError: allowedChangesError,
	} = useAllowedChangesForContract({
		id: id,
		options: {
			enabled: !!id,
		},
	});

	const phoneNumbers = usePhoneNumbers({
		options: {
			enabled: type === ProductType.Uf,
		},
	});

	if (allowedChangesError || phoneNumbers.isError || isDetailError)
		return <></>;

	const authPhoneNumbers = phoneNumbers.isSuccess && phoneNumbers.data;

	const showInvestmentStrategy =
		process.env.GATSBY_FEATURE_IS_SAVINGS_STRATEGY_ENABLED === "true" &&
		authPhoneNumbers &&
		allowedChangesSuccess &&
		allowedChanges?.canChangeInvestmentStrategy === true;

	const getIsReady = () => {
		if (isNil(allowedChanges) || !allowedChangesSuccess) return false;

		if (!type || !contractByIdSuccess) return false;

		if (type === ProductType.Uf) {
			return phoneNumbers.isSuccess && allowedChangesSuccess && isDetailSuccess;
		}

		if (type === ProductType.Pf) return isDetailSuccess;

		return true;
	};

	const smartActions: { [key in keyof typeof SAC]: SmartActionListType } = {
		Fond: {
			component: SAC.Fond,
			tab: "smlouva",
			hasCustomEvent: true,
			disabled: allowedChanges?.canChangeEmployerOrClientContribution !== true,
		},
		EmployerContribution: {
			component: SAC.EmployerContribution,
			tab: "smlouva",
			hasCustomEvent: true,
			disabled: allowedChanges?.canChangeEmployerOrClientContribution !== true,
		},
		Changes: {
			component: SAC.Changes,
			url: "/profil/zmeny/",
			hasCustomEvent: false,
		},
		Payments: {
			component: SAC.Payments,
			tab: "pohyby-platby",
			hasCustomEvent: false,
		},
		InvestmentStrategy: {
			component: SAC.InvestmentStrategy,
			tab: "detail",
			hasCustomEvent: showInvestmentStrategy,
			disabled: allowedChanges?.canChangeInvestmentStrategy !== true,
		},
		Coverage: {
			component: SAC.Coverage,
			tab: "osoby-kryti",
			hasCustomEvent: false,
		},
		TaxCertificate: {
			component: SAC.TaxCertificate,
			tab: "dokumenty",
			hasCustomEvent: false,
		},
		ChangeAdress: {
			component: SAC.ChangeAdress,
			tab: "klient",
			hasCustomEvent: false,
			disabled: allowedChanges?.canChangeElectronicContacts !== true,
		},
		NextPayment: {
			component: SAC.NextPayment,
			tab: "pohyby-platby",
			hasCustomEvent: false,
		},
		Report: {
			component: SAC.Report,
			tab: "dokumenty",
			hasCustomEvent: false,
		},
		SmartActionSkeleton: {
			component: SAC.SmartActionSkeleton,
		},
	};

	// renders only the first 3 actions in each product
	const mapSmartActionType: Record<
		SupportedContractType,
		SmartActionListType[]
	> = {
		PF: [
			smartActions.Fond,
			smartActions.EmployerContribution,
			smartActions.Changes,
			smartActions.Payments,
			smartActions.NextPayment,
			smartActions.TaxCertificate,
		],
		UF: [
			smartActions.Fond,
			data?.isChildrenContract
				? smartActions.Payments
				: smartActions.EmployerContribution,
			showInvestmentStrategy
				? smartActions.InvestmentStrategy
				: smartActions.Changes,
			smartActions.ChangeAdress,
			smartActions.Payments,
			smartActions.NextPayment,
			smartActions.TaxCertificate,
		],
		CL: [
			smartActions.Coverage,
			{ ...smartActions.Payments, tab: "pohyby" },
			smartActions.Changes,
			smartActions.ChangeAdress,
			{ ...smartActions.NextPayment, tab: "pohyby" },
			smartActions.Report,
		],
		CLF: [
			smartActions.Coverage,
			{ ...smartActions.Payments, tab: "pohyby" },
			smartActions.Changes,
			smartActions.ChangeAdress,
			{ ...smartActions.NextPayment, tab: "pohyby" },
			smartActions.Report,
		],
	};

	const smartActionComponent = mapSmartActionType[type];

	if (!getIsReady()) {
		return (
			<Stack
				direction="row"
				spacing={4}
				justifyContent="center"
				sx={{ width: "100%" }}
			>
				<SAC.SmartActionSkeleton />
				<SAC.SmartActionSkeleton />
				<SAC.SmartActionSkeleton />
			</Stack>
		);
	}

	return (
		<Stack direction="column">
			<Stack spacing={3} direction="row" justifyContent="center">
				{smartActionComponent
					.filter((v) => !v.disabled)
					.slice(0, 3)
					.map((Item, i) => {
						if (Item.hidden) return null;
						return (
							<Item.component
								key={`smart-action-${i}`}
								href={
									Item.tab ? contractLink(id, data?.type, Item.tab) : Item.url
								}
								hasCustomEvent={Item.hasCustomEvent}
								contractId={id}
								disabled={Item.disabled}
								{...rest}
							/>
						);
					})}
			</Stack>

			<Stack spacing={2} direction="column">
				<SmartActionWarnings allowedChanges={allowedChanges} />
			</Stack>
		</Stack>
	);
}

function SmartActionsById({
	type,
	id,
	...rest
}: SmartActionsByIdProps | Record<string, never>): JSX.Element {
	return <SmartActionsByType type={type} id={id} {...rest} />;
}

export interface FondProps {
	amount: number;
}

export interface NextPaymentProps extends FondProps {
	date: string;
}

export interface ISmartAction {
	children: ReactNode;
	icon: ButtonProps["startIcon"];
	action: ActionType;
	href: string;
	contractId: string;
	hasCustomEvent: boolean;
	disabled?: boolean;
}

type SmartActionListType = {
	component: ElementType;
	url?: string;
	tab?: string;
	hasCustomEvent?: boolean;
	disabled?: boolean;
	hidden?: boolean;
};

const eventNameByActionName: {
	[key in Exclude<ActionType, undefined>]: string | undefined;
} = {
	Changes: "Seznam změn",
	NextPayment: "Moje příští platba",
	Payments: "Přehled plateb",
	Report: "Hlášení pojistné události",
	Fond: "Změna měsíčního příspěvku",
	TaxCertificate: "Daňové potvrzení",
	EmployerContribution: "Změna přispívání zaměstnavatele",
	Coverage: "Pojistná krytí",
	ChangeAdress: "Změna adresy",
	InvestmentStrategy: "Změnit investiční strategii",
	FondBankId: undefined,
	InvestmentStrategyBankId: undefined,
};

export function SmartAction({
	children,
	icon,
	action,
	href,
	hasCustomEvent,
	contractId,
	disabled = false,
}: ISmartAction) {
	const { setAction } = useSmartAction();
	const ai = useAppInsightsContext();
	return (
		<Button
			variant="smartAction"
			disabled={disabled}
			startIcon={icon}
			onClick={() => {
				ai.trackEvent(
					{
						name: `${
							!action || !eventNameByActionName[action]
								? "nedefinovaná"
								: eventNameByActionName[action]
						} [smartAction]`,
					},
					{ contractId }
				);

				if (hasCustomEvent) {
					setAction({ type: action });
				}
			}}
			href={href}
		>
			<Stack sx={{ flexGrow: 1, justifyContent: "center" }}>
				<HeadingXS
					withoutScale
					sx={{ mb: 0, fontSize: { md: "1.4rem", xxs: "1.2rem" } }}
				>
					{children}
				</HeadingXS>
			</Stack>
		</Button>
	);
}

export default SmartActionsById;
