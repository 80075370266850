/* tslint:disable */
/* eslint-disable */
/**
 * NN.CustomerPortal.ApiApp.General
 * GeneralAPIs
 *
 * The version of the OpenAPI document: 1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Organization } from './Organization';
import {
    OrganizationFromJSON,
    OrganizationFromJSONTyped,
    OrganizationToJSON,
} from './Organization';
import type { Contacts } from './Contacts';
import {
    ContactsFromJSON,
    ContactsFromJSONTyped,
    ContactsToJSON,
} from './Contacts';
import type { NdpAddress } from './NdpAddress';
import {
    NdpAddressFromJSON,
    NdpAddressFromJSONTyped,
    NdpAddressToJSON,
} from './NdpAddress';
import type { KindEnum } from './KindEnum';
import {
    KindEnumFromJSON,
    KindEnumFromJSONTyped,
    KindEnumToJSON,
} from './KindEnum';
import type { TypeEnum } from './TypeEnum';
import {
    TypeEnumFromJSON,
    TypeEnumFromJSONTyped,
    TypeEnumToJSON,
} from './TypeEnum';
import type { Person } from './Person';
import {
    PersonFromJSON,
    PersonFromJSONTyped,
    PersonToJSON,
} from './Person';

/**
 * 
 * @export
 * @interface Participant
 */
export interface Participant {
    /**
     * 
     * @type {number}
     * @memberof Participant
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof Participant
     */
    masterId: number;
    /**
     * 
     * @type {KindEnum}
     * @memberof Participant
     */
    kind: KindEnum;
    /**
     * 
     * @type {TypeEnum}
     * @memberof Participant
     */
    type: TypeEnum;
    /**
     * 
     * @type {Person}
     * @memberof Participant
     */
    person?: Person;
    /**
     * 
     * @type {Organization}
     * @memberof Participant
     */
    organization?: Organization;
    /**
     * 
     * @type {NdpAddress}
     * @memberof Participant
     */
    permanentAddress?: NdpAddress;
    /**
     * 
     * @type {NdpAddress}
     * @memberof Participant
     */
    contactAddress?: NdpAddress;
    /**
     * 
     * @type {Contacts}
     * @memberof Participant
     */
    contacts: Contacts;
    /**
     * 
     * @type {string}
     * @memberof Participant
     */
    sourceSystem?: string | null;
}

/**
 * Check if a given object implements the Participant interface.
 */
export function instanceOfParticipant(value: object): value is Participant {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('masterId' in value) || value['masterId'] === undefined) return false;
    if (!('kind' in value) || value['kind'] === undefined) return false;
    if (!('type' in value) || value['type'] === undefined) return false;
    if (!('contacts' in value) || value['contacts'] === undefined) return false;
    return true;
}

export function ParticipantFromJSON(json: any): Participant {
    return ParticipantFromJSONTyped(json, false);
}

export function ParticipantFromJSONTyped(json: any, ignoreDiscriminator: boolean): Participant {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'masterId': json['masterId'],
        'kind': KindEnumFromJSON(json['kind']),
        'type': TypeEnumFromJSON(json['type']),
        'person': json['person'] == null ? undefined : PersonFromJSON(json['person']),
        'organization': json['organization'] == null ? undefined : OrganizationFromJSON(json['organization']),
        'permanentAddress': json['permanentAddress'] == null ? undefined : NdpAddressFromJSON(json['permanentAddress']),
        'contactAddress': json['contactAddress'] == null ? undefined : NdpAddressFromJSON(json['contactAddress']),
        'contacts': ContactsFromJSON(json['contacts']),
        'sourceSystem': json['sourceSystem'] == null ? undefined : json['sourceSystem'],
    };
}

export function ParticipantToJSON(value?: Participant | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'masterId': value['masterId'],
        'kind': KindEnumToJSON(value['kind']),
        'type': TypeEnumToJSON(value['type']),
        'person': PersonToJSON(value['person']),
        'organization': OrganizationToJSON(value['organization']),
        'permanentAddress': NdpAddressToJSON(value['permanentAddress']),
        'contactAddress': NdpAddressToJSON(value['contactAddress']),
        'contacts': ContactsToJSON(value['contacts']),
        'sourceSystem': value['sourceSystem'],
    };
}

