/* tslint:disable */
/* eslint-disable */
/**
 * NN.CustomerPortal.ApiApp.General
 * GeneralAPIs
 *
 * The version of the OpenAPI document: 1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const ContactType = {
    Agent: 'Agent',
    Broker: 'Broker',
    Default: 'Default'
} as const;
export type ContactType = typeof ContactType[keyof typeof ContactType];


export function instanceOfContactType(value: any): boolean {
    for (const key in ContactType) {
        if (Object.prototype.hasOwnProperty.call(ContactType, key)) {
            if ((ContactType as Record<string, ContactType>)[key] === value) {
                return true;
            }
        }
    }
    return false;
}

export function ContactTypeFromJSON(json: any): ContactType {
    return ContactTypeFromJSONTyped(json, false);
}

export function ContactTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ContactType {
    return json as ContactType;
}

export function ContactTypeToJSON(value?: ContactType | null): any {
    return value as any;
}

