/* tslint:disable */
/* eslint-disable */
/**
 * NN.CustomerPortal.ApiApp.General
 * GeneralAPIs
 *
 * The version of the OpenAPI document: 1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CmsWarningsResponse
 */
export interface CmsWarningsResponse {
    /**
     * 
     * @type {string}
     * @memberof CmsWarningsResponse
     */
    error?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CmsWarningsResponse
     */
    warning?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CmsWarningsResponse
     */
    info?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CmsWarningsResponse
     */
    success?: string | null;
}

/**
 * Check if a given object implements the CmsWarningsResponse interface.
 */
export function instanceOfCmsWarningsResponse(value: object): value is CmsWarningsResponse {
    return true;
}

export function CmsWarningsResponseFromJSON(json: any): CmsWarningsResponse {
    return CmsWarningsResponseFromJSONTyped(json, false);
}

export function CmsWarningsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CmsWarningsResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'error': json['error'] == null ? undefined : json['error'],
        'warning': json['warning'] == null ? undefined : json['warning'],
        'info': json['info'] == null ? undefined : json['info'],
        'success': json['success'] == null ? undefined : json['success'],
    };
}

export function CmsWarningsResponseToJSON(value?: CmsWarningsResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'error': value['error'],
        'warning': value['warning'],
        'info': value['info'],
        'success': value['success'],
    };
}

