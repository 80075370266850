/* tslint:disable */
/* eslint-disable */
/**
 * NN.CustomerPortal.ApiApp.General
 * GeneralAPIs
 *
 * The version of the OpenAPI document: 1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { LifeTransactionStatus } from './LifeTransactionStatus';
import {
    LifeTransactionStatusFromJSON,
    LifeTransactionStatusFromJSONTyped,
    LifeTransactionStatusToJSON,
} from './LifeTransactionStatus';
import type { LifeTransactionEnum } from './LifeTransactionEnum';
import {
    LifeTransactionEnumFromJSON,
    LifeTransactionEnumFromJSONTyped,
    LifeTransactionEnumToJSON,
} from './LifeTransactionEnum';

/**
 * 
 * @export
 * @interface GetLifeTransactionsResponse
 */
export interface GetLifeTransactionsResponse {
    /**
     * 
     * @type {number}
     * @memberof GetLifeTransactionsResponse
     */
    amount: number;
    /**
     * Payment date
     * @type {Date}
     * @memberof GetLifeTransactionsResponse
     */
    date: Date;
    /**
     * 
     * @type {string}
     * @memberof GetLifeTransactionsResponse
     */
    typeFull: string;
    /**
     * 
     * @type {LifeTransactionEnum}
     * @memberof GetLifeTransactionsResponse
     */
    type: LifeTransactionEnum;
    /**
     * 
     * @type {string}
     * @memberof GetLifeTransactionsResponse
     */
    title: string;
    /**
     * 
     * @type {LifeTransactionStatus}
     * @memberof GetLifeTransactionsResponse
     */
    status: LifeTransactionStatus;
    /**
     * 
     * @type {boolean}
     * @memberof GetLifeTransactionsResponse
     */
    isPaid: boolean;
    /**
     * 
     * @type {Date}
     * @memberof GetLifeTransactionsResponse
     */
    periodFrom?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof GetLifeTransactionsResponse
     */
    periodTo?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof GetLifeTransactionsResponse
     */
    maturityDate: Date;
}

/**
 * Check if a given object implements the GetLifeTransactionsResponse interface.
 */
export function instanceOfGetLifeTransactionsResponse(value: object): value is GetLifeTransactionsResponse {
    if (!('amount' in value) || value['amount'] === undefined) return false;
    if (!('date' in value) || value['date'] === undefined) return false;
    if (!('typeFull' in value) || value['typeFull'] === undefined) return false;
    if (!('type' in value) || value['type'] === undefined) return false;
    if (!('title' in value) || value['title'] === undefined) return false;
    if (!('status' in value) || value['status'] === undefined) return false;
    if (!('isPaid' in value) || value['isPaid'] === undefined) return false;
    if (!('maturityDate' in value) || value['maturityDate'] === undefined) return false;
    return true;
}

export function GetLifeTransactionsResponseFromJSON(json: any): GetLifeTransactionsResponse {
    return GetLifeTransactionsResponseFromJSONTyped(json, false);
}

export function GetLifeTransactionsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetLifeTransactionsResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'amount': json['amount'],
        'date': (new Date(json['date'])),
        'typeFull': json['typeFull'],
        'type': LifeTransactionEnumFromJSON(json['type']),
        'title': json['title'],
        'status': LifeTransactionStatusFromJSON(json['status']),
        'isPaid': json['isPaid'],
        'periodFrom': json['periodFrom'] == null ? undefined : (new Date(json['periodFrom'])),
        'periodTo': json['periodTo'] == null ? undefined : (new Date(json['periodTo'])),
        'maturityDate': (new Date(json['maturityDate'])),
    };
}

export function GetLifeTransactionsResponseToJSON(value?: GetLifeTransactionsResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'amount': value['amount'],
        'date': ((value['date']).toISOString()),
        'typeFull': value['typeFull'],
        'type': LifeTransactionEnumToJSON(value['type']),
        'title': value['title'],
        'status': LifeTransactionStatusToJSON(value['status']),
        'isPaid': value['isPaid'],
        'periodFrom': value['periodFrom'] == null ? undefined : ((value['periodFrom'] as any).toISOString()),
        'periodTo': value['periodTo'] == null ? undefined : ((value['periodTo'] as any).toISOString()),
        'maturityDate': ((value['maturityDate']).toISOString()),
    };
}

