/* tslint:disable */
/* eslint-disable */
/**
 * NN.CustomerPortal.ApiApp.General
 * GeneralAPIs
 *
 * The version of the OpenAPI document: 1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ChangeFundsRequest,
  ErrorResponse,
  GetDPSAllowedChangesResponse,
  GetDPSDetailResponse,
  GetFundsPerformanceResponse,
  GetPensionTransactionsResponse,
  GetZZJPreviewRequest,
  PensionTransactionEnum,
  SetAmlIdentificationRequest,
  SetContributionRequest,
  SetDPSTaxOptimumRequest,
  SetPensionBeneficiariesRequest,
} from '../models/index';
import {
    ChangeFundsRequestFromJSON,
    ChangeFundsRequestToJSON,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    GetDPSAllowedChangesResponseFromJSON,
    GetDPSAllowedChangesResponseToJSON,
    GetDPSDetailResponseFromJSON,
    GetDPSDetailResponseToJSON,
    GetFundsPerformanceResponseFromJSON,
    GetFundsPerformanceResponseToJSON,
    GetPensionTransactionsResponseFromJSON,
    GetPensionTransactionsResponseToJSON,
    GetZZJPreviewRequestFromJSON,
    GetZZJPreviewRequestToJSON,
    PensionTransactionEnumFromJSON,
    PensionTransactionEnumToJSON,
    SetAmlIdentificationRequestFromJSON,
    SetAmlIdentificationRequestToJSON,
    SetContributionRequestFromJSON,
    SetContributionRequestToJSON,
    SetDPSTaxOptimumRequestFromJSON,
    SetDPSTaxOptimumRequestToJSON,
    SetPensionBeneficiariesRequestFromJSON,
    SetPensionBeneficiariesRequestToJSON,
} from '../models/index';

export interface ChangeDPSFundsPostRequest {
    contractId: string;
    changeFundsRequest: ChangeFundsRequest;
}

export interface GetDPSAllowedChangesGetRequest {
    contractId: string;
}

export interface GetDPSDetailGetRequest {
    contractId: string;
}

export interface GetDPSFundsGetRequest {
    contractId: string;
}

export interface GetDPSTransactionsGetRequest {
    contractId: string;
    count?: number;
    from?: Date;
    to?: Date;
    typeFilter?: Array<PensionTransactionEnum>;
}

export interface PreviewChangeDPSFundsZZJPostRequest {
    contractId: string;
    getZZJPreviewRequest: GetZZJPreviewRequest;
}

export interface SetDPSAmlIdentificationPutRequest {
    contractId: string;
    setAmlIdentificationRequest: SetAmlIdentificationRequest;
}

export interface SetDPSBeneficiariesPutRequest {
    contractId: string;
    setPensionBeneficiariesRequest: SetPensionBeneficiariesRequest;
}

export interface SetDPSContributionPutRequest {
    contractId: string;
    setContributionRequest: SetContributionRequest;
}

export interface SetDPSTaxOptimumPutRequest {
    contractId: string;
    setDPSTaxOptimumRequest: SetDPSTaxOptimumRequest;
}

/**
 * 
 */
export class ContractsDpsApi extends runtime.BaseAPI {

    /**
     * Change for funds on DPS
     */
    async changeDPSFundsPostRaw(requestParameters: ChangeDPSFundsPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['contractId'] == null) {
            throw new runtime.RequiredError(
                'contractId',
                'Required parameter "contractId" was null or undefined when calling changeDPSFundsPost().'
            );
        }

        if (requestParameters['changeFundsRequest'] == null) {
            throw new runtime.RequiredError(
                'changeFundsRequest',
                'Required parameter "changeFundsRequest" was null or undefined when calling changeDPSFundsPost().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("ADB2CAuthFlow", ["https://nnczcustomerportaldev.onmicrosoft.com/5d23dca9-d19b-4c16-b403-d27b86222083/CustPortalApi.Default"]);
        }

        const response = await this.request({
            path: `/contracts/dps/{contractId}/change-funds`.replace(`{${"contractId"}}`, encodeURIComponent(String(requestParameters['contractId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ChangeFundsRequestToJSON(requestParameters['changeFundsRequest']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Change for funds on DPS
     */
    async changeDPSFundsPost(requestParameters: ChangeDPSFundsPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.changeDPSFundsPostRaw(requestParameters, initOverrides);
    }

    /**
     * Get what changes can be made to DPS contract
     */
    async getDPSAllowedChangesGetRaw(requestParameters: GetDPSAllowedChangesGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetDPSAllowedChangesResponse>> {
        if (requestParameters['contractId'] == null) {
            throw new runtime.RequiredError(
                'contractId',
                'Required parameter "contractId" was null or undefined when calling getDPSAllowedChangesGet().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("ADB2CAuthFlow", ["https://nnczcustomerportaldev.onmicrosoft.com/5d23dca9-d19b-4c16-b403-d27b86222083/CustPortalApi.Default"]);
        }

        const response = await this.request({
            path: `/contracts/dps/{contractId}/allowed-changes`.replace(`{${"contractId"}}`, encodeURIComponent(String(requestParameters['contractId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetDPSAllowedChangesResponseFromJSON(jsonValue));
    }

    /**
     * Get what changes can be made to DPS contract
     */
    async getDPSAllowedChangesGet(requestParameters: GetDPSAllowedChangesGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetDPSAllowedChangesResponse> {
        const response = await this.getDPSAllowedChangesGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get DPS contract detail for the current user
     */
    async getDPSDetailGetRaw(requestParameters: GetDPSDetailGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetDPSDetailResponse>> {
        if (requestParameters['contractId'] == null) {
            throw new runtime.RequiredError(
                'contractId',
                'Required parameter "contractId" was null or undefined when calling getDPSDetailGet().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("ADB2CAuthFlow", ["https://nnczcustomerportaldev.onmicrosoft.com/5d23dca9-d19b-4c16-b403-d27b86222083/CustPortalApi.Default"]);
        }

        const response = await this.request({
            path: `/contracts/dps/{contractId}`.replace(`{${"contractId"}}`, encodeURIComponent(String(requestParameters['contractId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetDPSDetailResponseFromJSON(jsonValue));
    }

    /**
     * Get DPS contract detail for the current user
     */
    async getDPSDetailGet(requestParameters: GetDPSDetailGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetDPSDetailResponse> {
        const response = await this.getDPSDetailGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get historical values of pension units for funds included in a contract
     */
    async getDPSFundsGetRaw(requestParameters: GetDPSFundsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GetFundsPerformanceResponse>>> {
        if (requestParameters['contractId'] == null) {
            throw new runtime.RequiredError(
                'contractId',
                'Required parameter "contractId" was null or undefined when calling getDPSFundsGet().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("ADB2CAuthFlow", ["https://nnczcustomerportaldev.onmicrosoft.com/5d23dca9-d19b-4c16-b403-d27b86222083/CustPortalApi.Default"]);
        }

        const response = await this.request({
            path: `/contracts/dps/{contractId}/funds`.replace(`{${"contractId"}}`, encodeURIComponent(String(requestParameters['contractId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetFundsPerformanceResponseFromJSON));
    }

    /**
     * Get historical values of pension units for funds included in a contract
     */
    async getDPSFundsGet(requestParameters: GetDPSFundsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GetFundsPerformanceResponse>> {
        const response = await this.getDPSFundsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get DPS transaction history.
     */
    async getDPSTransactionsGetRaw(requestParameters: GetDPSTransactionsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GetPensionTransactionsResponse>>> {
        if (requestParameters['contractId'] == null) {
            throw new runtime.RequiredError(
                'contractId',
                'Required parameter "contractId" was null or undefined when calling getDPSTransactionsGet().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['count'] != null) {
            queryParameters['Count'] = requestParameters['count'];
        }

        if (requestParameters['from'] != null) {
            queryParameters['From'] = (requestParameters['from'] as any).toISOString();
        }

        if (requestParameters['to'] != null) {
            queryParameters['To'] = (requestParameters['to'] as any).toISOString();
        }

        if (requestParameters['typeFilter'] != null) {
            queryParameters['TypeFilter'] = requestParameters['typeFilter']!.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("ADB2CAuthFlow", ["https://nnczcustomerportaldev.onmicrosoft.com/5d23dca9-d19b-4c16-b403-d27b86222083/CustPortalApi.Default"]);
        }

        const response = await this.request({
            path: `/contracts/dps/{contractId}/transactions`.replace(`{${"contractId"}}`, encodeURIComponent(String(requestParameters['contractId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetPensionTransactionsResponseFromJSON));
    }

    /**
     * Get DPS transaction history.
     */
    async getDPSTransactionsGet(requestParameters: GetDPSTransactionsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GetPensionTransactionsResponse>> {
        const response = await this.getDPSTransactionsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Download zzj preview
     */
    async previewChangeDPSFundsZZJPostRaw(requestParameters: PreviewChangeDPSFundsZZJPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters['contractId'] == null) {
            throw new runtime.RequiredError(
                'contractId',
                'Required parameter "contractId" was null or undefined when calling previewChangeDPSFundsZZJPost().'
            );
        }

        if (requestParameters['getZZJPreviewRequest'] == null) {
            throw new runtime.RequiredError(
                'getZZJPreviewRequest',
                'Required parameter "getZZJPreviewRequest" was null or undefined when calling previewChangeDPSFundsZZJPost().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("ADB2CAuthFlow", ["https://nnczcustomerportaldev.onmicrosoft.com/5d23dca9-d19b-4c16-b403-d27b86222083/CustPortalApi.Default"]);
        }

        const response = await this.request({
            path: `/contracts/dps/{contractId}/change-funds/zzj-preview`.replace(`{${"contractId"}}`, encodeURIComponent(String(requestParameters['contractId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GetZZJPreviewRequestToJSON(requestParameters['getZZJPreviewRequest']),
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     * Download zzj preview
     */
    async previewChangeDPSFundsZZJPost(requestParameters: PreviewChangeDPSFundsZZJPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob> {
        const response = await this.previewChangeDPSFundsZZJPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * AML identification on DPS
     */
    async setDPSAmlIdentificationPutRaw(requestParameters: SetDPSAmlIdentificationPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['contractId'] == null) {
            throw new runtime.RequiredError(
                'contractId',
                'Required parameter "contractId" was null or undefined when calling setDPSAmlIdentificationPut().'
            );
        }

        if (requestParameters['setAmlIdentificationRequest'] == null) {
            throw new runtime.RequiredError(
                'setAmlIdentificationRequest',
                'Required parameter "setAmlIdentificationRequest" was null or undefined when calling setDPSAmlIdentificationPut().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("ADB2CAuthFlow", ["https://nnczcustomerportaldev.onmicrosoft.com/5d23dca9-d19b-4c16-b403-d27b86222083/CustPortalApi.Default"]);
        }

        const response = await this.request({
            path: `/contracts/dps/{contractId}/aml-identification`.replace(`{${"contractId"}}`, encodeURIComponent(String(requestParameters['contractId']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: SetAmlIdentificationRequestToJSON(requestParameters['setAmlIdentificationRequest']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * AML identification on DPS
     */
    async setDPSAmlIdentificationPut(requestParameters: SetDPSAmlIdentificationPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.setDPSAmlIdentificationPutRaw(requestParameters, initOverrides);
    }

    /**
     * Set beneficiaries for DPS
     */
    async setDPSBeneficiariesPutRaw(requestParameters: SetDPSBeneficiariesPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['contractId'] == null) {
            throw new runtime.RequiredError(
                'contractId',
                'Required parameter "contractId" was null or undefined when calling setDPSBeneficiariesPut().'
            );
        }

        if (requestParameters['setPensionBeneficiariesRequest'] == null) {
            throw new runtime.RequiredError(
                'setPensionBeneficiariesRequest',
                'Required parameter "setPensionBeneficiariesRequest" was null or undefined when calling setDPSBeneficiariesPut().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("ADB2CAuthFlow", ["https://nnczcustomerportaldev.onmicrosoft.com/5d23dca9-d19b-4c16-b403-d27b86222083/CustPortalApi.Default"]);
        }

        const response = await this.request({
            path: `/contracts/dps/{contractId}/beneficiaries`.replace(`{${"contractId"}}`, encodeURIComponent(String(requestParameters['contractId']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: SetPensionBeneficiariesRequestToJSON(requestParameters['setPensionBeneficiariesRequest']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Set beneficiaries for DPS
     */
    async setDPSBeneficiariesPut(requestParameters: SetDPSBeneficiariesPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.setDPSBeneficiariesPutRaw(requestParameters, initOverrides);
    }

    /**
     * Set contribution amount and does employer contribute for DPS
     */
    async setDPSContributionPutRaw(requestParameters: SetDPSContributionPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['contractId'] == null) {
            throw new runtime.RequiredError(
                'contractId',
                'Required parameter "contractId" was null or undefined when calling setDPSContributionPut().'
            );
        }

        if (requestParameters['setContributionRequest'] == null) {
            throw new runtime.RequiredError(
                'setContributionRequest',
                'Required parameter "setContributionRequest" was null or undefined when calling setDPSContributionPut().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("ADB2CAuthFlow", ["https://nnczcustomerportaldev.onmicrosoft.com/5d23dca9-d19b-4c16-b403-d27b86222083/CustPortalApi.Default"]);
        }

        const response = await this.request({
            path: `/contracts/dps/{contractId}/contributions`.replace(`{${"contractId"}}`, encodeURIComponent(String(requestParameters['contractId']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: SetContributionRequestToJSON(requestParameters['setContributionRequest']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Set contribution amount and does employer contribute for DPS
     */
    async setDPSContributionPut(requestParameters: SetDPSContributionPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.setDPSContributionPutRaw(requestParameters, initOverrides);
    }

    /**
     * Change tax optimum on DPS
     */
    async setDPSTaxOptimumPutRaw(requestParameters: SetDPSTaxOptimumPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['contractId'] == null) {
            throw new runtime.RequiredError(
                'contractId',
                'Required parameter "contractId" was null or undefined when calling setDPSTaxOptimumPut().'
            );
        }

        if (requestParameters['setDPSTaxOptimumRequest'] == null) {
            throw new runtime.RequiredError(
                'setDPSTaxOptimumRequest',
                'Required parameter "setDPSTaxOptimumRequest" was null or undefined when calling setDPSTaxOptimumPut().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("ADB2CAuthFlow", ["https://nnczcustomerportaldev.onmicrosoft.com/5d23dca9-d19b-4c16-b403-d27b86222083/CustPortalApi.Default"]);
        }

        const response = await this.request({
            path: `/contracts/dps/{contractId}/change-tax-optimum`.replace(`{${"contractId"}}`, encodeURIComponent(String(requestParameters['contractId']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: SetDPSTaxOptimumRequestToJSON(requestParameters['setDPSTaxOptimumRequest']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Change tax optimum on DPS
     */
    async setDPSTaxOptimumPut(requestParameters: SetDPSTaxOptimumPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.setDPSTaxOptimumPutRaw(requestParameters, initOverrides);
    }

}
