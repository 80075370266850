import { Skeleton } from "@mui/material";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { ReactElement } from "react";

import { dateTimeFormat } from "../../functions/dates";
import { formatAmount } from "../../utils";
import { BodyTextM, BodyTextS } from "../Typography";

export interface SingleTransactionRowProps {
	icon?: ReactElement;
	title: string;
	date?: Date | string;
	amount: number;
}

function SingleTransactionRow({
	icon,
	title,
	date,
	amount,
}: SingleTransactionRowProps): JSX.Element {
	return (
		<Stack direction="row" sx={{ alignItems: "center" }} spacing={4}>
			{icon && <Box sx={{ width: 24 }}>{icon}</Box>}
			<Stack sx={{ flexGrow: 1 }}>
				<BodyTextM>{title}</BodyTextM>
				<BodyTextS mb={0} light>
					{date ? dateTimeFormat.format(new Date(date)) : "-"}
				</BodyTextS>
			</Stack>
			<BodyTextM>{formatAmount(amount)}&nbsp;Kč</BodyTextM>
		</Stack>
	);
}

export default SingleTransactionRow;
export function TransactionSkeleton(): JSX.Element {
	return <Skeleton height={45} variant="rectangular" />;
}
