/* tslint:disable */
/* eslint-disable */
/**
 * NN.CustomerPortal.ApiApp.General
 * GeneralAPIs
 *
 * The version of the OpenAPI document: 1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SetPPTaxOptimumRequest
 */
export interface SetPPTaxOptimumRequest {
    /**
     * 
     * @type {boolean}
     * @memberof SetPPTaxOptimumRequest
     */
    isActive: boolean;
}

/**
 * Check if a given object implements the SetPPTaxOptimumRequest interface.
 */
export function instanceOfSetPPTaxOptimumRequest(value: object): value is SetPPTaxOptimumRequest {
    if (!('isActive' in value) || value['isActive'] === undefined) return false;
    return true;
}

export function SetPPTaxOptimumRequestFromJSON(json: any): SetPPTaxOptimumRequest {
    return SetPPTaxOptimumRequestFromJSONTyped(json, false);
}

export function SetPPTaxOptimumRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): SetPPTaxOptimumRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'isActive': json['isActive'],
    };
}

export function SetPPTaxOptimumRequestToJSON(value?: SetPPTaxOptimumRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'isActive': value['isActive'],
    };
}

