/* tslint:disable */
/* eslint-disable */
/**
 * NN.CustomerPortal.ApiApp.General
 * GeneralAPIs
 *
 * The version of the OpenAPI document: 1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  DocumentDownloadResponse,
  FileDownloadInfo,
} from '../models/index';
import {
    DocumentDownloadResponseFromJSON,
    DocumentDownloadResponseToJSON,
    FileDownloadInfoFromJSON,
    FileDownloadInfoToJSON,
} from '../models/index';

export interface DownloadLifePolicyDocumentGetRequest {
    documentName: string;
}

export interface GetDPSDocumentsGetRequest {
    contractId: string;
}

export interface GetDocumentGetRequest {
    documentId: number;
    token?: string;
}

export interface GetLifeDocumentsGetRequest {
    contractId: string;
}

export interface GetPPDocumentsGetRequest {
    contractId: string;
}

export interface HeadDocumentHeadRequest {
    documentId: number;
    token?: string;
}

/**
 * 
 */
export class DocumentsApi extends runtime.BaseAPI {

    /**
     * Download life-policy document
     */
    async downloadLifePolicyDocumentGetRaw(requestParameters: DownloadLifePolicyDocumentGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters['documentName'] == null) {
            throw new runtime.RequiredError(
                'documentName',
                'Required parameter "documentName" was null or undefined when calling downloadLifePolicyDocumentGet().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/documents/download/life-policies/{documentName}`.replace(`{${"documentName"}}`, encodeURIComponent(String(requestParameters['documentName']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     * Download life-policy document
     */
    async downloadLifePolicyDocumentGet(requestParameters: DownloadLifePolicyDocumentGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob> {
        const response = await this.downloadLifePolicyDocumentGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get Current DPS Pre-contractual Information
     */
    async getCurrentDPSPrecontractualInfoGetRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FileDownloadInfo>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("ADB2CAuthFlow", ["https://nnczcustomerportaldev.onmicrosoft.com/5d23dca9-d19b-4c16-b403-d27b86222083/CustPortalApi.Default"]);
        }

        const response = await this.request({
            path: `/documents/current-dps-precontractual-info`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FileDownloadInfoFromJSON(jsonValue));
    }

    /**
     * Get Current DPS Pre-contractual Information
     */
    async getCurrentDPSPrecontractualInfoGet(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FileDownloadInfo> {
        const response = await this.getCurrentDPSPrecontractualInfoGetRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get list of documents for the given DPS contract.
     */
    async getDPSDocumentsGetRaw(requestParameters: GetDPSDocumentsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<DocumentDownloadResponse>>> {
        if (requestParameters['contractId'] == null) {
            throw new runtime.RequiredError(
                'contractId',
                'Required parameter "contractId" was null or undefined when calling getDPSDocumentsGet().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("ADB2CAuthFlow", ["https://nnczcustomerportaldev.onmicrosoft.com/5d23dca9-d19b-4c16-b403-d27b86222083/CustPortalApi.Default"]);
        }

        const response = await this.request({
            path: `/documents/dps/{contractId}`.replace(`{${"contractId"}}`, encodeURIComponent(String(requestParameters['contractId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DocumentDownloadResponseFromJSON));
    }

    /**
     * Get list of documents for the given DPS contract.
     */
    async getDPSDocumentsGet(requestParameters: GetDPSDocumentsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<DocumentDownloadResponse>> {
        const response = await this.getDPSDocumentsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Download specified document
     */
    async getDocumentGetRaw(requestParameters: GetDocumentGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters['documentId'] == null) {
            throw new runtime.RequiredError(
                'documentId',
                'Required parameter "documentId" was null or undefined when calling getDocumentGet().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['token'] != null) {
            queryParameters['token'] = requestParameters['token'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/documents/download/{documentId}`.replace(`{${"documentId"}}`, encodeURIComponent(String(requestParameters['documentId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     * Download specified document
     */
    async getDocumentGet(requestParameters: GetDocumentGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob> {
        const response = await this.getDocumentGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get list of documents for the given life contract.
     */
    async getLifeDocumentsGetRaw(requestParameters: GetLifeDocumentsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<DocumentDownloadResponse>>> {
        if (requestParameters['contractId'] == null) {
            throw new runtime.RequiredError(
                'contractId',
                'Required parameter "contractId" was null or undefined when calling getLifeDocumentsGet().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("ADB2CAuthFlow", ["https://nnczcustomerportaldev.onmicrosoft.com/5d23dca9-d19b-4c16-b403-d27b86222083/CustPortalApi.Default"]);
        }

        const response = await this.request({
            path: `/documents/life/{contractId}`.replace(`{${"contractId"}}`, encodeURIComponent(String(requestParameters['contractId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DocumentDownloadResponseFromJSON));
    }

    /**
     * Get list of documents for the given life contract.
     */
    async getLifeDocumentsGet(requestParameters: GetLifeDocumentsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<DocumentDownloadResponse>> {
        const response = await this.getLifeDocumentsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get list of documents for the given PP contract.
     */
    async getPPDocumentsGetRaw(requestParameters: GetPPDocumentsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<DocumentDownloadResponse>>> {
        if (requestParameters['contractId'] == null) {
            throw new runtime.RequiredError(
                'contractId',
                'Required parameter "contractId" was null or undefined when calling getPPDocumentsGet().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("ADB2CAuthFlow", ["https://nnczcustomerportaldev.onmicrosoft.com/5d23dca9-d19b-4c16-b403-d27b86222083/CustPortalApi.Default"]);
        }

        const response = await this.request({
            path: `/documents/pp/{contractId}`.replace(`{${"contractId"}}`, encodeURIComponent(String(requestParameters['contractId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DocumentDownloadResponseFromJSON));
    }

    /**
     * Get list of documents for the given PP contract.
     */
    async getPPDocumentsGet(requestParameters: GetPPDocumentsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<DocumentDownloadResponse>> {
        const response = await this.getPPDocumentsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get list of documents for current user
     */
    async getUserDocumentsGetRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<DocumentDownloadResponse>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("ADB2CAuthFlow", ["https://nnczcustomerportaldev.onmicrosoft.com/5d23dca9-d19b-4c16-b403-d27b86222083/CustPortalApi.Default"]);
        }

        const response = await this.request({
            path: `/documents`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DocumentDownloadResponseFromJSON));
    }

    /**
     * Get list of documents for current user
     */
    async getUserDocumentsGet(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<DocumentDownloadResponse>> {
        const response = await this.getUserDocumentsGetRaw(initOverrides);
        return await response.value();
    }

    /**
     * Head request to preflight a download of a specified document
     */
    async headDocumentHeadRaw(requestParameters: HeadDocumentHeadRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['documentId'] == null) {
            throw new runtime.RequiredError(
                'documentId',
                'Required parameter "documentId" was null or undefined when calling headDocumentHead().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['token'] != null) {
            queryParameters['token'] = requestParameters['token'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/documents/download/{documentId}`.replace(`{${"documentId"}}`, encodeURIComponent(String(requestParameters['documentId']))),
            method: 'HEAD',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Head request to preflight a download of a specified document
     */
    async headDocumentHead(requestParameters: HeadDocumentHeadRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.headDocumentHeadRaw(requestParameters, initOverrides);
    }

}
