/* tslint:disable */
/* eslint-disable */
/**
 * NN.CustomerPortal.ApiApp.General
 * GeneralAPIs
 *
 * The version of the OpenAPI document: 1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Fund } from './Fund';
import {
    FundFromJSON,
    FundFromJSONTyped,
    FundToJSON,
} from './Fund';
import type { PriceItem } from './PriceItem';
import {
    PriceItemFromJSON,
    PriceItemFromJSONTyped,
    PriceItemToJSON,
} from './PriceItem';
import type { Employer } from './Employer';
import {
    EmployerFromJSON,
    EmployerFromJSONTyped,
    EmployerToJSON,
} from './Employer';
import type { LifeContractStatus } from './LifeContractStatus';
import {
    LifeContractStatusFromJSON,
    LifeContractStatusFromJSONTyped,
    LifeContractStatusToJSON,
} from './LifeContractStatus';
import type { LifeUIStatus } from './LifeUIStatus';
import {
    LifeUIStatusFromJSON,
    LifeUIStatusFromJSONTyped,
    LifeUIStatusToJSON,
} from './LifeUIStatus';
import type { ContractPaymentInfo } from './ContractPaymentInfo';
import {
    ContractPaymentInfoFromJSON,
    ContractPaymentInfoFromJSONTyped,
    ContractPaymentInfoToJSON,
} from './ContractPaymentInfo';
import type { InsuredPerson } from './InsuredPerson';
import {
    InsuredPersonFromJSON,
    InsuredPersonFromJSONTyped,
    InsuredPersonToJSON,
} from './InsuredPerson';
import type { SharedClient } from './SharedClient';
import {
    SharedClientFromJSON,
    SharedClientFromJSONTyped,
    SharedClientToJSON,
} from './SharedClient';
import type { ContractParametersLife } from './ContractParametersLife';
import {
    ContractParametersLifeFromJSON,
    ContractParametersLifeFromJSONTyped,
    ContractParametersLifeToJSON,
} from './ContractParametersLife';

/**
 * 
 * @export
 * @interface GetLifeDetailResponse
 */
export interface GetLifeDetailResponse {
    /**
     * 
     * @type {string}
     * @memberof GetLifeDetailResponse
     */
    productName: string;
    /**
     * 
     * @type {number}
     * @memberof GetLifeDetailResponse
     */
    balanceTotal: number;
    /**
     * 
     * @type {number}
     * @memberof GetLifeDetailResponse
     */
    totalDebit: number;
    /**
     * 
     * @type {Array<Fund>}
     * @memberof GetLifeDetailResponse
     */
    funds: Array<Fund>;
    /**
     * 
     * @type {ContractPaymentInfo}
     * @memberof GetLifeDetailResponse
     */
    paymentInfo: ContractPaymentInfo;
    /**
     * 
     * @type {SharedClient}
     * @memberof GetLifeDetailResponse
     */
    clientInfo: SharedClient;
    /**
     * 
     * @type {Array<InsuredPerson>}
     * @memberof GetLifeDetailResponse
     */
    insuredPersons: Array<InsuredPerson>;
    /**
     * 
     * @type {ContractParametersLife}
     * @memberof GetLifeDetailResponse
     */
    contract: ContractParametersLife;
    /**
     * 
     * @type {Array<PriceItem>}
     * @memberof GetLifeDetailResponse
     */
    priceBreakdown: Array<PriceItem>;
    /**
     * 
     * @type {LifeContractStatus}
     * @memberof GetLifeDetailResponse
     */
    status: LifeContractStatus;
    /**
     * 
     * @type {Employer}
     * @memberof GetLifeDetailResponse
     */
    employer?: Employer;
    /**
     * 
     * @type {string}
     * @memberof GetLifeDetailResponse
     */
    investmentStrategy?: string | null;
    /**
     * Not null for SMART contract type
     * @type {number}
     * @memberof GetLifeDetailResponse
     */
    payoutVariant?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetLifeDetailResponse
     */
    supportsInflationProgram: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetLifeDetailResponse
     */
    hasInflationProgram: boolean;
    /**
     * 
     * @type {LifeUIStatus}
     * @memberof GetLifeDetailResponse
     */
    uiStatus: LifeUIStatus;
    /**
     * Approximate customer surrender value, the amount a person would
     * receive if they withdraw money from their own life insurance
     * @type {number}
     * @memberof GetLifeDetailResponse
     */
    surrValue: number;
}

/**
 * Check if a given object implements the GetLifeDetailResponse interface.
 */
export function instanceOfGetLifeDetailResponse(value: object): value is GetLifeDetailResponse {
    if (!('productName' in value) || value['productName'] === undefined) return false;
    if (!('balanceTotal' in value) || value['balanceTotal'] === undefined) return false;
    if (!('totalDebit' in value) || value['totalDebit'] === undefined) return false;
    if (!('funds' in value) || value['funds'] === undefined) return false;
    if (!('paymentInfo' in value) || value['paymentInfo'] === undefined) return false;
    if (!('clientInfo' in value) || value['clientInfo'] === undefined) return false;
    if (!('insuredPersons' in value) || value['insuredPersons'] === undefined) return false;
    if (!('contract' in value) || value['contract'] === undefined) return false;
    if (!('priceBreakdown' in value) || value['priceBreakdown'] === undefined) return false;
    if (!('status' in value) || value['status'] === undefined) return false;
    if (!('supportsInflationProgram' in value) || value['supportsInflationProgram'] === undefined) return false;
    if (!('hasInflationProgram' in value) || value['hasInflationProgram'] === undefined) return false;
    if (!('uiStatus' in value) || value['uiStatus'] === undefined) return false;
    if (!('surrValue' in value) || value['surrValue'] === undefined) return false;
    return true;
}

export function GetLifeDetailResponseFromJSON(json: any): GetLifeDetailResponse {
    return GetLifeDetailResponseFromJSONTyped(json, false);
}

export function GetLifeDetailResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetLifeDetailResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'productName': json['productName'],
        'balanceTotal': json['balanceTotal'],
        'totalDebit': json['totalDebit'],
        'funds': ((json['funds'] as Array<any>).map(FundFromJSON)),
        'paymentInfo': ContractPaymentInfoFromJSON(json['paymentInfo']),
        'clientInfo': SharedClientFromJSON(json['clientInfo']),
        'insuredPersons': ((json['insuredPersons'] as Array<any>).map(InsuredPersonFromJSON)),
        'contract': ContractParametersLifeFromJSON(json['contract']),
        'priceBreakdown': ((json['priceBreakdown'] as Array<any>).map(PriceItemFromJSON)),
        'status': LifeContractStatusFromJSON(json['status']),
        'employer': json['employer'] == null ? undefined : EmployerFromJSON(json['employer']),
        'investmentStrategy': json['investmentStrategy'] == null ? undefined : json['investmentStrategy'],
        'payoutVariant': json['payoutVariant'] == null ? undefined : json['payoutVariant'],
        'supportsInflationProgram': json['supportsInflationProgram'],
        'hasInflationProgram': json['hasInflationProgram'],
        'uiStatus': LifeUIStatusFromJSON(json['uiStatus']),
        'surrValue': json['surrValue'],
    };
}

export function GetLifeDetailResponseToJSON(value?: GetLifeDetailResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'productName': value['productName'],
        'balanceTotal': value['balanceTotal'],
        'totalDebit': value['totalDebit'],
        'funds': ((value['funds'] as Array<any>).map(FundToJSON)),
        'paymentInfo': ContractPaymentInfoToJSON(value['paymentInfo']),
        'clientInfo': SharedClientToJSON(value['clientInfo']),
        'insuredPersons': ((value['insuredPersons'] as Array<any>).map(InsuredPersonToJSON)),
        'contract': ContractParametersLifeToJSON(value['contract']),
        'priceBreakdown': ((value['priceBreakdown'] as Array<any>).map(PriceItemToJSON)),
        'status': LifeContractStatusToJSON(value['status']),
        'employer': EmployerToJSON(value['employer']),
        'investmentStrategy': value['investmentStrategy'],
        'payoutVariant': value['payoutVariant'],
        'supportsInflationProgram': value['supportsInflationProgram'],
        'hasInflationProgram': value['hasInflationProgram'],
        'uiStatus': LifeUIStatusToJSON(value['uiStatus']),
        'surrValue': value['surrValue'],
    };
}

