import { Skeleton } from "@mui/material";
import useIsDesktop from "desktop/useIsDesktop";
import { PageProps } from "gatsby";

import { ProductType } from "../../../models";
import {
	ContractIDProvider,
	extractContractIDFromPageProps,
} from "../../context/ContractIDContext";
import { ContractTypeProvider } from "../../context/ContractTypeContext";
import { useContractById } from "../../queryHooks";
import { assertIsLifeContractType } from "../../types/contracts";
import { BackNavigationBar, LoggedInBar } from "../AppBar";
import { FufiSubtitle, LifeSubtitle } from "./Heading/Subtitle";
import { FufiTitle, LifeTitle } from "./Heading/Title";

interface ICommonLifeAppBar {
	contractId: string;
}

const LifeAppBar = ({ contractId }: ICommonLifeAppBar) => {
	return (
		<ContractIDProvider contractId={contractId}>
			<ContractTypeProvider contractType={ProductType.Cl}>
				<BackNavigationBar title={<LifeTitle />} subtitle={<LifeSubtitle />} />
			</ContractTypeProvider>
		</ContractIDProvider>
	);
};

const FufiAppBar = ({ contractId }: ICommonLifeAppBar) => {
	return (
		<ContractIDProvider contractId={contractId}>
			<ContractTypeProvider contractType={ProductType.Clf}>
				<BackNavigationBar title={<FufiTitle />} subtitle={<FufiSubtitle />} />
			</ContractTypeProvider>
		</ContractIDProvider>
	);
};

const LoadingBar = () => {
	return <BackNavigationBar title={<Skeleton />} subtitle={<Skeleton />} />;
};

const AppBarByContractType = {
	[ProductType.Clf]: FufiAppBar,
	[ProductType.Cl]: LifeAppBar,
};

export const CommonLifeAppBar = (pageProps: PageProps) => {
	const contractId = extractContractIDFromPageProps(pageProps);
	const isDesktop = useIsDesktop();

	const { data, isSuccess } = useContractById({
		id: contractId,
		options: { enabled: !isDesktop },
	});

	if (isDesktop) return <LoggedInBar />;

	if (!isSuccess) return <LoadingBar />;

	assertIsLifeContractType(data.type);

	const AppBar = AppBarByContractType[data.type];

	return <AppBar contractId={contractId} />;
};
