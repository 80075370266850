/* tslint:disable */
/* eslint-disable */
/**
 * NN.CustomerPortal.ApiApp.General
 * GeneralAPIs
 *
 * The version of the OpenAPI document: 1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const InsuranceTypeEnum = {
    Life: 'Life',
    Child: 'Child'
} as const;
export type InsuranceTypeEnum = typeof InsuranceTypeEnum[keyof typeof InsuranceTypeEnum];


export function instanceOfInsuranceTypeEnum(value: any): boolean {
    for (const key in InsuranceTypeEnum) {
        if (Object.prototype.hasOwnProperty.call(InsuranceTypeEnum, key)) {
            if ((InsuranceTypeEnum as Record<string, InsuranceTypeEnum>)[key] === value) {
                return true;
            }
        }
    }
    return false;
}

export function InsuranceTypeEnumFromJSON(json: any): InsuranceTypeEnum {
    return InsuranceTypeEnumFromJSONTyped(json, false);
}

export function InsuranceTypeEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): InsuranceTypeEnum {
    return json as InsuranceTypeEnum;
}

export function InsuranceTypeEnumToJSON(value?: InsuranceTypeEnum | null): any {
    return value as any;
}

