/* tslint:disable */
/* eslint-disable */
/**
 * NN.CustomerPortal.ApiApp.General
 * GeneralAPIs
 *
 * The version of the OpenAPI document: 1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Assignee
 */
export interface Assignee {
    /**
     * 
     * @type {number}
     * @memberof Assignee
     */
    id: number;
}

/**
 * Check if a given object implements the Assignee interface.
 */
export function instanceOfAssignee(value: object): value is Assignee {
    if (!('id' in value) || value['id'] === undefined) return false;
    return true;
}

export function AssigneeFromJSON(json: any): Assignee {
    return AssigneeFromJSONTyped(json, false);
}

export function AssigneeFromJSONTyped(json: any, ignoreDiscriminator: boolean): Assignee {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
    };
}

export function AssigneeToJSON(value?: Assignee | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
    };
}

