/* tslint:disable */
/* eslint-disable */
/**
 * NN.CustomerPortal.ApiApp.General
 * GeneralAPIs
 *
 * The version of the OpenAPI document: 1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const AMLVerification = {
    No: 'No',
    Yes: 'Yes',
    Except: 'Except',
    NotAvailable: 'NotAvailable'
} as const;
export type AMLVerification = typeof AMLVerification[keyof typeof AMLVerification];


export function instanceOfAMLVerification(value: any): boolean {
    for (const key in AMLVerification) {
        if (Object.prototype.hasOwnProperty.call(AMLVerification, key)) {
            if ((AMLVerification as Record<string, AMLVerification>)[key] === value) {
                return true;
            }
        }
    }
    return false;
}

export function AMLVerificationFromJSON(json: any): AMLVerification {
    return AMLVerificationFromJSONTyped(json, false);
}

export function AMLVerificationFromJSONTyped(json: any, ignoreDiscriminator: boolean): AMLVerification {
    return json as AMLVerification;
}

export function AMLVerificationToJSON(value?: AMLVerification | null): any {
    return value as any;
}

