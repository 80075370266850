/* tslint:disable */
/* eslint-disable */
/**
 * NN.CustomerPortal.ApiApp.General
 * GeneralAPIs
 *
 * The version of the OpenAPI document: 1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const StarPensionClientTypeEnum = {
    Z: 'Z',
    P: 'P',
    S: 'S',
    N: 'N'
} as const;
export type StarPensionClientTypeEnum = typeof StarPensionClientTypeEnum[keyof typeof StarPensionClientTypeEnum];


export function instanceOfStarPensionClientTypeEnum(value: any): boolean {
    for (const key in StarPensionClientTypeEnum) {
        if (Object.prototype.hasOwnProperty.call(StarPensionClientTypeEnum, key)) {
            if ((StarPensionClientTypeEnum as Record<string, StarPensionClientTypeEnum>)[key] === value) {
                return true;
            }
        }
    }
    return false;
}

export function StarPensionClientTypeEnumFromJSON(json: any): StarPensionClientTypeEnum {
    return StarPensionClientTypeEnumFromJSONTyped(json, false);
}

export function StarPensionClientTypeEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): StarPensionClientTypeEnum {
    return json as StarPensionClientTypeEnum;
}

export function StarPensionClientTypeEnumToJSON(value?: StarPensionClientTypeEnum | null): any {
    return value as any;
}

