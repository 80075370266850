/* tslint:disable */
/* eslint-disable */
/**
 * NN.CustomerPortal.ApiApp.General
 * GeneralAPIs
 *
 * The version of the OpenAPI document: 1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const InsuredPersonRole = {
    PolicyHolder: 'PolicyHolder',
    PolicyHolderAndFirst: 'PolicyHolderAndFirst',
    PolicyHolderAndSecond: 'PolicyHolderAndSecond',
    First: 'First',
    Second: 'Second',
    Children: 'Children'
} as const;
export type InsuredPersonRole = typeof InsuredPersonRole[keyof typeof InsuredPersonRole];


export function instanceOfInsuredPersonRole(value: any): boolean {
    for (const key in InsuredPersonRole) {
        if (Object.prototype.hasOwnProperty.call(InsuredPersonRole, key)) {
            if ((InsuredPersonRole as Record<string, InsuredPersonRole>)[key] === value) {
                return true;
            }
        }
    }
    return false;
}

export function InsuredPersonRoleFromJSON(json: any): InsuredPersonRole {
    return InsuredPersonRoleFromJSONTyped(json, false);
}

export function InsuredPersonRoleFromJSONTyped(json: any, ignoreDiscriminator: boolean): InsuredPersonRole {
    return json as InsuredPersonRole;
}

export function InsuredPersonRoleToJSON(value?: InsuredPersonRole | null): any {
    return value as any;
}

