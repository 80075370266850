/* tslint:disable */
/* eslint-disable */
/**
 * NN.CustomerPortal.ApiApp.General
 * GeneralAPIs
 *
 * The version of the OpenAPI document: 1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const TypeEnum = {
    Agent: 'Agent',
    Broker: 'Broker',
    Client: 'Client'
} as const;
export type TypeEnum = typeof TypeEnum[keyof typeof TypeEnum];


export function instanceOfTypeEnum(value: any): boolean {
    for (const key in TypeEnum) {
        if (Object.prototype.hasOwnProperty.call(TypeEnum, key)) {
            if ((TypeEnum as Record<string, TypeEnum>)[key] === value) {
                return true;
            }
        }
    }
    return false;
}

export function TypeEnumFromJSON(json: any): TypeEnum {
    return TypeEnumFromJSONTyped(json, false);
}

export function TypeEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): TypeEnum {
    return json as TypeEnum;
}

export function TypeEnumToJSON(value?: TypeEnum | null): any {
    return value as any;
}

