/* tslint:disable */
/* eslint-disable */
/**
 * NN.CustomerPortal.ApiApp.General
 * GeneralAPIs
 *
 * The version of the OpenAPI document: 1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const DeliveryType = {
    Post: 'Post',
    Electronically: 'Electronically'
} as const;
export type DeliveryType = typeof DeliveryType[keyof typeof DeliveryType];


export function instanceOfDeliveryType(value: any): boolean {
    for (const key in DeliveryType) {
        if (Object.prototype.hasOwnProperty.call(DeliveryType, key)) {
            if ((DeliveryType as Record<string, DeliveryType>)[key] === value) {
                return true;
            }
        }
    }
    return false;
}

export function DeliveryTypeFromJSON(json: any): DeliveryType {
    return DeliveryTypeFromJSONTyped(json, false);
}

export function DeliveryTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): DeliveryType {
    return json as DeliveryType;
}

export function DeliveryTypeToJSON(value?: DeliveryType | null): any {
    return value as any;
}

