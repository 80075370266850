/* tslint:disable */
/* eslint-disable */
/**
 * NN.CustomerPortal.ApiApp.General
 * GeneralAPIs
 *
 * The version of the OpenAPI document: 1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SetAmlIdentificationRequest
 */
export interface SetAmlIdentificationRequest {
    /**
     * 
     * @type {string}
     * @memberof SetAmlIdentificationRequest
     */
    token?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SetAmlIdentificationRequest
     */
    sourceOfIncome?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SetAmlIdentificationRequest
     */
    companySourceOfIncome?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SetAmlIdentificationRequest
     */
    sourceOfProperty?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SetAmlIdentificationRequest
     */
    income?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SetAmlIdentificationRequest
     */
    domicil?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SetAmlIdentificationRequest
     */
    taxNumber?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof SetAmlIdentificationRequest
     */
    pep?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof SetAmlIdentificationRequest
     */
    greenCard?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof SetAmlIdentificationRequest
     */
    usTaxResident?: boolean | null;
}

/**
 * Check if a given object implements the SetAmlIdentificationRequest interface.
 */
export function instanceOfSetAmlIdentificationRequest(value: object): value is SetAmlIdentificationRequest {
    return true;
}

export function SetAmlIdentificationRequestFromJSON(json: any): SetAmlIdentificationRequest {
    return SetAmlIdentificationRequestFromJSONTyped(json, false);
}

export function SetAmlIdentificationRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): SetAmlIdentificationRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'token': json['token'] == null ? undefined : json['token'],
        'sourceOfIncome': json['sourceOfIncome'] == null ? undefined : json['sourceOfIncome'],
        'companySourceOfIncome': json['companySourceOfIncome'] == null ? undefined : json['companySourceOfIncome'],
        'sourceOfProperty': json['sourceOfProperty'] == null ? undefined : json['sourceOfProperty'],
        'income': json['income'] == null ? undefined : json['income'],
        'domicil': json['domicil'] == null ? undefined : json['domicil'],
        'taxNumber': json['TaxNumber'] == null ? undefined : json['TaxNumber'],
        'pep': json['pep'] == null ? undefined : json['pep'],
        'greenCard': json['greenCard'] == null ? undefined : json['greenCard'],
        'usTaxResident': json['usTaxResident'] == null ? undefined : json['usTaxResident'],
    };
}

export function SetAmlIdentificationRequestToJSON(value?: SetAmlIdentificationRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'token': value['token'],
        'sourceOfIncome': value['sourceOfIncome'],
        'companySourceOfIncome': value['companySourceOfIncome'],
        'sourceOfProperty': value['sourceOfProperty'],
        'income': value['income'],
        'domicil': value['domicil'],
        'TaxNumber': value['taxNumber'],
        'pep': value['pep'],
        'greenCard': value['greenCard'],
        'usTaxResident': value['usTaxResident'],
    };
}

