/* tslint:disable */
/* eslint-disable */
/**
 * NN.CustomerPortal.ApiApp.General
 * GeneralAPIs
 *
 * The version of the OpenAPI document: 1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { AddressType } from './AddressType';
import {
    AddressTypeFromJSON,
    AddressTypeFromJSONTyped,
    AddressTypeToJSON,
} from './AddressType';

/**
 * 
 * @export
 * @interface Address
 */
export interface Address {
    /**
     * 
     * @type {AddressType}
     * @memberof Address
     */
    type: AddressType;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    street?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    houseNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    town?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    zip: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    country?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    note?: string | null;
}

/**
 * Check if a given object implements the Address interface.
 */
export function instanceOfAddress(value: object): value is Address {
    if (!('type' in value) || value['type'] === undefined) return false;
    if (!('zip' in value) || value['zip'] === undefined) return false;
    return true;
}

export function AddressFromJSON(json: any): Address {
    return AddressFromJSONTyped(json, false);
}

export function AddressFromJSONTyped(json: any, ignoreDiscriminator: boolean): Address {
    if (json == null) {
        return json;
    }
    return {
        
        'type': AddressTypeFromJSON(json['type']),
        'street': json['street'] == null ? undefined : json['street'],
        'houseNumber': json['houseNumber'] == null ? undefined : json['houseNumber'],
        'town': json['town'] == null ? undefined : json['town'],
        'zip': json['zip'],
        'country': json['country'] == null ? undefined : json['country'],
        'note': json['note'] == null ? undefined : json['note'],
    };
}

export function AddressToJSON(value?: Address | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'type': AddressTypeToJSON(value['type']),
        'street': value['street'],
        'houseNumber': value['houseNumber'],
        'town': value['town'],
        'zip': value['zip'],
        'country': value['country'],
        'note': value['note'],
    };
}

