/* tslint:disable */
/* eslint-disable */
/**
 * NN.CustomerPortal.ApiApp.General
 * GeneralAPIs
 *
 * The version of the OpenAPI document: 1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { PensionPendingChange } from './PensionPendingChange';
import {
    PensionPendingChangeFromJSON,
    PensionPendingChangeFromJSONTyped,
    PensionPendingChangeToJSON,
} from './PensionPendingChange';
import type { Contributions } from './Contributions';
import {
    ContributionsFromJSON,
    ContributionsFromJSONTyped,
    ContributionsToJSON,
} from './Contributions';
import type { Address } from './Address';
import {
    AddressFromJSON,
    AddressFromJSONTyped,
    AddressToJSON,
} from './Address';
import type { ContractParametersPP } from './ContractParametersPP';
import {
    ContractParametersPPFromJSON,
    ContractParametersPPFromJSONTyped,
    ContractParametersPPToJSON,
} from './ContractParametersPP';
import type { PPUIStatus } from './PPUIStatus';
import {
    PPUIStatusFromJSON,
    PPUIStatusFromJSONTyped,
    PPUIStatusToJSON,
} from './PPUIStatus';
import type { Employer } from './Employer';
import {
    EmployerFromJSON,
    EmployerFromJSONTyped,
    EmployerToJSON,
} from './Employer';
import type { PensionBeneficiary } from './PensionBeneficiary';
import {
    PensionBeneficiaryFromJSON,
    PensionBeneficiaryFromJSONTyped,
    PensionBeneficiaryToJSON,
} from './PensionBeneficiary';
import type { Evaluations } from './Evaluations';
import {
    EvaluationsFromJSON,
    EvaluationsFromJSONTyped,
    EvaluationsToJSON,
} from './Evaluations';
import type { ContactInfo } from './ContactInfo';
import {
    ContactInfoFromJSON,
    ContactInfoFromJSONTyped,
    ContactInfoToJSON,
} from './ContactInfo';
import type { PensionPaymentInfo } from './PensionPaymentInfo';
import {
    PensionPaymentInfoFromJSON,
    PensionPaymentInfoFromJSONTyped,
    PensionPaymentInfoToJSON,
} from './PensionPaymentInfo';
import type { SharedClient } from './SharedClient';
import {
    SharedClientFromJSON,
    SharedClientFromJSONTyped,
    SharedClientToJSON,
} from './SharedClient';
import type { StarPensionClientTypeEnum } from './StarPensionClientTypeEnum';
import {
    StarPensionClientTypeEnumFromJSON,
    StarPensionClientTypeEnumFromJSONTyped,
    StarPensionClientTypeEnumToJSON,
} from './StarPensionClientTypeEnum';

/**
 * 
 * @export
 * @interface GetPPDetailResponse
 */
export interface GetPPDetailResponse {
    /**
     * 
     * @type {number}
     * @memberof GetPPDetailResponse
     */
    savedAmount: number;
    /**
     * 
     * @type {Contributions}
     * @memberof GetPPDetailResponse
     */
    contributionsBreakdown: Contributions;
    /**
     * 
     * @type {Evaluations}
     * @memberof GetPPDetailResponse
     */
    evaluationsBreakdown: Evaluations;
    /**
     * 
     * @type {PensionPaymentInfo}
     * @memberof GetPPDetailResponse
     */
    clientPaymentInfo: PensionPaymentInfo;
    /**
     * 
     * @type {PensionPaymentInfo}
     * @memberof GetPPDetailResponse
     */
    employerPaymentInfo: PensionPaymentInfo;
    /**
     * 
     * @type {SharedClient}
     * @memberof GetPPDetailResponse
     */
    clientInfo: SharedClient;
    /**
     * 
     * @type {ContactInfo}
     * @memberof GetPPDetailResponse
     */
    contact: ContactInfo;
    /**
     * 
     * @type {Address}
     * @memberof GetPPDetailResponse
     */
    permanentAddress: Address;
    /**
     * 
     * @type {Address}
     * @memberof GetPPDetailResponse
     */
    contactAddress?: Address;
    /**
     * 
     * @type {boolean}
     * @memberof GetPPDetailResponse
     */
    isContactAddressChangeInProgress: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetPPDetailResponse
     */
    isGlobalContactAddressChangeEnabled: boolean;
    /**
     * 
     * @type {ContractParametersPP}
     * @memberof GetPPDetailResponse
     */
    contract: ContractParametersPP;
    /**
     * 
     * @type {Array<PensionBeneficiary>}
     * @memberof GetPPDetailResponse
     */
    beneficiaries: Array<PensionBeneficiary>;
    /**
     * 
     * @type {Employer}
     * @memberof GetPPDetailResponse
     */
    employer?: Employer;
    /**
     * 
     * @type {PPUIStatus}
     * @memberof GetPPDetailResponse
     */
    uiStatus: PPUIStatus;
    /**
     * 
     * @type {Array<PensionPendingChange>}
     * @memberof GetPPDetailResponse
     */
    pendingChanges: Array<PensionPendingChange>;
    /**
     * 
     * @type {boolean}
     * @memberof GetPPDetailResponse
     */
    isClientIdentified: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetPPDetailResponse
     */
    isAmlFulfilled: boolean;
    /**
     * 
     * @type {StarPensionClientTypeEnum}
     * @memberof GetPPDetailResponse
     */
    clientType: StarPensionClientTypeEnum;
}

/**
 * Check if a given object implements the GetPPDetailResponse interface.
 */
export function instanceOfGetPPDetailResponse(value: object): value is GetPPDetailResponse {
    if (!('savedAmount' in value) || value['savedAmount'] === undefined) return false;
    if (!('contributionsBreakdown' in value) || value['contributionsBreakdown'] === undefined) return false;
    if (!('evaluationsBreakdown' in value) || value['evaluationsBreakdown'] === undefined) return false;
    if (!('clientPaymentInfo' in value) || value['clientPaymentInfo'] === undefined) return false;
    if (!('employerPaymentInfo' in value) || value['employerPaymentInfo'] === undefined) return false;
    if (!('clientInfo' in value) || value['clientInfo'] === undefined) return false;
    if (!('contact' in value) || value['contact'] === undefined) return false;
    if (!('permanentAddress' in value) || value['permanentAddress'] === undefined) return false;
    if (!('isContactAddressChangeInProgress' in value) || value['isContactAddressChangeInProgress'] === undefined) return false;
    if (!('isGlobalContactAddressChangeEnabled' in value) || value['isGlobalContactAddressChangeEnabled'] === undefined) return false;
    if (!('contract' in value) || value['contract'] === undefined) return false;
    if (!('beneficiaries' in value) || value['beneficiaries'] === undefined) return false;
    if (!('uiStatus' in value) || value['uiStatus'] === undefined) return false;
    if (!('pendingChanges' in value) || value['pendingChanges'] === undefined) return false;
    if (!('isClientIdentified' in value) || value['isClientIdentified'] === undefined) return false;
    if (!('isAmlFulfilled' in value) || value['isAmlFulfilled'] === undefined) return false;
    if (!('clientType' in value) || value['clientType'] === undefined) return false;
    return true;
}

export function GetPPDetailResponseFromJSON(json: any): GetPPDetailResponse {
    return GetPPDetailResponseFromJSONTyped(json, false);
}

export function GetPPDetailResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetPPDetailResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'savedAmount': json['savedAmount'],
        'contributionsBreakdown': ContributionsFromJSON(json['contributionsBreakdown']),
        'evaluationsBreakdown': EvaluationsFromJSON(json['evaluationsBreakdown']),
        'clientPaymentInfo': PensionPaymentInfoFromJSON(json['clientPaymentInfo']),
        'employerPaymentInfo': PensionPaymentInfoFromJSON(json['employerPaymentInfo']),
        'clientInfo': SharedClientFromJSON(json['clientInfo']),
        'contact': ContactInfoFromJSON(json['contact']),
        'permanentAddress': AddressFromJSON(json['permanentAddress']),
        'contactAddress': json['contactAddress'] == null ? undefined : AddressFromJSON(json['contactAddress']),
        'isContactAddressChangeInProgress': json['isContactAddressChangeInProgress'],
        'isGlobalContactAddressChangeEnabled': json['isGlobalContactAddressChangeEnabled'],
        'contract': ContractParametersPPFromJSON(json['contract']),
        'beneficiaries': ((json['beneficiaries'] as Array<any>).map(PensionBeneficiaryFromJSON)),
        'employer': json['employer'] == null ? undefined : EmployerFromJSON(json['employer']),
        'uiStatus': PPUIStatusFromJSON(json['uiStatus']),
        'pendingChanges': ((json['pendingChanges'] as Array<any>).map(PensionPendingChangeFromJSON)),
        'isClientIdentified': json['isClientIdentified'],
        'isAmlFulfilled': json['isAmlFulfilled'],
        'clientType': StarPensionClientTypeEnumFromJSON(json['clientType']),
    };
}

export function GetPPDetailResponseToJSON(value?: GetPPDetailResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'savedAmount': value['savedAmount'],
        'contributionsBreakdown': ContributionsToJSON(value['contributionsBreakdown']),
        'evaluationsBreakdown': EvaluationsToJSON(value['evaluationsBreakdown']),
        'clientPaymentInfo': PensionPaymentInfoToJSON(value['clientPaymentInfo']),
        'employerPaymentInfo': PensionPaymentInfoToJSON(value['employerPaymentInfo']),
        'clientInfo': SharedClientToJSON(value['clientInfo']),
        'contact': ContactInfoToJSON(value['contact']),
        'permanentAddress': AddressToJSON(value['permanentAddress']),
        'contactAddress': AddressToJSON(value['contactAddress']),
        'isContactAddressChangeInProgress': value['isContactAddressChangeInProgress'],
        'isGlobalContactAddressChangeEnabled': value['isGlobalContactAddressChangeEnabled'],
        'contract': ContractParametersPPToJSON(value['contract']),
        'beneficiaries': ((value['beneficiaries'] as Array<any>).map(PensionBeneficiaryToJSON)),
        'employer': EmployerToJSON(value['employer']),
        'uiStatus': PPUIStatusToJSON(value['uiStatus']),
        'pendingChanges': ((value['pendingChanges'] as Array<any>).map(PensionPendingChangeToJSON)),
        'isClientIdentified': value['isClientIdentified'],
        'isAmlFulfilled': value['isAmlFulfilled'],
        'clientType': StarPensionClientTypeEnumToJSON(value['clientType']),
    };
}

