/* tslint:disable */
/* eslint-disable */
/**
 * NN.CustomerPortal.ApiApp.General
 * GeneralAPIs
 *
 * The version of the OpenAPI document: 1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SetDPSTaxOptimumRequest
 */
export interface SetDPSTaxOptimumRequest {
    /**
     * 
     * @type {boolean}
     * @memberof SetDPSTaxOptimumRequest
     */
    isActive: boolean;
}

/**
 * Check if a given object implements the SetDPSTaxOptimumRequest interface.
 */
export function instanceOfSetDPSTaxOptimumRequest(value: object): value is SetDPSTaxOptimumRequest {
    if (!('isActive' in value) || value['isActive'] === undefined) return false;
    return true;
}

export function SetDPSTaxOptimumRequestFromJSON(json: any): SetDPSTaxOptimumRequest {
    return SetDPSTaxOptimumRequestFromJSONTyped(json, false);
}

export function SetDPSTaxOptimumRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): SetDPSTaxOptimumRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'isActive': json['isActive'],
    };
}

export function SetDPSTaxOptimumRequestToJSON(value?: SetDPSTaxOptimumRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'isActive': value['isActive'],
    };
}

