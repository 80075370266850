/* tslint:disable */
/* eslint-disable */
/**
 * NN.CustomerPortal.ApiApp.General
 * GeneralAPIs
 *
 * The version of the OpenAPI document: 1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ProfileCode } from './ProfileCode';
import {
    ProfileCodeFromJSON,
    ProfileCodeFromJSONTyped,
    ProfileCodeToJSON,
} from './ProfileCode';

/**
 * 
 * @export
 * @interface Profile
 */
export interface Profile {
    /**
     * 
     * @type {ProfileCode}
     * @memberof Profile
     */
    code: ProfileCode;
    /**
     * 
     * @type {string}
     * @memberof Profile
     */
    name: string;
}

/**
 * Check if a given object implements the Profile interface.
 */
export function instanceOfProfile(value: object): value is Profile {
    if (!('code' in value) || value['code'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    return true;
}

export function ProfileFromJSON(json: any): Profile {
    return ProfileFromJSONTyped(json, false);
}

export function ProfileFromJSONTyped(json: any, ignoreDiscriminator: boolean): Profile {
    if (json == null) {
        return json;
    }
    return {
        
        'code': ProfileCodeFromJSON(json['code']),
        'name': json['name'],
    };
}

export function ProfileToJSON(value?: Profile | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'code': ProfileCodeToJSON(value['code']),
        'name': value['name'],
    };
}

