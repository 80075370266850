import { useMemo } from "react";

import countries from "../components/Form/countries.json";

export type CountryInfo = {
	country_code: string;
	country_code_a3: string;
	country_code_n3: string;
	country_name: string;
	country_name_long: string;
	country_name_cz: string;
	country_name_cz_long: string;
	country_name_sk: string;
	country_name_sk_long: string;
	batch_id: number;
	risk_country?: boolean;
};

export const useCountry = (countryCode?: string | null) => {
	const country = useMemo(() => {
		if (!countryCode) {
			return null;
		}

		const selectedCountryObject = countries.find(
			(country) => country.country_code === countryCode
		);
		return selectedCountryObject;
	}, [countryCode]);

	return !country ? null : (country as CountryInfo);
};
