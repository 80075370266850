/* tslint:disable */
/* eslint-disable */
/**
 * NN.CustomerPortal.ApiApp.General
 * GeneralAPIs
 *
 * The version of the OpenAPI document: 1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ErrorResponse,
  GetPPDetailResponse,
  GetPensionTransactionsResponse,
  PensionTransactionEnum,
  SetAmlIdentificationRequest,
  SetContributionRequest,
  SetPPTaxOptimumRequest,
  SetPensionBeneficiariesRequest,
} from '../models/index';
import {
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    GetPPDetailResponseFromJSON,
    GetPPDetailResponseToJSON,
    GetPensionTransactionsResponseFromJSON,
    GetPensionTransactionsResponseToJSON,
    PensionTransactionEnumFromJSON,
    PensionTransactionEnumToJSON,
    SetAmlIdentificationRequestFromJSON,
    SetAmlIdentificationRequestToJSON,
    SetContributionRequestFromJSON,
    SetContributionRequestToJSON,
    SetPPTaxOptimumRequestFromJSON,
    SetPPTaxOptimumRequestToJSON,
    SetPensionBeneficiariesRequestFromJSON,
    SetPensionBeneficiariesRequestToJSON,
} from '../models/index';

export interface GetPPDetailGetRequest {
    contractId: string;
}

export interface GetPPTransactionsGetRequest {
    contractId: string;
    count?: number;
    from?: Date;
    to?: Date;
    typeFilter?: Array<PensionTransactionEnum>;
}

export interface SetPPAmlIdentificationPutRequest {
    contractId: string;
    setAmlIdentificationRequest: SetAmlIdentificationRequest;
}

export interface SetPPBeneficiariesPutRequest {
    contractId: string;
    setPensionBeneficiariesRequest: SetPensionBeneficiariesRequest;
}

export interface SetPPContributionPutRequest {
    contractId: string;
    setContributionRequest: SetContributionRequest;
}

export interface SetPPTaxOptimumPutRequest {
    contractId: string;
    setPPTaxOptimumRequest: SetPPTaxOptimumRequest;
}

/**
 * 
 */
export class ContractsPpApi extends runtime.BaseAPI {

    /**
     * Get PP contract detail for the current user
     */
    async getPPDetailGetRaw(requestParameters: GetPPDetailGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetPPDetailResponse>> {
        if (requestParameters['contractId'] == null) {
            throw new runtime.RequiredError(
                'contractId',
                'Required parameter "contractId" was null or undefined when calling getPPDetailGet().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("ADB2CAuthFlow", ["https://nnczcustomerportaldev.onmicrosoft.com/5d23dca9-d19b-4c16-b403-d27b86222083/CustPortalApi.Default"]);
        }

        const response = await this.request({
            path: `/contracts/pp/{contractId}`.replace(`{${"contractId"}}`, encodeURIComponent(String(requestParameters['contractId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetPPDetailResponseFromJSON(jsonValue));
    }

    /**
     * Get PP contract detail for the current user
     */
    async getPPDetailGet(requestParameters: GetPPDetailGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetPPDetailResponse> {
        const response = await this.getPPDetailGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get PP transaction history.
     */
    async getPPTransactionsGetRaw(requestParameters: GetPPTransactionsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GetPensionTransactionsResponse>>> {
        if (requestParameters['contractId'] == null) {
            throw new runtime.RequiredError(
                'contractId',
                'Required parameter "contractId" was null or undefined when calling getPPTransactionsGet().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['count'] != null) {
            queryParameters['Count'] = requestParameters['count'];
        }

        if (requestParameters['from'] != null) {
            queryParameters['From'] = (requestParameters['from'] as any).toISOString();
        }

        if (requestParameters['to'] != null) {
            queryParameters['To'] = (requestParameters['to'] as any).toISOString();
        }

        if (requestParameters['typeFilter'] != null) {
            queryParameters['TypeFilter'] = requestParameters['typeFilter']!.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("ADB2CAuthFlow", ["https://nnczcustomerportaldev.onmicrosoft.com/5d23dca9-d19b-4c16-b403-d27b86222083/CustPortalApi.Default"]);
        }

        const response = await this.request({
            path: `/contracts/pp/{contractId}/transactions`.replace(`{${"contractId"}}`, encodeURIComponent(String(requestParameters['contractId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetPensionTransactionsResponseFromJSON));
    }

    /**
     * Get PP transaction history.
     */
    async getPPTransactionsGet(requestParameters: GetPPTransactionsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GetPensionTransactionsResponse>> {
        const response = await this.getPPTransactionsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * AML identification on PP
     */
    async setPPAmlIdentificationPutRaw(requestParameters: SetPPAmlIdentificationPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['contractId'] == null) {
            throw new runtime.RequiredError(
                'contractId',
                'Required parameter "contractId" was null or undefined when calling setPPAmlIdentificationPut().'
            );
        }

        if (requestParameters['setAmlIdentificationRequest'] == null) {
            throw new runtime.RequiredError(
                'setAmlIdentificationRequest',
                'Required parameter "setAmlIdentificationRequest" was null or undefined when calling setPPAmlIdentificationPut().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("ADB2CAuthFlow", ["https://nnczcustomerportaldev.onmicrosoft.com/5d23dca9-d19b-4c16-b403-d27b86222083/CustPortalApi.Default"]);
        }

        const response = await this.request({
            path: `/contracts/pp/{contractId}/aml-identification`.replace(`{${"contractId"}}`, encodeURIComponent(String(requestParameters['contractId']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: SetAmlIdentificationRequestToJSON(requestParameters['setAmlIdentificationRequest']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * AML identification on PP
     */
    async setPPAmlIdentificationPut(requestParameters: SetPPAmlIdentificationPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.setPPAmlIdentificationPutRaw(requestParameters, initOverrides);
    }

    /**
     * Set beneficiaries for PP
     */
    async setPPBeneficiariesPutRaw(requestParameters: SetPPBeneficiariesPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['contractId'] == null) {
            throw new runtime.RequiredError(
                'contractId',
                'Required parameter "contractId" was null or undefined when calling setPPBeneficiariesPut().'
            );
        }

        if (requestParameters['setPensionBeneficiariesRequest'] == null) {
            throw new runtime.RequiredError(
                'setPensionBeneficiariesRequest',
                'Required parameter "setPensionBeneficiariesRequest" was null or undefined when calling setPPBeneficiariesPut().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("ADB2CAuthFlow", ["https://nnczcustomerportaldev.onmicrosoft.com/5d23dca9-d19b-4c16-b403-d27b86222083/CustPortalApi.Default"]);
        }

        const response = await this.request({
            path: `/contracts/pp/{contractId}/beneficiaries`.replace(`{${"contractId"}}`, encodeURIComponent(String(requestParameters['contractId']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: SetPensionBeneficiariesRequestToJSON(requestParameters['setPensionBeneficiariesRequest']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Set beneficiaries for PP
     */
    async setPPBeneficiariesPut(requestParameters: SetPPBeneficiariesPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.setPPBeneficiariesPutRaw(requestParameters, initOverrides);
    }

    /**
     * Set contribution amount and does employer contribute for PP
     */
    async setPPContributionPutRaw(requestParameters: SetPPContributionPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['contractId'] == null) {
            throw new runtime.RequiredError(
                'contractId',
                'Required parameter "contractId" was null or undefined when calling setPPContributionPut().'
            );
        }

        if (requestParameters['setContributionRequest'] == null) {
            throw new runtime.RequiredError(
                'setContributionRequest',
                'Required parameter "setContributionRequest" was null or undefined when calling setPPContributionPut().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("ADB2CAuthFlow", ["https://nnczcustomerportaldev.onmicrosoft.com/5d23dca9-d19b-4c16-b403-d27b86222083/CustPortalApi.Default"]);
        }

        const response = await this.request({
            path: `/contracts/pp/{contractId}/contributions`.replace(`{${"contractId"}}`, encodeURIComponent(String(requestParameters['contractId']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: SetContributionRequestToJSON(requestParameters['setContributionRequest']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Set contribution amount and does employer contribute for PP
     */
    async setPPContributionPut(requestParameters: SetPPContributionPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.setPPContributionPutRaw(requestParameters, initOverrides);
    }

    /**
     * Change tax optimum on PP
     */
    async setPPTaxOptimumPutRaw(requestParameters: SetPPTaxOptimumPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['contractId'] == null) {
            throw new runtime.RequiredError(
                'contractId',
                'Required parameter "contractId" was null or undefined when calling setPPTaxOptimumPut().'
            );
        }

        if (requestParameters['setPPTaxOptimumRequest'] == null) {
            throw new runtime.RequiredError(
                'setPPTaxOptimumRequest',
                'Required parameter "setPPTaxOptimumRequest" was null or undefined when calling setPPTaxOptimumPut().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("ADB2CAuthFlow", ["https://nnczcustomerportaldev.onmicrosoft.com/5d23dca9-d19b-4c16-b403-d27b86222083/CustPortalApi.Default"]);
        }

        const response = await this.request({
            path: `/contracts/pp/{contractId}/change-tax-optimum`.replace(`{${"contractId"}}`, encodeURIComponent(String(requestParameters['contractId']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: SetPPTaxOptimumRequestToJSON(requestParameters['setPPTaxOptimumRequest']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Change tax optimum on PP
     */
    async setPPTaxOptimumPut(requestParameters: SetPPTaxOptimumPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.setPPTaxOptimumPutRaw(requestParameters, initOverrides);
    }

}
