import { createContext, ReactNode, useContext, useState } from "react";

export type BottomNavigationState = "unset" | "hidden" | "shown";
interface ILayoutContext {
	bottomNavigationState: BottomNavigationState;
	setBottomNavigation: (state: BottomNavigationState) => void;
}

const LayoutContext = createContext<ILayoutContext>({} as ILayoutContext);

export default LayoutContext;

export interface LayoutProps {
	children: ReactNode;
	appBarContent?: ReactNode;
	isBottomNavigationBarHidden?: boolean;
}

// TODO remove completely. Having components from layout context mount on page change can cause ugly bugs.
export const LayoutProvider = ({ children }: LayoutProps): JSX.Element => {
	const [bottomNavigation, setBottomNavigation] =
		useState<BottomNavigationState>("unset");
	return (
		<LayoutContext.Provider
			value={{
				bottomNavigationState: bottomNavigation,
				setBottomNavigation,
			}}
		>
			{children}
		</LayoutContext.Provider>
	);
};

export function useLayout(): ILayoutContext {
	return useContext(LayoutContext);
}
