import useIsDesktop from "desktop/useIsDesktop";
import { PageProps } from "gatsby";

import { PensionPhaseStatus, ProductType } from "../../../models";
import { BackNavigationBar, LoggedInBar } from "../../components/AppBar";
import Client from "../../components/DPS/Client";
import ContractDetail from "../../components/DPS/ContractDetail";
import Docs from "../../components/DPS/Docs";
import Transactions from "../../components/DPS/Transactions";
import Export from "../../components/PP/Export";
import { SEO } from "../../components/SEO";
import { TabsWithQueryParameter } from "../../components/Tabs";
import {
	ContractIDProvider,
	extractContractIDFromPageProps,
} from "../../context/ContractIDContext";
import { useContractId } from "../../context/ContractIDContext";
import { ContractTypeProvider } from "../../context/ContractTypeContext";
import withProtection from "../../highOrderComponents/withProtection";
import { useSPErrorTabsRedirect } from "../../hooks/useSPError";
import {
	useAllowedChangesForContract,
	useContractById,
} from "../../queryHooks";
import cs from "../../translations/cs.json";

const title = "Penzijní připojištění";

export const AppBar = (pageProps: PageProps): JSX.Element =>
	useIsDesktop() ? (
		<LoggedInBar />
	) : (
		<BackNavigationBar
			title={title}
			id={extractContractIDFromPageProps(pageProps)}
		/>
	);

const PPContent = () => {
	const id = useContractId();
	const { data, isSuccess } = useContractById({
		id: id,
		options: { refetchOnMount: false },
	});

	const {
		data: allowedData,
		isSuccess: allowedIsSuccess,
		isError,
	} = useAllowedChangesForContract({
		id: id,
		options: {
			enabled: !!id,
		},
	});

	void useSPErrorTabsRedirect();

	if (isSuccess) {
		return (
			<TabsWithQueryParameter
				appBar={<BackNavigationBar title={title} />}
				queryParamKey="main-tab"
				tabs={[
					data?.pensionPhaseStatus === PensionPhaseStatus.Payout
						? null
						: { label: "Detail", slug: "detail", disabled: isError },
					{ label: "Platby", slug: "pohyby-platby", disabled: isError },
					{ label: "Klient", slug: "klient", disabled: isError },
					{ label: "Smlouva", slug: "smlouva", disabled: isError },
					{ label: "Dokumenty", slug: "dokumenty" },
				]}
			>
				{data?.pensionPhaseStatus === PensionPhaseStatus.Payout ? null : (
					<Export />
				)}
				<Transactions />
				<Client />
				<ContractDetail
					pendingChanges={allowedData?.pendingChanges || undefined}
					isSuccessPendingChanges={allowedIsSuccess}
				/>
				<Docs />
			</TabsWithQueryParameter>
		);
	}

	return <>{cs.global.pageIsLoading}</>;
};

function PenzijniPripojisteni(pageProps: PageProps): JSX.Element {
	return (
		<ContractIDProvider contractId={extractContractIDFromPageProps(pageProps)}>
			<ContractTypeProvider contractType={ProductType.Pf}>
				<PPContent />
			</ContractTypeProvider>
		</ContractIDProvider>
	);
}

export default withProtection(PenzijniPripojisteni);

export const Head = (): JSX.Element => (
	<SEO title={title}>
		<meta name="robots" content="noindex" />
	</SEO>
);
