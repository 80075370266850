/* tslint:disable */
/* eslint-disable */
/**
 * NN.CustomerPortal.ApiApp.General
 * GeneralAPIs
 *
 * The version of the OpenAPI document: 1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { FundCode } from './FundCode';
import {
    FundCodeFromJSON,
    FundCodeFromJSONTyped,
    FundCodeToJSON,
} from './FundCode';

/**
 * 
 * @export
 * @interface CustomAllocation
 */
export interface CustomAllocation {
    /**
     * 
     * @type {FundCode}
     * @memberof CustomAllocation
     */
    fundCode: FundCode;
    /**
     * 
     * @type {number}
     * @memberof CustomAllocation
     */
    allocation: number;
}

/**
 * Check if a given object implements the CustomAllocation interface.
 */
export function instanceOfCustomAllocation(value: object): value is CustomAllocation {
    if (!('fundCode' in value) || value['fundCode'] === undefined) return false;
    if (!('allocation' in value) || value['allocation'] === undefined) return false;
    return true;
}

export function CustomAllocationFromJSON(json: any): CustomAllocation {
    return CustomAllocationFromJSONTyped(json, false);
}

export function CustomAllocationFromJSONTyped(json: any, ignoreDiscriminator: boolean): CustomAllocation {
    if (json == null) {
        return json;
    }
    return {
        
        'fundCode': FundCodeFromJSON(json['fundCode']),
        'allocation': json['allocation'],
    };
}

export function CustomAllocationToJSON(value?: CustomAllocation | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'fundCode': FundCodeToJSON(value['fundCode']),
        'allocation': value['allocation'],
    };
}

