/* tslint:disable */
/* eslint-disable */
/**
 * NN.CustomerPortal.ApiApp.General
 * GeneralAPIs
 *
 * The version of the OpenAPI document: 1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { AttentionType } from './AttentionType';
import {
    AttentionTypeFromJSON,
    AttentionTypeFromJSONTyped,
    AttentionTypeToJSON,
} from './AttentionType';

/**
 * 
 * @export
 * @interface Attention
 */
export interface Attention {
    /**
     * 
     * @type {AttentionType}
     * @memberof Attention
     */
    type: AttentionType;
    /**
     * 
     * @type {number}
     * @memberof Attention
     */
    amount?: number | null;
}

/**
 * Check if a given object implements the Attention interface.
 */
export function instanceOfAttention(value: object): value is Attention {
    if (!('type' in value) || value['type'] === undefined) return false;
    return true;
}

export function AttentionFromJSON(json: any): Attention {
    return AttentionFromJSONTyped(json, false);
}

export function AttentionFromJSONTyped(json: any, ignoreDiscriminator: boolean): Attention {
    if (json == null) {
        return json;
    }
    return {
        
        'type': AttentionTypeFromJSON(json['type']),
        'amount': json['amount'] == null ? undefined : json['amount'],
    };
}

export function AttentionToJSON(value?: Attention | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'type': AttentionTypeToJSON(value['type']),
        'amount': value['amount'],
    };
}

