import { InteractionStatus } from "@azure/msal-browser";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import Box from "@mui/material/Box";
import { ButtonProps } from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import Toolbar from "@mui/material/Toolbar";
import { navigate } from "gatsby";
import { ReactNode } from "react";

import BottomNavigation from "../../components/BottomNavigation";
import BackIcon from "../../components/Icons/Back";
import LogOutIcon from "../../components/Icons/LogOut";
import PhoneIcon from "../../components/Icons/Phone";
import Logo from "../../images/logo.svg";
import { InternalButtonLink, InternalLink } from "../Link";
import useLogout from "../LogoutButton/useLogout";
import { BodyTextS, HeadingS } from "../Typography";

export interface BarProps {
	children: ReactNode;
}

function EmptyBar({ children }: BarProps): JSX.Element {
	return (
		<Toolbar
			sx={{
				justifyContent: "space-between",
				/// need media query for higher specifity
				height: "80px",
				px: { xxs: 0 },
			}}
		>
			<InternalLink to="/" sx={{ lineHeight: 0 }}>
				<img src={Logo} alt="NN" />
			</InternalLink>
			{children}
		</Toolbar>
	);
}

export function LoggedOutBar(): JSX.Element {
	return (
		<EmptyBar>
			<InternalButtonLink
				to="tel:+420244090800"
				variant="appBarLink"
				size="small"
				startIcon={
					<PhoneIcon sx={{ width: "1.7rem", height: "2.4rem" }} color="text" />
				}
			>
				244 090 800
			</InternalButtonLink>
		</EmptyBar>
	);
}

function LogoutButton({ sx }: ButtonProps) {
	const { click } = useLogout();
	return (
		<InternalButtonLink
			variant="appBarLink"
			size="small"
			startIcon={
				<LogOutIcon sx={{ width: "1.6rem", height: "2.1rem", mr: 2 }} />
			}
			sx={{ ...sx }}
			onClick={click}
			data-test="logout-button"
		>
			Odhlásit
		</InternalButtonLink>
	);
}

export function LoggedInBar(): JSX.Element {
	return (
		<EmptyBar>
			<Box sx={{ display: { md: "none" }, flexGrow: 1 }} />
			<BottomNavigation variant="desktop" />
			<LogoutButton
				sx={{
					pr: 0,
				}}
			/>
		</EmptyBar>
	);
}

export function PortalAppBar(): JSX.Element {
	const context = useMsal();
	const isAuthenticated = useIsAuthenticated();
	if (context.inProgress !== InteractionStatus.Startup) {
		if (isAuthenticated) {
			return <LoggedInBar />;
		} else {
			return <LoggedOutBar />;
		}
	} else {
		return (
			<EmptyBar>
				<Skeleton width={300} />
				<Skeleton width={100} />
			</EmptyBar>
		);
	}
}

interface Props {
	id?: string | ReactNode;
	title: ReactNode;
	subtitle?: ReactNode;
}

export function BackNavigationBar({ id, title, subtitle }: Props): JSX.Element {
	return (
		<Toolbar>
			<IconButton
				aria-label="menu"
				onClick={() => navigate("/smlouvy/")}
				sx={{
					position: "relative",
					left: (theme) => `-${theme.spacing(3)}`,
					color: "transparent",
				}}
			>
				<BackIcon sx={{ width: "2.2rem", height: "2.2rem" }} color="primary" />
			</IconButton>
			<Stack pt={1} width="100%">
				<HeadingS sx={{ mb: -2, color: "text.primary" }}>{title}</HeadingS>
				{subtitle && (
					<BodyTextS sx={{ mb: -2, color: "grey.dark" }}>{subtitle}</BodyTextS>
				)}
				{typeof id === "string" && (
					<BodyTextS sx={{ mb: -2, color: "grey.dark" }}>{`(${id})`}</BodyTextS>
				)}
			</Stack>
		</Toolbar>
	);
}

export function FauxLoggedInBar(): JSX.Element {
	return (
		<EmptyBar>
			<InternalButtonLink
				variant="appBarLink"
				size="small"
				startIcon={
					<LogOutIcon sx={{ width: "1.6rem", height: "2.1rem", mr: 2 }} />
				}
				sx={{
					pr: 0,
				}}
				to="/rozcestnik"
			>
				Odhlásit
			</InternalButtonLink>
		</EmptyBar>
	);
}
