/* tslint:disable */
/* eslint-disable */
/**
 * NN.CustomerPortal.ApiApp.General
 * GeneralAPIs
 *
 * The version of the OpenAPI document: 1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { AddressRequest } from './AddressRequest';
import {
    AddressRequestFromJSON,
    AddressRequestFromJSONTyped,
    AddressRequestToJSON,
} from './AddressRequest';
import type { BeneficiaryRelationship } from './BeneficiaryRelationship';
import {
    BeneficiaryRelationshipFromJSON,
    BeneficiaryRelationshipFromJSONTyped,
    BeneficiaryRelationshipToJSON,
} from './BeneficiaryRelationship';

/**
 * 
 * @export
 * @interface BeneficiaryRequest
 */
export interface BeneficiaryRequest {
    /**
     * 
     * @type {string}
     * @memberof BeneficiaryRequest
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof BeneficiaryRequest
     */
    lastName: string;
    /**
     * 
     * @type {Date}
     * @memberof BeneficiaryRequest
     */
    dateOfBirth?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof BeneficiaryRequest
     */
    birthNumber?: string | null;
    /**
     * 
     * @type {BeneficiaryRelationship}
     * @memberof BeneficiaryRequest
     */
    relationship?: BeneficiaryRelationship;
    /**
     * 
     * @type {number}
     * @memberof BeneficiaryRequest
     */
    ratio: number;
    /**
     * 
     * @type {AddressRequest}
     * @memberof BeneficiaryRequest
     */
    address?: AddressRequest;
}

/**
 * Check if a given object implements the BeneficiaryRequest interface.
 */
export function instanceOfBeneficiaryRequest(value: object): value is BeneficiaryRequest {
    if (!('firstName' in value) || value['firstName'] === undefined) return false;
    if (!('lastName' in value) || value['lastName'] === undefined) return false;
    if (!('ratio' in value) || value['ratio'] === undefined) return false;
    return true;
}

export function BeneficiaryRequestFromJSON(json: any): BeneficiaryRequest {
    return BeneficiaryRequestFromJSONTyped(json, false);
}

export function BeneficiaryRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): BeneficiaryRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'firstName': json['firstName'],
        'lastName': json['lastName'],
        'dateOfBirth': json['dateOfBirth'] == null ? undefined : (new Date(json['dateOfBirth'])),
        'birthNumber': json['birthNumber'] == null ? undefined : json['birthNumber'],
        'relationship': json['relationship'] == null ? undefined : BeneficiaryRelationshipFromJSON(json['relationship']),
        'ratio': json['ratio'],
        'address': json['address'] == null ? undefined : AddressRequestFromJSON(json['address']),
    };
}

export function BeneficiaryRequestToJSON(value?: BeneficiaryRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'firstName': value['firstName'],
        'lastName': value['lastName'],
        'dateOfBirth': value['dateOfBirth'] == null ? undefined : ((value['dateOfBirth'] as any).toISOString()),
        'birthNumber': value['birthNumber'],
        'relationship': BeneficiaryRelationshipToJSON(value['relationship']),
        'ratio': value['ratio'],
        'address': AddressRequestToJSON(value['address']),
    };
}

