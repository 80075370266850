/* tslint:disable */
/* eslint-disable */
/**
 * NN.CustomerPortal.ApiApp.General
 * GeneralAPIs
 *
 * The version of the OpenAPI document: 1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { AllowedChangesItem } from './AllowedChangesItem';
import {
    AllowedChangesItemFromJSON,
    AllowedChangesItemFromJSONTyped,
    AllowedChangesItemToJSON,
} from './AllowedChangesItem';

/**
 * 
 * @export
 * @interface GetAllowedChangesResponse
 */
export interface GetAllowedChangesResponse {
    /**
     * 
     * @type {boolean}
     * @memberof GetAllowedChangesResponse
     */
    isGlobalContactAddressChangeEnabled: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetAllowedChangesResponse
     */
    hasAnyFailedChange: boolean;
    /**
     * 
     * @type {Array<AllowedChangesItem>}
     * @memberof GetAllowedChangesResponse
     */
    contracts: Array<AllowedChangesItem>;
}

/**
 * Check if a given object implements the GetAllowedChangesResponse interface.
 */
export function instanceOfGetAllowedChangesResponse(value: object): value is GetAllowedChangesResponse {
    if (!('isGlobalContactAddressChangeEnabled' in value) || value['isGlobalContactAddressChangeEnabled'] === undefined) return false;
    if (!('hasAnyFailedChange' in value) || value['hasAnyFailedChange'] === undefined) return false;
    if (!('contracts' in value) || value['contracts'] === undefined) return false;
    return true;
}

export function GetAllowedChangesResponseFromJSON(json: any): GetAllowedChangesResponse {
    return GetAllowedChangesResponseFromJSONTyped(json, false);
}

export function GetAllowedChangesResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetAllowedChangesResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'isGlobalContactAddressChangeEnabled': json['isGlobalContactAddressChangeEnabled'],
        'hasAnyFailedChange': json['hasAnyFailedChange'],
        'contracts': ((json['contracts'] as Array<any>).map(AllowedChangesItemFromJSON)),
    };
}

export function GetAllowedChangesResponseToJSON(value?: GetAllowedChangesResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'isGlobalContactAddressChangeEnabled': value['isGlobalContactAddressChangeEnabled'],
        'hasAnyFailedChange': value['hasAnyFailedChange'],
        'contracts': ((value['contracts'] as Array<any>).map(AllowedChangesItemToJSON)),
    };
}

