import { useIsAuthenticated } from "@azure/msal-react";
import { Box, Stack } from "@mui/material";
import { PageProps } from "gatsby";
import { ComponentType, PropsWithChildren, ReactNode } from "react";

import { AppBar as ProfilesAppBar } from "../../components/Profile/Layout";
import { EnvironmentHelper } from "../../functions/env";
import { AppBar as AppBar404 } from "../../pages/404";
import { AppBar as ContactsAppBar, ContactsImage } from "../../pages/contacts";
import { AppBar as ContractsAppBar } from "../../pages/contracts";
import { AppBar as DocumentsAppBar } from "../../pages/documents";
import { AppBar as DPSAppBar } from "../../pages/doplnkove-penzijni-sporeni";
import { AppBar as UnsupportedAppBar } from "../../pages/nepodporovany-prohlizec";
import { AppBar as PPAppBar } from "../../pages/penzijni-pripojisteni";
import { AppBar as PrihlaseniAppBar } from "../../pages/prihlaseni";
import { AppBar as PrihlaseniNeboRegistraceAppBar } from "../../pages/prihlaseni-nebo-registrace";
import { AppBar as RegistraceAppBar } from "../../pages/registrace";
import { AppBar as CrossroadAppBar } from "../../pages/rozcestnik";
import { AppBar as UknownUserAppBar } from "../../pages/uzivatel-nenalezen";
import cs from "../../translations/cs.json";
import { LoggedOutBar as UdrzbaAppBar } from "../AppBar";
import AppBar from "../AppBar";
import BottomNavigation from "../BottomNavigation";
import { DataConfig } from "../DataConfig";
import Drawer from "../Drawer";
import { LogoutWarning } from "../Logout";
import { CommonLifeAppBar } from "../ZivotniPojisteni/AppBar";
import LayoutContext, {
	BottomNavigationState,
	LayoutProvider,
	useLayout,
} from "./context";
import LayoutBody from "./LayoutBody";

const appBarBySubPage: Record<string, ComponentType> = {
	ProfilesAppBar,
	DocumentsAppBar,
	ContractsAppBar,
	DPSAppBar,
	CommonLifeAppBar,
	PPAppBar,
	PrihlaseniNeboRegistraceAppBar,
	RegistraceAppBar,
	PrihlaseniAppBar,
	UknownUserAppBar,
	CrossroadAppBar,
	ContactsAppBar,
	AppBar404,
	UdrzbaAppBar,
	UnsupportedAppBar,
	CrossroadAppBar,
};

export { LayoutContext, LayoutProvider };

export const TitleByPage = {
	contacts: cs.bottomNavigationBar.contacts,
	profile: cs.bottomNavigationBar.profile,
};

export const BannerByPageWrapperDesktop = {
	contracts: ({ children }: PropsWithChildren) => (
		<Stack sx={{ gap: 4, alignItems: "stretch", width: "100%", mb: 5 }}>
			{children}
		</Stack>
	),
};

export const BannerByPageWrapperMobile = {
	contracts: ({ children }: PropsWithChildren) => (
		<Box sx={{ mb: 4, px: 4 }}>{children}</Box>
	),
};

export const BackgroundImageByPage = {
	contacts: ContactsImage,
};

export interface LayoutProps extends PageProps {
	appBarContent?: ReactNode;
	withGlobalDrawer?: boolean;
	bottomNavigation: boolean;
	appBar: string;
}

const isDev = process.env.NODE_ENV == "development";

const getBottomNavigation = (
	pageProp: boolean,
	navState: BottomNavigationState
) => {
	if (navState === "unset") {
		return pageProp;
	} else {
		return navState === "shown" ? true : false;
	}
};

const Layout = (layoutProps: LayoutProps): JSX.Element => {
	const { children, withGlobalDrawer = true } = layoutProps;
	const { bottomNavigationState } = useLayout();
	const showBottomNavigation = getBottomNavigation(
		layoutProps.bottomNavigation,
		bottomNavigationState
	);

	const isAuthenticated = useIsAuthenticated();
	const AppBarContent =
		appBarBySubPage[layoutProps.appBar] ?? appBarBySubPage["AppBar404"];
	return (
		<>
			<Stack>
				{layoutProps.appBar !== "none" && (
					<AppBar>
						<AppBarContent {...layoutProps} />
					</AppBar>
				)}
				<LayoutBody
					pageProps={{
						name: layoutProps.pageContext.page,
						...layoutProps.pageContext.additionalProps,
					}}
				>
					{children}
				</LayoutBody>
				{showBottomNavigation && <BottomNavigation />}
			</Stack>
			{!EnvironmentHelper.isProd() && <DataConfig />}
			{withGlobalDrawer && <Drawer />}
			{isAuthenticated && !isDev && (
				<LogoutWarning
					pageName={layoutProps.pageContext.page as string | undefined}
				/>
			)}
		</>
	);
};

export default Layout;
