/* tslint:disable */
/* eslint-disable */
/**
 * NN.CustomerPortal.ApiApp.General
 * GeneralAPIs
 *
 * The version of the OpenAPI document: 1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CmsWarningsResponse,
} from '../models/index';
import {
    CmsWarningsResponseFromJSON,
    CmsWarningsResponseToJSON,
} from '../models/index';

/**
 * 
 */
export class BannersApi extends runtime.BaseAPI {

    /**
     * Get CMS info banners
     */
    async getCmsWarningsGetRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CmsWarningsResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("ADB2CAuthFlow", ["https://nnczcustomerportaldev.onmicrosoft.com/5d23dca9-d19b-4c16-b403-d27b86222083/CustPortalApi.Default"]);
        }

        const response = await this.request({
            path: `/banners/cms-warnings`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CmsWarningsResponseFromJSON(jsonValue));
    }

    /**
     * Get CMS info banners
     */
    async getCmsWarningsGet(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CmsWarningsResponse> {
        const response = await this.getCmsWarningsGetRaw(initOverrides);
        return await response.value();
    }

}
