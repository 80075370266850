export enum CustomApiError {
	None = 0,
	InvalidQueryParameters = 399,
	ValidationFailed = 400,
	Unauthorized = 401,
	NotFound = 404,
	MethodNotAllowed = 405,
	UnprocessableEntity = 422,
	StarPensionCallBlocked = 423,
	DBCallFailed = 501,
	UnhandledException = 502,
	PensionContributionAMLFailed = 600,
	StarPensionCallFailed = 700,
	SMSCodeNotValid = 800,
	LivelinkCallFailed = 900,
}
