/* tslint:disable */
/* eslint-disable */
/**
 * NN.CustomerPortal.ApiApp.General
 * GeneralAPIs
 *
 * The version of the OpenAPI document: 1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { PensionPendingChange } from './PensionPendingChange';
import {
    PensionPendingChangeFromJSON,
    PensionPendingChangeFromJSONTyped,
    PensionPendingChangeToJSON,
} from './PensionPendingChange';

/**
 * 
 * @export
 * @interface GetDPSAllowedChangesResponse
 */
export interface GetDPSAllowedChangesResponse {
    /**
     * 
     * @type {Array<PensionPendingChange>}
     * @memberof GetDPSAllowedChangesResponse
     */
    pendingChanges: Array<PensionPendingChange>;
    /**
     * 
     * @type {boolean}
     * @memberof GetDPSAllowedChangesResponse
     */
    canChangeInvestmentStrategy: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetDPSAllowedChangesResponse
     */
    canChangeEmployerOrClientContribution: boolean;
}

/**
 * Check if a given object implements the GetDPSAllowedChangesResponse interface.
 */
export function instanceOfGetDPSAllowedChangesResponse(value: object): value is GetDPSAllowedChangesResponse {
    if (!('pendingChanges' in value) || value['pendingChanges'] === undefined) return false;
    if (!('canChangeInvestmentStrategy' in value) || value['canChangeInvestmentStrategy'] === undefined) return false;
    if (!('canChangeEmployerOrClientContribution' in value) || value['canChangeEmployerOrClientContribution'] === undefined) return false;
    return true;
}

export function GetDPSAllowedChangesResponseFromJSON(json: any): GetDPSAllowedChangesResponse {
    return GetDPSAllowedChangesResponseFromJSONTyped(json, false);
}

export function GetDPSAllowedChangesResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetDPSAllowedChangesResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'pendingChanges': ((json['pendingChanges'] as Array<any>).map(PensionPendingChangeFromJSON)),
        'canChangeInvestmentStrategy': json['canChangeInvestmentStrategy'],
        'canChangeEmployerOrClientContribution': json['canChangeEmployerOrClientContribution'],
    };
}

export function GetDPSAllowedChangesResponseToJSON(value?: GetDPSAllowedChangesResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'pendingChanges': ((value['pendingChanges'] as Array<any>).map(PensionPendingChangeToJSON)),
        'canChangeInvestmentStrategy': value['canChangeInvestmentStrategy'],
        'canChangeEmployerOrClientContribution': value['canChangeEmployerOrClientContribution'],
    };
}

