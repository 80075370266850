/* tslint:disable */
/* eslint-disable */
/**
 * NN.CustomerPortal.ApiApp.General
 * GeneralAPIs
 *
 * The version of the OpenAPI document: 1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ActiveDirectoryAction } from './ActiveDirectoryAction';
import {
    ActiveDirectoryActionFromJSON,
    ActiveDirectoryActionFromJSONTyped,
    ActiveDirectoryActionToJSON,
} from './ActiveDirectoryAction';

/**
 * 
 * @export
 * @interface RegisterResponse
 */
export interface RegisterResponse {
    /**
     * 
     * @type {string}
     * @memberof RegisterResponse
     */
    version: string;
    /**
     * 
     * @type {ActiveDirectoryAction}
     * @memberof RegisterResponse
     */
    action: ActiveDirectoryAction;
    /**
     * 
     * @type {string}
     * @memberof RegisterResponse
     */
    userMessage?: string | null;
    /**
     * 
     * @type {number}
     * @memberof RegisterResponse
     */
    status?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RegisterResponse
     */
    statusCode: number;
    /**
     * 
     * @type {string}
     * @memberof RegisterResponse
     */
    pid?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RegisterResponse
     */
    mpk?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RegisterResponse
     */
    reasonPhrase: string;
    /**
     * 
     * @type {number}
     * @memberof RegisterResponse
     */
    reasonCode: number;
}

/**
 * Check if a given object implements the RegisterResponse interface.
 */
export function instanceOfRegisterResponse(value: object): value is RegisterResponse {
    if (!('version' in value) || value['version'] === undefined) return false;
    if (!('action' in value) || value['action'] === undefined) return false;
    if (!('statusCode' in value) || value['statusCode'] === undefined) return false;
    if (!('reasonPhrase' in value) || value['reasonPhrase'] === undefined) return false;
    if (!('reasonCode' in value) || value['reasonCode'] === undefined) return false;
    return true;
}

export function RegisterResponseFromJSON(json: any): RegisterResponse {
    return RegisterResponseFromJSONTyped(json, false);
}

export function RegisterResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): RegisterResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'version': json['version'],
        'action': ActiveDirectoryActionFromJSON(json['action']),
        'userMessage': json['userMessage'] == null ? undefined : json['userMessage'],
        'status': json['status'] == null ? undefined : json['status'],
        'statusCode': json['statusCode'],
        'pid': json['pid'] == null ? undefined : json['pid'],
        'mpk': json['mpk'] == null ? undefined : json['mpk'],
        'reasonPhrase': json['reason_phrase'],
        'reasonCode': json['reason_code'],
    };
}

export function RegisterResponseToJSON(value?: RegisterResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'version': value['version'],
        'action': ActiveDirectoryActionToJSON(value['action']),
        'userMessage': value['userMessage'],
        'status': value['status'],
        'statusCode': value['statusCode'],
        'pid': value['pid'],
        'mpk': value['mpk'],
        'reason_phrase': value['reasonPhrase'],
        'reason_code': value['reasonCode'],
    };
}

