/* tslint:disable */
/* eslint-disable */
/**
 * NN.CustomerPortal.ApiApp.General
 * GeneralAPIs
 *
 * The version of the OpenAPI document: 1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Organization
 */
export interface Organization {
    /**
     * 
     * @type {number}
     * @memberof Organization
     */
    id: number;
    /**
     * 
     * @type {Date}
     * @memberof Organization
     */
    foundationDate?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof Organization
     */
    organizationKindCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Organization
     */
    taxIdentificationNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Organization
     */
    vatIdentificationNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Organization
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Organization
     */
    fullName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Organization
     */
    sourceSystem?: string | null;
}

/**
 * Check if a given object implements the Organization interface.
 */
export function instanceOfOrganization(value: object): value is Organization {
    if (!('id' in value) || value['id'] === undefined) return false;
    return true;
}

export function OrganizationFromJSON(json: any): Organization {
    return OrganizationFromJSONTyped(json, false);
}

export function OrganizationFromJSONTyped(json: any, ignoreDiscriminator: boolean): Organization {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'foundationDate': json['foundationDate'] == null ? undefined : (new Date(json['foundationDate'])),
        'organizationKindCode': json['organizationKindCode'] == null ? undefined : json['organizationKindCode'],
        'taxIdentificationNumber': json['taxIdentificationNumber'] == null ? undefined : json['taxIdentificationNumber'],
        'vatIdentificationNumber': json['vatIdentificationNumber'] == null ? undefined : json['vatIdentificationNumber'],
        'name': json['name'] == null ? undefined : json['name'],
        'fullName': json['fullName'] == null ? undefined : json['fullName'],
        'sourceSystem': json['sourceSystem'] == null ? undefined : json['sourceSystem'],
    };
}

export function OrganizationToJSON(value?: Organization | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'foundationDate': value['foundationDate'] == null ? undefined : ((value['foundationDate'] as any).toISOString().substring(0,10)),
        'organizationKindCode': value['organizationKindCode'],
        'taxIdentificationNumber': value['taxIdentificationNumber'],
        'vatIdentificationNumber': value['vatIdentificationNumber'],
        'name': value['name'],
        'fullName': value['fullName'],
        'sourceSystem': value['sourceSystem'],
    };
}

