import { Skeleton } from "@mui/material";

import { TriggerArrowDown } from "../../../../Link/Trigger";

interface LoadMoreButtonProps {
	fetchMore: () => void;
	loading: boolean;
}

const LoadMoreButton = ({
	fetchMore,
	loading,
}: LoadMoreButtonProps): JSX.Element => {
	if (loading) return <Skeleton />;

	return (
		<TriggerArrowDown
			onClick={fetchMore}
			sx={{ alignSelf: "flex-start", py: 4 }}
		>
			Starší pohyby
		</TriggerArrowDown>
	);
};

export default LoadMoreButton;
