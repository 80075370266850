/* tslint:disable */
/* eslint-disable */
/**
 * NN.CustomerPortal.ApiApp.General
 * GeneralAPIs
 *
 * The version of the OpenAPI document: 1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ChangesDisabledReason } from './ChangesDisabledReason';
import {
    ChangesDisabledReasonFromJSON,
    ChangesDisabledReasonFromJSONTyped,
    ChangesDisabledReasonToJSON,
} from './ChangesDisabledReason';

/**
 * 
 * @export
 * @interface DPSUIStatus
 */
export interface DPSUIStatus {
    /**
     * 
     * @type {boolean}
     * @memberof DPSUIStatus
     */
    canChangeBeneficiaries: boolean;
    /**
     * 
     * @type {Array<ChangesDisabledReason>}
     * @memberof DPSUIStatus
     */
    reasons: Array<ChangesDisabledReason>;
}

/**
 * Check if a given object implements the DPSUIStatus interface.
 */
export function instanceOfDPSUIStatus(value: object): value is DPSUIStatus {
    if (!('canChangeBeneficiaries' in value) || value['canChangeBeneficiaries'] === undefined) return false;
    if (!('reasons' in value) || value['reasons'] === undefined) return false;
    return true;
}

export function DPSUIStatusFromJSON(json: any): DPSUIStatus {
    return DPSUIStatusFromJSONTyped(json, false);
}

export function DPSUIStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): DPSUIStatus {
    if (json == null) {
        return json;
    }
    return {
        
        'canChangeBeneficiaries': json['canChangeBeneficiaries'],
        'reasons': ((json['reasons'] as Array<any>).map(ChangesDisabledReasonFromJSON)),
    };
}

export function DPSUIStatusToJSON(value?: DPSUIStatus | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'canChangeBeneficiaries': value['canChangeBeneficiaries'],
        'reasons': ((value['reasons'] as Array<any>).map(ChangesDisabledReasonToJSON)),
    };
}

