export type DataWithStatus<TData> =
	| { isSuccess: true; data: TData }
	| { isSuccess: false; data?: undefined };

export const mapToDataWithStatus = <TData extends unknown>(
	data: TData,
	isSuccess: boolean
): DataWithStatus<TData> => {
	if (!isSuccess) return { isSuccess };
	return { isSuccess, data };
};
