/* tslint:disable */
/* eslint-disable */
/**
 * NN.CustomerPortal.ApiApp.General
 * GeneralAPIs
 *
 * The version of the OpenAPI document: 1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const PriceItemType = {
    MainRider: 'MainRider',
    Rider: 'Rider',
    Discount: 'Discount',
    Surcharge: 'Surcharge'
} as const;
export type PriceItemType = typeof PriceItemType[keyof typeof PriceItemType];


export function instanceOfPriceItemType(value: any): boolean {
    for (const key in PriceItemType) {
        if (Object.prototype.hasOwnProperty.call(PriceItemType, key)) {
            if ((PriceItemType as Record<string, PriceItemType>)[key] === value) {
                return true;
            }
        }
    }
    return false;
}

export function PriceItemTypeFromJSON(json: any): PriceItemType {
    return PriceItemTypeFromJSONTyped(json, false);
}

export function PriceItemTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): PriceItemType {
    return json as PriceItemType;
}

export function PriceItemTypeToJSON(value?: PriceItemType | null): any {
    return value as any;
}

