import { GetLifeDetailResponse } from "../../../../models";
import { useContractId } from "../../../context/ContractIDContext";
import { useLifeDetail } from "../../../queryHooks";
import { DataWithStatus } from "../../../types/global";
import { DebtStatus, getDebtStatusFromPaymentInfo } from "./PayById";

export type DebtInformation = DataWithStatus<DebtStatus | undefined>;

export const useDebtInformation = (): DebtInformation => {
	const id = useContractId();
	const { data, isSuccess } = useLifeDetail<GetLifeDetailResponse>({ id });

	if (!isSuccess) return { isSuccess };

	const debtStatus = getDebtStatusFromPaymentInfo(data.paymentInfo);

	return {
		data: debtStatus,
		isSuccess,
	};
};
