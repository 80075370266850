/* tslint:disable */
/* eslint-disable */
/**
 * NN.CustomerPortal.ApiApp.General
 * GeneralAPIs
 *
 * The version of the OpenAPI document: 1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Address } from './Address';
import {
    AddressFromJSON,
    AddressFromJSONTyped,
    AddressToJSON,
} from './Address';
import type { BeneficiaryRelationship } from './BeneficiaryRelationship';
import {
    BeneficiaryRelationshipFromJSON,
    BeneficiaryRelationshipFromJSONTyped,
    BeneficiaryRelationshipToJSON,
} from './BeneficiaryRelationship';

/**
 * 
 * @export
 * @interface Beneficiary
 */
export interface Beneficiary {
    /**
     * 
     * @type {string}
     * @memberof Beneficiary
     */
    firstName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Beneficiary
     */
    lastName?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof Beneficiary
     */
    dateOfBirth?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof Beneficiary
     */
    birthNumber?: string | null;
    /**
     * 
     * @type {BeneficiaryRelationship}
     * @memberof Beneficiary
     */
    relationship: BeneficiaryRelationship;
    /**
     * 
     * @type {number}
     * @memberof Beneficiary
     */
    ratio: number;
    /**
     * 
     * @type {Address}
     * @memberof Beneficiary
     */
    address?: Address;
    /**
     * 
     * @type {boolean}
     * @memberof Beneficiary
     */
    isBank: boolean;
}

/**
 * Check if a given object implements the Beneficiary interface.
 */
export function instanceOfBeneficiary(value: object): value is Beneficiary {
    if (!('relationship' in value) || value['relationship'] === undefined) return false;
    if (!('ratio' in value) || value['ratio'] === undefined) return false;
    if (!('isBank' in value) || value['isBank'] === undefined) return false;
    return true;
}

export function BeneficiaryFromJSON(json: any): Beneficiary {
    return BeneficiaryFromJSONTyped(json, false);
}

export function BeneficiaryFromJSONTyped(json: any, ignoreDiscriminator: boolean): Beneficiary {
    if (json == null) {
        return json;
    }
    return {
        
        'firstName': json['firstName'] == null ? undefined : json['firstName'],
        'lastName': json['lastName'] == null ? undefined : json['lastName'],
        'dateOfBirth': json['dateOfBirth'] == null ? undefined : (new Date(json['dateOfBirth'])),
        'birthNumber': json['birthNumber'] == null ? undefined : json['birthNumber'],
        'relationship': BeneficiaryRelationshipFromJSON(json['relationship']),
        'ratio': json['ratio'],
        'address': json['address'] == null ? undefined : AddressFromJSON(json['address']),
        'isBank': json['isBank'],
    };
}

export function BeneficiaryToJSON(value?: Beneficiary | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'firstName': value['firstName'],
        'lastName': value['lastName'],
        'dateOfBirth': value['dateOfBirth'] == null ? undefined : ((value['dateOfBirth'] as any).toISOString()),
        'birthNumber': value['birthNumber'],
        'relationship': BeneficiaryRelationshipToJSON(value['relationship']),
        'ratio': value['ratio'],
        'address': AddressToJSON(value['address']),
        'isBank': value['isBank'],
    };
}

