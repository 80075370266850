/* tslint:disable */
/* eslint-disable */
/**
 * NN.CustomerPortal.ApiApp.General
 * GeneralAPIs
 *
 * The version of the OpenAPI document: 1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const AttentionType = {
    Debt: 'Debt',
    TaxBonus: 'TaxBonus',
    Error: 'Error',
    Warning: 'Warning'
} as const;
export type AttentionType = typeof AttentionType[keyof typeof AttentionType];


export function instanceOfAttentionType(value: any): boolean {
    for (const key in AttentionType) {
        if (Object.prototype.hasOwnProperty.call(AttentionType, key)) {
            if ((AttentionType as Record<string, AttentionType>)[key] === value) {
                return true;
            }
        }
    }
    return false;
}

export function AttentionTypeFromJSON(json: any): AttentionType {
    return AttentionTypeFromJSONTyped(json, false);
}

export function AttentionTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): AttentionType {
    return json as AttentionType;
}

export function AttentionTypeToJSON(value?: AttentionType | null): any {
    return value as any;
}

