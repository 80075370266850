/* tslint:disable */
/* eslint-disable */
/**
 * NN.CustomerPortal.ApiApp.General
 * GeneralAPIs
 *
 * The version of the OpenAPI document: 1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { FundCode } from './FundCode';
import {
    FundCodeFromJSON,
    FundCodeFromJSONTyped,
    FundCodeToJSON,
} from './FundCode';

/**
 * 
 * @export
 * @interface Fund
 */
export interface Fund {
    /**
     * 
     * @type {string}
     * @memberof Fund
     */
    title: string;
    /**
     * 
     * @type {number}
     * @memberof Fund
     */
    totalAmount: number;
    /**
     * 
     * @type {number}
     * @memberof Fund
     */
    puAmount: number;
    /**
     * 
     * @type {number}
     * @memberof Fund
     */
    puValue: number;
    /**
     * Used by pension life funds 
     * Amount of initial investment units
     * @type {number}
     * @memberof Fund
     */
    valuePUStart: number;
    /**
     * Used by pension funds
     * @type {number}
     * @memberof Fund
     */
    balanceRatio: number;
    /**
     * Used by pension and life funds
     * @type {number}
     * @memberof Fund
     */
    depositRatio: number;
    /**
     * Used by life funds
     * @type {number}
     * @memberof Fund
     */
    depositTopUpRatio?: number | null;
    /**
     * 
     * @type {FundCode}
     * @memberof Fund
     */
    code?: FundCode;
}

/**
 * Check if a given object implements the Fund interface.
 */
export function instanceOfFund(value: object): value is Fund {
    if (!('title' in value) || value['title'] === undefined) return false;
    if (!('totalAmount' in value) || value['totalAmount'] === undefined) return false;
    if (!('puAmount' in value) || value['puAmount'] === undefined) return false;
    if (!('puValue' in value) || value['puValue'] === undefined) return false;
    if (!('valuePUStart' in value) || value['valuePUStart'] === undefined) return false;
    if (!('balanceRatio' in value) || value['balanceRatio'] === undefined) return false;
    if (!('depositRatio' in value) || value['depositRatio'] === undefined) return false;
    return true;
}

export function FundFromJSON(json: any): Fund {
    return FundFromJSONTyped(json, false);
}

export function FundFromJSONTyped(json: any, ignoreDiscriminator: boolean): Fund {
    if (json == null) {
        return json;
    }
    return {
        
        'title': json['title'],
        'totalAmount': json['totalAmount'],
        'puAmount': json['puAmount'],
        'puValue': json['puValue'],
        'valuePUStart': json['valuePUStart'],
        'balanceRatio': json['balanceRatio'],
        'depositRatio': json['depositRatio'],
        'depositTopUpRatio': json['depositTopUpRatio'] == null ? undefined : json['depositTopUpRatio'],
        'code': json['code'] == null ? undefined : FundCodeFromJSON(json['code']),
    };
}

export function FundToJSON(value?: Fund | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'title': value['title'],
        'totalAmount': value['totalAmount'],
        'puAmount': value['puAmount'],
        'puValue': value['puValue'],
        'valuePUStart': value['valuePUStart'],
        'balanceRatio': value['balanceRatio'],
        'depositRatio': value['depositRatio'],
        'depositTopUpRatio': value['depositTopUpRatio'],
        'code': FundCodeToJSON(value['code']),
    };
}

