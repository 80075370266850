/* tslint:disable */
/* eslint-disable */
/**
 * NN.CustomerPortal.ApiApp.General
 * GeneralAPIs
 *
 * The version of the OpenAPI document: 1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PickedAnswerRequest
 */
export interface PickedAnswerRequest {
    /**
     * 
     * @type {string}
     * @memberof PickedAnswerRequest
     */
    questionId: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof PickedAnswerRequest
     */
    choices: Array<string>;
}

/**
 * Check if a given object implements the PickedAnswerRequest interface.
 */
export function instanceOfPickedAnswerRequest(value: object): value is PickedAnswerRequest {
    if (!('questionId' in value) || value['questionId'] === undefined) return false;
    if (!('choices' in value) || value['choices'] === undefined) return false;
    return true;
}

export function PickedAnswerRequestFromJSON(json: any): PickedAnswerRequest {
    return PickedAnswerRequestFromJSONTyped(json, false);
}

export function PickedAnswerRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): PickedAnswerRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'questionId': json['questionId'],
        'choices': json['choices'],
    };
}

export function PickedAnswerRequestToJSON(value?: PickedAnswerRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'questionId': value['questionId'],
        'choices': value['choices'],
    };
}

