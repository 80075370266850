/* tslint:disable */
/* eslint-disable */
/**
 * NN.CustomerPortal.ApiApp.General
 * GeneralAPIs
 *
 * The version of the OpenAPI document: 1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Attention } from './Attention';
import {
    AttentionFromJSON,
    AttentionFromJSONTyped,
    AttentionToJSON,
} from './Attention';
import type { PaymentFrequency } from './PaymentFrequency';
import {
    PaymentFrequencyFromJSON,
    PaymentFrequencyFromJSONTyped,
    PaymentFrequencyToJSON,
} from './PaymentFrequency';
import type { ProductType } from './ProductType';
import {
    ProductTypeFromJSON,
    ProductTypeFromJSONTyped,
    ProductTypeToJSON,
} from './ProductType';
import type { PensionPhaseStatus } from './PensionPhaseStatus';
import {
    PensionPhaseStatusFromJSON,
    PensionPhaseStatusFromJSONTyped,
    PensionPhaseStatusToJSON,
} from './PensionPhaseStatus';

/**
 * 
 * @export
 * @interface GetContractsResponse
 */
export interface GetContractsResponse {
    /**
     * 
     * @type {string}
     * @memberof GetContractsResponse
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof GetContractsResponse
     */
    id: string;
    /**
     * 
     * @type {ProductType}
     * @memberof GetContractsResponse
     */
    type: ProductType;
    /**
     * 
     * @type {string}
     * @memberof GetContractsResponse
     */
    description: string;
    /**
     * 
     * @type {number}
     * @memberof GetContractsResponse
     */
    amount: number;
    /**
     * 
     * @type {PaymentFrequency}
     * @memberof GetContractsResponse
     */
    paymentFrequency?: PaymentFrequency;
    /**
     * 
     * @type {PensionPhaseStatus}
     * @memberof GetContractsResponse
     */
    pensionPhaseStatus?: PensionPhaseStatus;
    /**
     * 
     * @type {Array<Attention>}
     * @memberof GetContractsResponse
     */
    attentions: Array<Attention>;
    /**
     * LifeTransaction for Life contracts or PensionTransaction for Pension contracts
     * @type {boolean}
     * @memberof GetContractsResponse
     */
    isChildrenContract: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetContractsResponse
     */
    childName?: string | null;
}

/**
 * Check if a given object implements the GetContractsResponse interface.
 */
export function instanceOfGetContractsResponse(value: object): value is GetContractsResponse {
    if (!('title' in value) || value['title'] === undefined) return false;
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('type' in value) || value['type'] === undefined) return false;
    if (!('description' in value) || value['description'] === undefined) return false;
    if (!('amount' in value) || value['amount'] === undefined) return false;
    if (!('attentions' in value) || value['attentions'] === undefined) return false;
    if (!('isChildrenContract' in value) || value['isChildrenContract'] === undefined) return false;
    return true;
}

export function GetContractsResponseFromJSON(json: any): GetContractsResponse {
    return GetContractsResponseFromJSONTyped(json, false);
}

export function GetContractsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetContractsResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'title': json['title'],
        'id': json['id'],
        'type': ProductTypeFromJSON(json['type']),
        'description': json['description'],
        'amount': json['amount'],
        'paymentFrequency': json['paymentFrequency'] == null ? undefined : PaymentFrequencyFromJSON(json['paymentFrequency']),
        'pensionPhaseStatus': json['pensionPhaseStatus'] == null ? undefined : PensionPhaseStatusFromJSON(json['pensionPhaseStatus']),
        'attentions': ((json['attentions'] as Array<any>).map(AttentionFromJSON)),
        'isChildrenContract': json['isChildrenContract'],
        'childName': json['childName'] == null ? undefined : json['childName'],
    };
}

export function GetContractsResponseToJSON(value?: GetContractsResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'title': value['title'],
        'id': value['id'],
        'type': ProductTypeToJSON(value['type']),
        'description': value['description'],
        'amount': value['amount'],
        'paymentFrequency': PaymentFrequencyToJSON(value['paymentFrequency']),
        'pensionPhaseStatus': PensionPhaseStatusToJSON(value['pensionPhaseStatus']),
        'attentions': ((value['attentions'] as Array<any>).map(AttentionToJSON)),
        'isChildrenContract': value['isChildrenContract'],
        'childName': value['childName'],
    };
}

