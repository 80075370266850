/* tslint:disable */
/* eslint-disable */
/**
 * NN.CustomerPortal.ApiApp.General
 * GeneralAPIs
 *
 * The version of the OpenAPI document: 1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { CoverageKindEnum } from './CoverageKindEnum';
import {
    CoverageKindEnumFromJSON,
    CoverageKindEnumFromJSONTyped,
    CoverageKindEnumToJSON,
} from './CoverageKindEnum';

/**
 * 
 * @export
 * @interface Coverage
 */
export interface Coverage {
    /**
     * 
     * @type {number}
     * @memberof Coverage
     */
    id: number;
    /**
     * 
     * @type {CoverageKindEnum}
     * @memberof Coverage
     */
    coverageKind: CoverageKindEnum;
    /**
     * 
     * @type {boolean}
     * @memberof Coverage
     */
    isActive: boolean;
    /**
     * 
     * @type {number}
     * @memberof Coverage
     */
    order: number;
    /**
     * 
     * @type {string}
     * @memberof Coverage
     */
    stateCode?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof Coverage
     */
    isMain: boolean;
    /**
     * 
     * @type {Date}
     * @memberof Coverage
     */
    dateStart?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof Coverage
     */
    dateEnd?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof Coverage
     */
    sumAssured: number;
    /**
     * 
     * @type {number}
     * @memberof Coverage
     */
    premium: number;
    /**
     * 
     * @type {string}
     * @memberof Coverage
     */
    code?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Coverage
     */
    name?: string | null;
}

/**
 * Check if a given object implements the Coverage interface.
 */
export function instanceOfCoverage(value: object): value is Coverage {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('coverageKind' in value) || value['coverageKind'] === undefined) return false;
    if (!('isActive' in value) || value['isActive'] === undefined) return false;
    if (!('order' in value) || value['order'] === undefined) return false;
    if (!('isMain' in value) || value['isMain'] === undefined) return false;
    if (!('sumAssured' in value) || value['sumAssured'] === undefined) return false;
    if (!('premium' in value) || value['premium'] === undefined) return false;
    return true;
}

export function CoverageFromJSON(json: any): Coverage {
    return CoverageFromJSONTyped(json, false);
}

export function CoverageFromJSONTyped(json: any, ignoreDiscriminator: boolean): Coverage {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'coverageKind': CoverageKindEnumFromJSON(json['coverageKind']),
        'isActive': json['isActive'],
        'order': json['order'],
        'stateCode': json['stateCode'] == null ? undefined : json['stateCode'],
        'isMain': json['isMain'],
        'dateStart': json['dateStart'] == null ? undefined : (new Date(json['dateStart'])),
        'dateEnd': json['dateEnd'] == null ? undefined : (new Date(json['dateEnd'])),
        'sumAssured': json['sumAssured'],
        'premium': json['premium'],
        'code': json['code'] == null ? undefined : json['code'],
        'name': json['name'] == null ? undefined : json['name'],
    };
}

export function CoverageToJSON(value?: Coverage | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'coverageKind': CoverageKindEnumToJSON(value['coverageKind']),
        'isActive': value['isActive'],
        'order': value['order'],
        'stateCode': value['stateCode'],
        'isMain': value['isMain'],
        'dateStart': value['dateStart'] == null ? undefined : ((value['dateStart'] as any).toISOString().substring(0,10)),
        'dateEnd': value['dateEnd'] == null ? undefined : ((value['dateEnd'] as any).toISOString().substring(0,10)),
        'sumAssured': value['sumAssured'],
        'premium': value['premium'],
        'code': value['code'],
        'name': value['name'],
    };
}

