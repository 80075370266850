import Grid from "@mui/material/Grid";
import Skeleton from "@mui/material/Skeleton";
import useIsDesktop from "desktop/useIsDesktop";
import { graphql, useStaticQuery } from "gatsby";
import BackgroundImage from "gatsby-background-image";

import { LoggedInBar } from "../../components/AppBar";
import Consultants from "../../components/Contacts/Consultants";
import General from "../../components/Contacts/General";
import { contractTypeToProductName } from "../../components/Contract/ContractCard";
import { SEO } from "../../components/SEO";
import { TabsWithQueryParameter } from "../../components/Tabs";
import { useContractId } from "../../context/ContractIDContext";
import withProtection from "../../highOrderComponents/withProtection";
import { useContacts } from "../../queryHooks";

export const AppBar = (): JSX.Element => <LoggedInBar />;

export const ContactsImage = ({ children }: { children: JSX.Element }) => {
	const image = useStaticQuery<Queries.ContactsPhoneImageQuery>(
		graphql`
			query ContactsPhoneImage {
				desktop: file(relativePath: { eq: "contacts.png" }) {
					childImageSharp {
						fixed(quality: 90) {
							...GatsbyImageSharpFixed_withWebp
						}
					}
				}
			}
		`
	);
	return (
		<BackgroundImage
			style={{
				backgroundSize: "contain",
				backgroundPosition: "220px 12px",
				width: "100%",
			}}
			fixed={image.desktop.childImageSharp.fixed}
		>
			{children}
		</BackgroundImage>
	);
};

const Contacts = ({ pageContext }): JSX.Element => {
	const isD = useIsDesktop();

	const id = useContractId();

	const { data, isLoading } = useContacts();

	const notPensionContact = () => {
		if (!isLoading) {
			return data.filter(
				(client) =>
					client.productName !== contractTypeToProductName.UF &&
					client.productName !== contractTypeToProductName.PF
			);
		}
	};
	const resultNotPensionContact = notPensionContact();
	if (isD)
		return (
			<Grid container>
				<Grid item md={5}>
					<General />
				</Grid>
				<Grid item md={1} />
				<Grid item md={6}>
					{!isLoading ? (
						<Consultants consultants={notPensionContact()} />
					) : (
						<Skeleton />
					)}
				</Grid>
			</Grid>
		);

	return (
		<TabsWithQueryParameter
			queryParamKey="main-tab"
			tabs={
				!isLoading && resultNotPensionContact.length > 0
					? [
							{ label: "Všeobecné kontakty", slug: "vseobecne-kontakty" },
							{ label: "Kontakty na poradce", slug: "kontakty-na-poradce" },
					  ]
					: [{ label: "Všeobecné kontakty", slug: "vseobecne-kontakty" }]
			}
		>
			<General />
			{!isLoading ? (
				<Consultants consultants={notPensionContact()} />
			) : (
				<Skeleton />
			)}
		</TabsWithQueryParameter>
	);
};

export default withProtection(Contacts);

export const Head = (): JSX.Element => (
	<SEO
		description="Volejte 244 090 800 od pondělí do pátku od 8 do 18 hodin. Nebo nám napište na dotazy@nn.cz."
		title="Kontakty"
	/>
);
