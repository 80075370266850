/* tslint:disable */
/* eslint-disable */
/**
 * NN.CustomerPortal.ApiApp.General
 * GeneralAPIs
 *
 * The version of the OpenAPI document: 1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { IdentityDocument } from './IdentityDocument';
import {
    IdentityDocumentFromJSON,
    IdentityDocumentFromJSONTyped,
    IdentityDocumentToJSON,
} from './IdentityDocument';
import type { GenderEnum } from './GenderEnum';
import {
    GenderEnumFromJSON,
    GenderEnumFromJSONTyped,
    GenderEnumToJSON,
} from './GenderEnum';

/**
 * 
 * @export
 * @interface Person
 */
export interface Person {
    /**
     * 
     * @type {number}
     * @memberof Person
     */
    id: number;
    /**
     * 
     * @type {Date}
     * @memberof Person
     */
    birthDate?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof Person
     */
    birthNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Person
     */
    firstName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Person
     */
    lastName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Person
     */
    titlePrefix?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Person
     */
    titleSuffix?: string | null;
    /**
     * 
     * @type {GenderEnum}
     * @memberof Person
     */
    gender: GenderEnum;
    /**
     * 
     * @type {string}
     * @memberof Person
     */
    citizenship?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Person
     */
    citizenshipA2?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Person
     */
    citizenshipA3?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Person
     */
    countryOfBirth?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Person
     */
    countryOfBirthA2?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Person
     */
    countryOfBirthA3?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Person
     */
    birthPlace?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof Person
     */
    isAdult: boolean;
    /**
     * 
     * @type {string}
     * @memberof Person
     */
    riskCddLevel?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof Person
     */
    dateLastCddCheck?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof Person
     */
    dateNextCddCheck?: Date | null;
    /**
     * 
     * @type {boolean}
     * @memberof Person
     */
    isPEP: boolean;
    /**
     * 
     * @type {number}
     * @memberof Person
     */
    amlRiskCategory: number;
    /**
     * 
     * @type {string}
     * @memberof Person
     */
    amlRiskCategoryReason?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Person
     */
    sourceSystem?: string | null;
    /**
     * 
     * @type {Array<IdentityDocument>}
     * @memberof Person
     */
    identityDocuments: Array<IdentityDocument>;
}

/**
 * Check if a given object implements the Person interface.
 */
export function instanceOfPerson(value: object): value is Person {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('gender' in value) || value['gender'] === undefined) return false;
    if (!('isAdult' in value) || value['isAdult'] === undefined) return false;
    if (!('isPEP' in value) || value['isPEP'] === undefined) return false;
    if (!('amlRiskCategory' in value) || value['amlRiskCategory'] === undefined) return false;
    if (!('identityDocuments' in value) || value['identityDocuments'] === undefined) return false;
    return true;
}

export function PersonFromJSON(json: any): Person {
    return PersonFromJSONTyped(json, false);
}

export function PersonFromJSONTyped(json: any, ignoreDiscriminator: boolean): Person {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'birthDate': json['birthDate'] == null ? undefined : (new Date(json['birthDate'])),
        'birthNumber': json['birthNumber'] == null ? undefined : json['birthNumber'],
        'firstName': json['firstName'] == null ? undefined : json['firstName'],
        'lastName': json['lastName'] == null ? undefined : json['lastName'],
        'titlePrefix': json['titlePrefix'] == null ? undefined : json['titlePrefix'],
        'titleSuffix': json['titleSuffix'] == null ? undefined : json['titleSuffix'],
        'gender': GenderEnumFromJSON(json['gender']),
        'citizenship': json['citizenship'] == null ? undefined : json['citizenship'],
        'citizenshipA2': json['citizenshipA2'] == null ? undefined : json['citizenshipA2'],
        'citizenshipA3': json['citizenshipA3'] == null ? undefined : json['citizenshipA3'],
        'countryOfBirth': json['countryOfBirth'] == null ? undefined : json['countryOfBirth'],
        'countryOfBirthA2': json['countryOfBirthA2'] == null ? undefined : json['countryOfBirthA2'],
        'countryOfBirthA3': json['countryOfBirthA3'] == null ? undefined : json['countryOfBirthA3'],
        'birthPlace': json['birthPlace'] == null ? undefined : json['birthPlace'],
        'isAdult': json['isAdult'],
        'riskCddLevel': json['riskCddLevel'] == null ? undefined : json['riskCddLevel'],
        'dateLastCddCheck': json['dateLastCddCheck'] == null ? undefined : (new Date(json['dateLastCddCheck'])),
        'dateNextCddCheck': json['dateNextCddCheck'] == null ? undefined : (new Date(json['dateNextCddCheck'])),
        'isPEP': json['isPEP'],
        'amlRiskCategory': json['amlRiskCategory'],
        'amlRiskCategoryReason': json['amlRiskCategoryReason'] == null ? undefined : json['amlRiskCategoryReason'],
        'sourceSystem': json['sourceSystem'] == null ? undefined : json['sourceSystem'],
        'identityDocuments': ((json['identityDocuments'] as Array<any>).map(IdentityDocumentFromJSON)),
    };
}

export function PersonToJSON(value?: Person | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'birthDate': value['birthDate'] == null ? undefined : ((value['birthDate'] as any).toISOString().substring(0,10)),
        'birthNumber': value['birthNumber'],
        'firstName': value['firstName'],
        'lastName': value['lastName'],
        'titlePrefix': value['titlePrefix'],
        'titleSuffix': value['titleSuffix'],
        'gender': GenderEnumToJSON(value['gender']),
        'citizenship': value['citizenship'],
        'citizenshipA2': value['citizenshipA2'],
        'citizenshipA3': value['citizenshipA3'],
        'countryOfBirth': value['countryOfBirth'],
        'countryOfBirthA2': value['countryOfBirthA2'],
        'countryOfBirthA3': value['countryOfBirthA3'],
        'birthPlace': value['birthPlace'],
        'isAdult': value['isAdult'],
        'riskCddLevel': value['riskCddLevel'],
        'dateLastCddCheck': value['dateLastCddCheck'] == null ? undefined : ((value['dateLastCddCheck'] as any).toISOString().substring(0,10)),
        'dateNextCddCheck': value['dateNextCddCheck'] == null ? undefined : ((value['dateNextCddCheck'] as any).toISOString().substring(0,10)),
        'isPEP': value['isPEP'],
        'amlRiskCategory': value['amlRiskCategory'],
        'amlRiskCategoryReason': value['amlRiskCategoryReason'],
        'sourceSystem': value['sourceSystem'],
        'identityDocuments': ((value['identityDocuments'] as Array<any>).map(IdentityDocumentToJSON)),
    };
}

