/*hook to use in components shared between DPS and PP to avoid writing a complicated fetch function each time */

import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import {
	GetDPSTransactionsGetRequest,
	GetLifeTransactionsGetRequest,
	GetPPTransactionsGetRequest,
} from "../../apis";
import {
	DocumentDownloadResponse,
	GetDPSDetailResponse,
	GetLifeTransactionsResponse,
	GetPensionTransactionsResponse,
	GetPPDetailResponse,
	ProductType,
} from "../../models";
import { useContractTypeContext } from "../context/ContractTypeContext";
import { usePrimaryAPIClient } from "../context/PrimaryAPIClient";
import { useQueryKeyFactory } from "../context/QueryKeyProvider";
import {
	assertIsPensionContractType,
	assertIsSupportedContractType,
	PensionContractType,
	SupportedContractType,
} from "../types/contracts";

interface IPensionDetailProps<
	TResult = GetDPSDetailResponse | GetPPDetailResponse
> {
	id: string;
	type?: PensionContractType;
	options?: UseQueryOptions<
		GetDPSDetailResponse | GetPPDetailResponse,
		unknown,
		TResult
	>;
}

export const usePensionDetail = <
	TResult = GetDPSDetailResponse | GetPPDetailResponse,
>({
	id,
	type,
	options = {},
}: IPensionDetailProps<TResult>) => {
	const { contractsDpsApi, contractsPpApi } = usePrimaryAPIClient();
	const { contractType } = useContractTypeContext();
	const { createQueryKey } = useQueryKeyFactory();

	const pensionProductType = type || contractType;

	const fetchMethod = () => {
		assertIsPensionContractType(
			pensionProductType,
			"No or wrong contract type provided to use pension detail hook"
		);

		if (pensionProductType === ProductType.Uf) {
			return contractsDpsApi.getDPSDetailGet({ contractId: id });
		} else {
			return contractsPpApi.getPPDetailGet({ contractId: id });
		}
	};

	return useQuery<GetDPSDetailResponse | GetPPDetailResponse, unknown, TResult>(
		createQueryKey({
			key: ["getContractDetail", id, pensionProductType],
			includeUserIdentifier: false,
		}),
		fetchMethod,
		options
	);
};

interface IPensionDocumentsProps {
	id: string;
	type: PensionContractType;
	options: UseQueryOptions<DocumentDownloadResponse[]>;
}

export const usePensionDocuments = ({
	id,
	type,
	options = {},
}: IPensionDocumentsProps) => {
	const { documentsApi } = usePrimaryAPIClient();
	const { createQueryKey } = useQueryKeyFactory();

	const fetchMethod = () => {
		assertIsPensionContractType(
			type,
			"No or wrong contract type provided to use pension documents hook"
		);

		if (type === ProductType.Uf) {
			return documentsApi.getDPSDocumentsGet({ contractId: id });
		} else {
			return documentsApi.getPPDocumentsGet({ contractId: id });
		}
	};

	return useQuery<DocumentDownloadResponse[]>(
		createQueryKey({
			key: ["getPensionDocuments", id],
			includeUserIdentifier: false,
		}),
		fetchMethod,
		options
	);
};

interface TransactionsProps {
	type: SupportedContractType;
	params: Pick<
		| GetDPSTransactionsGetRequest
		| GetLifeTransactionsGetRequest
		| GetPPTransactionsGetRequest,
		"contractId" | "count"
	>;
	options?: UseQueryOptions<
		GetLifeTransactionsResponse[] | GetPensionTransactionsResponse[]
	>;
}

export const useTransactions = ({
	type,
	params,
	options = {},
}: TransactionsProps) => {
	const { contractsDpsApi, contractsPpApi, contractsLifeApi } =
		usePrimaryAPIClient();
	const { createQueryKey } = useQueryKeyFactory();

	const fetchMethodByProductType = {
		[ProductType.Uf]: async (params: GetDPSTransactionsGetRequest) =>
			contractsDpsApi.getDPSTransactionsGet(params),
		[ProductType.Pf]: async (params: GetPPTransactionsGetRequest) =>
			contractsPpApi.getPPTransactionsGet(params),
		[ProductType.Cl]: async (params: GetLifeTransactionsGetRequest) =>
			contractsLifeApi.getLifeTransactionsGet({
				...params,
				productType: ProductType.Cl,
			}),
		[ProductType.Clf]: async (params: GetLifeTransactionsGetRequest) =>
			contractsLifeApi.getLifeTransactionsGet({
				...params,
				productType: ProductType.Clf,
			}),
	};

	const fetchMethod = async () => {
		assertIsSupportedContractType(type);
		return await fetchMethodByProductType[type](params);
	};

	return useQuery<
		GetLifeTransactionsResponse[] | GetPensionTransactionsResponse[]
	>(
		createQueryKey({
			key: ["getTransactions", params],
			includeUserIdentifier: false,
		}),
		() => {
			return fetchMethod();
		},
		options
	);
};
