/* tslint:disable */
/* eslint-disable */
/**
 * NN.CustomerPortal.ApiApp.General
 * GeneralAPIs
 *
 * The version of the OpenAPI document: 1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const ConsentType = {
    Marketing: 'Marketing',
    ElectronicCommunication: 'ElectronicCommunication',
    ElectronicContracting: 'ElectronicContracting'
} as const;
export type ConsentType = typeof ConsentType[keyof typeof ConsentType];


export function instanceOfConsentType(value: any): boolean {
    for (const key in ConsentType) {
        if (Object.prototype.hasOwnProperty.call(ConsentType, key)) {
            if ((ConsentType as Record<string, ConsentType>)[key] === value) {
                return true;
            }
        }
    }
    return false;
}

export function ConsentTypeFromJSON(json: any): ConsentType {
    return ConsentTypeFromJSONTyped(json, false);
}

export function ConsentTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConsentType {
    return json as ConsentType;
}

export function ConsentTypeToJSON(value?: ConsentType | null): any {
    return value as any;
}

