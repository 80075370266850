import { Box, Stack } from "@mui/material";
import { navigate } from "gatsby";
import { Button } from "gatsby-theme-material-ui";

import cs from "../../translations/cs.json";
import Attention from "../AttentionBlock";
import { useErrorContext } from "../ErrorBoundary/ErrorContext";
import { BodyTextS, HeadingS } from "../Typography";

export interface IErrorMessage {
	title?: string;
	message?: string;
	errorMessage?: string;
	buttonText?: string;
	customButton?: JSX.Element | null;
}

export const HomeButton = () => {
	const { resetError } = useErrorContext();
	return (
		<Button
			variant="contained"
			onClick={() => {
				resetError();
				void navigate("/");
			}}
		>
			{cs.global.home}
		</Button>
	);
};

export default function GeneralMessage({
	title = cs.errors.somethingWentWrong,
	message = cs.errors.general,
	buttonText = cs.errors.tryAgain,
	errorMessage,
	customButton,
}: IErrorMessage): JSX.Element {
	const button = (() => {
		if (customButton === null) return null;
		return (
			customButton ?? (
				<Button onClick={() => window.location.reload()} variant="contained">
					{buttonText}
				</Button>
			)
		);
	})();
	return (
		<>
			<Attention
				sx={{ minWidth: { md: "450px", xs: 0 } }}
				severity="error"
				title={title}
			>
				{message}
			</Attention>
			{errorMessage && (
				<Stack gap={4}>
					<HeadingS sx={{ m: 0 }}>Více informací</HeadingS>
					<Box sx={{ p: 3, borderRadius: 4, backgroundColor: "grey.light" }}>
						<BodyTextS sx={{ m: 0 }}>{errorMessage}</BodyTextS>
					</Box>
				</Stack>
			)}
			{button}
		</>
	);
}
