/* tslint:disable */
/* eslint-disable */
/**
 * NN.CustomerPortal.ApiApp.General
 * GeneralAPIs
 *
 * The version of the OpenAPI document: 1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { BeneficiaryRequest } from './BeneficiaryRequest';
import {
    BeneficiaryRequestFromJSON,
    BeneficiaryRequestFromJSONTyped,
    BeneficiaryRequestToJSON,
} from './BeneficiaryRequest';

/**
 * 
 * @export
 * @interface SetBeneficiariesRequest
 */
export interface SetBeneficiariesRequest {
    /**
     * 
     * @type {Array<BeneficiaryRequest>}
     * @memberof SetBeneficiariesRequest
     */
    beneficiaries: Array<BeneficiaryRequest>;
}

/**
 * Check if a given object implements the SetBeneficiariesRequest interface.
 */
export function instanceOfSetBeneficiariesRequest(value: object): value is SetBeneficiariesRequest {
    if (!('beneficiaries' in value) || value['beneficiaries'] === undefined) return false;
    return true;
}

export function SetBeneficiariesRequestFromJSON(json: any): SetBeneficiariesRequest {
    return SetBeneficiariesRequestFromJSONTyped(json, false);
}

export function SetBeneficiariesRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): SetBeneficiariesRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'beneficiaries': ((json['beneficiaries'] as Array<any>).map(BeneficiaryRequestFromJSON)),
    };
}

export function SetBeneficiariesRequestToJSON(value?: SetBeneficiariesRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'beneficiaries': ((value['beneficiaries'] as Array<any>).map(BeneficiaryRequestToJSON)),
    };
}

