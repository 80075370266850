import { isEqual } from "lodash";
import {
	addMethod,
	array,
	boolean,
	date,
	MixedSchema,
	number,
	object,
	ref,
	setLocale,
	string,
} from "yup";

import cs from "../../translations/cs.json";
import { DialogValues } from "../DPS/Contribution/ContributionDialog";
import InternalLink from "../Link/InternalLink";
import locale from "./locale";

function testRC(x: string, age?: number) {
	if (!age) age = 0;
	try {
		if (x.length == 0) return true;
		if (x.length > 10) throw 1;
		if (x.length < 9) throw 1;
		const isNumeric = /^\d+$/.test(x);
		if (!isNumeric) throw 1;
		let year = parseInt(x.substr(0, 2), 10);
		let month = parseInt(x.substr(2, 2), 10);
		const day = parseInt(x.substr(4, 2), 10);
		const ext = parseInt(x.substr(6, 3), 10);
		// SP throws error on 9 digit birthnumbers older than 1923.
		if (x.length == 9 && year < 24) return false;
		if (x.length == 9 && year < 54) return true;
		let c = 0;
		if (x.length == 10) c = parseInt(x.substr(9, 1));
		let m = parseInt(x.substr(0, 9)) % 11;
		if (m == 10) m = 0;
		if (m != c) throw 1;
		year += year < 54 ? 2000 : 1900;
		if (month > 70 && year > 2003) month -= 70;
		else if (month > 50) month -= 50;
		else if (month > 20 && year > 2003) month -= 20;
		const d = new Date();
		if (year + age > d.getFullYear()) throw 1;
		if (month == 0) throw 1;
		if (month > 12) throw 1;
		if (day == 0) throw 1;
		if (day > 31) throw 1;
	} catch (e) {
		return false;
	}
	return true;
}

export const globalFormikYupConfig = (): void => {
	setLocale(locale);

	addMethod(MixedSchema, "parentHasChanges", function (initialValues) {
		return this.test(
			"has-changes",
			cs.errorMessages.makeChanges,
			(_, { parent }) => {
				return !isEqual(initialValues, parent);
			}
		);
	});
};

setLocale(locale);

export const dateTo = {
	dateTo: date()
		.min(ref("dateFrom"), "Datum 'do' musí být později než datum 'od'")
		.nullable()
		.typeError("Neplatný formát data"),
};

export const dateToNow = {
	dateTo: date()
		.min(ref("dateFrom"), "Datum 'do' musí být později než datum 'od'")
		.max(new Date(), "Nezadávejte datum z budoucnosti")
		.nullable()
		.typeError("Neplatný formát data"),
};

export const dateRangeFrom2010 = {
	dateFrom: date()
		.default(() => new Date("Jan 01 2010 00:00:00"))
		.min(
			new Date("Jan 01 2010 00:00:00"),
			"Nezadávejte data starší než 1.1.2010."
		)
		.typeError("Neplatný formát data")
		.nullable(),
	...dateToNow,
};

export const dateRangeFrom1990 = {
	dateFrom: date()
		.default(() => new Date("Jan 01 1990 00:00:00"))
		.min(
			new Date("Jan 01 1990 00:00:00"),
			"Nezadávejte data starší než 1.1.1990."
		)
		.typeError("Neplatný formát data")
		.nullable(),
	...dateToNow,
};

export const dateRange = {
	dateFrom: date().typeError("Neplatný formát data").nullable(),
	...dateTo,
};

export const agreement = object().shape({
	agreement: boolean()
		.required("Prosím vyberte jednu z možností.")
		.typeError("Prosím vyberte jednu z možností."),
});

export const insuredPerson = object().shape({
	insuredPerson: string(),
});

export const insuranceType = object().shape({
	insuranceType: string(),
});

export const getMonthlyContributionValidation = (
	currentValues: DialogValues
) => {
	const max = 999999;
	return object().shape({
		monthlyContribution: number()
			.max(max, () => (
				<>
					Těší nás, že si chcete změnit výši příspěvku. Pokud si přejete
					přispívat více než {max} Kč, potřebujeme vás z bezpečnostních důvodů a
					v souladu s platnými zákony doidentifikovat. Kontaktujte prosím Vašeho
					poradce nebo nám zavolejte na číslo{" "}
					<InternalLink variant="light" to="tel:+420244090800">
						244 090 800
					</InternalLink>{" "}
					a my Vám schůzku domluvíme.
				</>
			))
			.integer(),
		doesEmployerContribute: boolean(),
		valuesChanged: string().parentHasChanges({
			monthlyContribution: currentValues.monthlyContribution,
			doesEmployerContribute: currentValues.doesEmployerContribute,
		}),
	});
};

export const addressSchema = object({
	addressType: string().required(cs.AddressErrorMessages.address),
	street: string(),
	houseNumber: string().max(30),
	zip: string().matches(/^[0-9]*$/, cs.AddressErrorMessages.zipWithOutSymbols),
	town: string(),
});

export const beneficiariesSchema = array()
	.of(
		object().shape({
			firstName: string()
				.max(80)
				.nullable()
				.matches(/^[A-ZÁČĎÉĚÍŇÓŘŠŤŮÚÝŽ]/, cs.errorMessages.firstCapitalLetter)
				.matches(/^[a-zA-Z\u00C0-\u017F\s]+$/, cs.errorMessages.numberInName)
				.required(),
			lastName: string()
				.max(100)
				.nullable()
				.matches(/^[A-ZÁČĎÉĚÍŇÓŘŠŤŮÚÝŽ]/, cs.errorMessages.firstCapitalLetter)
				.matches(/^[a-zA-Z\u00C0-\u017F\s]+$/, cs.errorMessages.numberInSurname)
				.required(),
			dateOfBirth: date()
				.min(
					new Date("1900-01-01"),
					"Zadané datum je velmi vzdálené minulosti."
				)
				.max(
					new Date(),
					"Zadané datum je v budoucnu, nemůže tedy být datem narození."
				)
				.typeError("Neplatný formát data")
				.required(),
			birthNumber: string()
				.required()
				.test(
					"birthNumber",
					cs.errorMessages.validBirthNumber,
					function (value) {
						return testRC(value);
					}
				),
			address: object()
				.shape(
					// all address fields except street field are required, but only when one of the address field is present (in other words, don't send incomplete address)
					{
						houseNumber: string()
							.default("") // https://github.com/jaredpalmer/formik/issues/805#issuecomment-725535037 - to make parentHasChange() works properly
							.defined()
							.max(30)
							.matches(/^[0-9/]*$/, cs.AddressErrorMessages.houseNumberSymbols)
							.when(["street", "town", "zip"], {
								is: (street, town, zip) => street || town || zip,
								then: string()
									.nullable()
									.required(
										cs.beneficiariesChange.errorMessages.incompleteAddress
									),
								otherwise: string().nullable(),
							}),
						street: string()
							.default("")
							.defined()
							.max(100)
							.when(["houseNumber", "town", "zip"], {
								is: (houseNumber, town, zip) => houseNumber || town || zip,
								then: string()
									.nullable()
									.required(
										cs.beneficiariesChange.errorMessages.incompleteAddress
									),
								otherwise: string().nullable(),
							}),
						town: string()
							.default("")
							.defined()
							.max(50)
							.when(["houseNumber", "street", "zip"], {
								is: (houseNumber, street, zip) => houseNumber || street || zip,
								then: string()
									.nullable()
									.required(
										cs.beneficiariesChange.errorMessages.incompleteAddress
									),
								otherwise: string().nullable(),
							}),
						zip: string()
							.default("")
							.defined()
							.max(5)
							.matches(/^[0-9]*$/, cs.errorMessages.onlyNumbers)
							.when(["houseNumber", "street", "town"], {
								is: (houseNumber, street, town) =>
									houseNumber || street || town,
								then: string()
									.nullable()
									.required(
										cs.beneficiariesChange.errorMessages.incompleteAddress
									),
								otherwise: string().nullable(),
							}),
					},
					[
						["houseNumber", "town"], // sadly this 2nd parameter of object() is missing in yup documentation, but it is required to solve circle dependencies of fileds, there must be specified all combinations of fields https://github.com/jquense/yup/issues/1464
						["houseNumber", "zip"],
						["houseNumber", "street"],
						["zip", "town"],
						["zip", "street"],
						["street", "town"],
					]
				)
				.nullable(),
		})
	)
	.max(10, "Zabezpečených osob může být maximálně 10."); // these errors must be redefined in BeneficiariesList.tsx until we found better solution for handling <ErrorMessage /> for arrays (validation of the whole array (.max()) is triggered only if there are no errors inside every single object defined by shape)

export const checkSum100 = (
	values: (number | undefined)[] | undefined
): boolean => {
	const sumOfValues = values?.reduce(
		(acc = 0, val) => (val === undefined ? acc : acc + val),
		0
	);
	return sumOfValues === 100;
};

export const ratiosSchema = array()
	.of(
		object().shape({
			ratio: number().integer(),
		})
	)
	.test(
		"isRatioSum100",
		cs.beneficiariesChange.sumMustBe100ErrorMessage,
		(ratios) => {
			const noBeneficiars = ratios.length === 0;
			if (noBeneficiars) {
				return true;
			} else {
				return checkSum100(ratios?.map((a) => a.ratio));
			}
		}
	)
	.test(
		"isEveryRatioAbove0",
		cs.beneficiariesChange.isEveryRatioAbove0ErrorMessage,
		(ratios) => {
			const existsRatio0 = ratios.find(
				(item) => item.ratio <= 0 || item.ratio === undefined
			);
			if (existsRatio0) {
				return false;
			} else {
				return true;
			}
		}
	);

export const allocationSchema = array()
	.of(
		object().shape({
			allocation: number(),
		})
	)
	.test(
		"isRatioSum100",
		cs.investmentStrategy.sumMustBe100ErrorMessage,
		(allocation) => {
			return checkSum100(allocation?.map((a) => a.allocation));
		}
	);

export const allocationDefaultSchema = array().of(
	object().shape({
		allocation: number(),
	})
);
