/* tslint:disable */
/* eslint-disable */
/**
 * NN.CustomerPortal.ApiApp.General
 * GeneralAPIs
 *
 * The version of the OpenAPI document: 1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const PensionPhaseStatus = {
    Saving: 'Saving',
    Payout: 'Payout'
} as const;
export type PensionPhaseStatus = typeof PensionPhaseStatus[keyof typeof PensionPhaseStatus];


export function instanceOfPensionPhaseStatus(value: any): boolean {
    for (const key in PensionPhaseStatus) {
        if (Object.prototype.hasOwnProperty.call(PensionPhaseStatus, key)) {
            if ((PensionPhaseStatus as Record<string, PensionPhaseStatus>)[key] === value) {
                return true;
            }
        }
    }
    return false;
}

export function PensionPhaseStatusFromJSON(json: any): PensionPhaseStatus {
    return PensionPhaseStatusFromJSONTyped(json, false);
}

export function PensionPhaseStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): PensionPhaseStatus {
    return json as PensionPhaseStatus;
}

export function PensionPhaseStatusToJSON(value?: PensionPhaseStatus | null): any {
    return value as any;
}

