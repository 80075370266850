export enum cookies {
	googleAnalytics = "GOOGLE_ANALYTICS",
	googleAds = "GOOGLE_ADS",
	facebookPixel = "FACEBOOK_PIXEL",
	appInsights = "APP_INSIGHTS",
	seznamRemarketing = "SEZNAM_REMARKETING",
	clarity = "CLARITY",
	linkedinInsight = "LINKEDIN_INSIGHT",
}
export type cookieId =
	| cookies.appInsights
	| cookies.googleAnalytics
	| cookies.facebookPixel
	| cookies.seznamRemarketing
	| cookies.clarity
	| cookies.googleAds
	| cookies.linkedinInsight;
export const mandatoryCookieIds: cookieId[] = [];
export const statisticalCookieIds: cookieId[] = [
	cookies.appInsights,
	cookies.googleAnalytics,
];
export const marketingCookieIds: cookieId[] = [
	cookies.googleAds,
	cookies.facebookPixel,
	cookies.seznamRemarketing,
	cookies.clarity,
	cookies.linkedinInsight,
];

export type cookieCategory = "statistical" | "marketing";
