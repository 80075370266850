/* tslint:disable */
/* eslint-disable */
/**
 * NN.CustomerPortal.ApiApp.General
 * GeneralAPIs
 *
 * The version of the OpenAPI document: 1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PostLoginResponse
 */
export interface PostLoginResponse {
    /**
     * 
     * @type {boolean}
     * @memberof PostLoginResponse
     */
    startConsentGathering: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PostLoginResponse
     */
    startElectronicContactsConfirmation: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PostLoginResponse
     */
    isPhoneVerified: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PostLoginResponse
     */
    isEmailVerified: boolean;
    /**
     * 
     * @type {string}
     * @memberof PostLoginResponse
     */
    userEmail?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PostLoginResponse
     */
    userPhone?: string | null;
}

/**
 * Check if a given object implements the PostLoginResponse interface.
 */
export function instanceOfPostLoginResponse(value: object): value is PostLoginResponse {
    if (!('startConsentGathering' in value) || value['startConsentGathering'] === undefined) return false;
    if (!('startElectronicContactsConfirmation' in value) || value['startElectronicContactsConfirmation'] === undefined) return false;
    if (!('isPhoneVerified' in value) || value['isPhoneVerified'] === undefined) return false;
    if (!('isEmailVerified' in value) || value['isEmailVerified'] === undefined) return false;
    return true;
}

export function PostLoginResponseFromJSON(json: any): PostLoginResponse {
    return PostLoginResponseFromJSONTyped(json, false);
}

export function PostLoginResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostLoginResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'startConsentGathering': json['startConsentGathering'],
        'startElectronicContactsConfirmation': json['startElectronicContactsConfirmation'],
        'isPhoneVerified': json['isPhoneVerified'],
        'isEmailVerified': json['isEmailVerified'],
        'userEmail': json['userEmail'] == null ? undefined : json['userEmail'],
        'userPhone': json['userPhone'] == null ? undefined : json['userPhone'],
    };
}

export function PostLoginResponseToJSON(value?: PostLoginResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'startConsentGathering': value['startConsentGathering'],
        'startElectronicContactsConfirmation': value['startElectronicContactsConfirmation'],
        'isPhoneVerified': value['isPhoneVerified'],
        'isEmailVerified': value['isEmailVerified'],
        'userEmail': value['userEmail'],
        'userPhone': value['userPhone'],
    };
}

